import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  dialogBox: {
    height: 500,
    backgroundColor: "#EEF4FA",
    margin: "20px 20px 20px 20px"
  },
  tableColumn: {
    display: "inline-flex",
    alignItems: "center",
    width: "50%",
    height: "35px",
    fontSize: "16px",
    marginBottom: "10px",
  },
  imageColumn: {
    display: "block",
    alignItems: "center",
    float: "right",
    width: "50%",
    maxHeight: "200px",
  },
  columnName: {
    width: "30%",
    textAlign: "right"
  },
  columnValue: {
    width: "70%",
    textAlign: "center"
  },
  priceColumn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "35px",
    fontSize: "16px",
    marginBottom: "10px",
  },
  priceBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80px",
    height: "40px",
    backgroundColor: "#fff",
    margin: "0 5px",
    fontWeight: "bold"
  },
});

const ItemDetail = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const item = {...props.item};

  const detail = [
    { name: t("ツールNo") + '.', value: item.edp_no },
    { name: t("重量"), value: item.weight },
    { name: t("製品記号"), value: item.abbreviation },
    { name: t("全長・ねじ長・シャンク径"), value: item.overall_thread_length_shank_dia },
    { name: t("呼び"), value: item.designation || '-' },
    { name: t("材質"), value: item.materials || '-' },
    { name: t("刃数 / 溝数"), value: item.number_of_flutes },
    { name: t("推奨下穴径"), value: item.drill_hole_dia || '' },
    { name: t("精度"), value: item.grade || '-' },

    { name: t("写真"), value: item.image || null },
    { name: t("表面処理"), value: item.surface_treatment || '-' },
    { name: t("分類"), value: item.category },
    { name: t("製品備考"), value: item.remarks || '-' },
    { name: t("在庫ロケーション"), value: item.location || '-' },
  ];

  return (
    <>
      <div className={classes.dialogBox}>
        <div style={{margin: 30}}>
          {detail.map((v,index) => {
            return (
              <div
                className={index === 9 ? classes.imageColumn : classes.tableColumn}
                key={v.name}
                style={index > 8 ? {display: "flex"} : index === 3 ? {fontSize: "10px",fontWeight: "bold"} : {}}
              >
                {index === 9 ?
                    detail[9].value ? <img
                      src={
                      process.env.REACT_APP_ENVIRONMENT === 'production' ?
                        `https://monolithbox-images-bucket.s3-ap-northeast-1.amazonaws.com/${detail[9].value}`
                      : `https://osg-product-images.s3-ap-northeast-1.amazonaws.com/images/${detail[9].value}`
                    }
                      style={{width: "100%"}}
                    alt={'Product'}
                    /> : null
                :
                  <>
                    <div className={classes.columnName}>{v.name}</div>
                    <div className={classes.columnValue}>{ v.value }</div>
                  </>
                }
              </div>
            );
          })}
          <div className={classes.priceColumn}>
            {t('標準価格')}
            <div className={classes.priceBox}>
              { item.standard_price || '' }
            </div>
            / {t('1点')}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDetail;
