import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  CircularProgress
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import axios from 'axios';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import AddIcon from '@material-ui/icons/Add';
import styled from "styled-components";
import { Trans, useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .formControl {
    background-color: #fff;
  }


  justify-content: flex-end;
  flex-direction: row;
  text-decoration: none
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "@media (max-height:800px)":{
      maxHeight: "77vh",
    },
    "@media (max-height:600px)":{
      maxHeight: "70vh",
    },
    "@media (max-height:400px)":{
      maxHeight: "50vh",
    },
    maxHeight: "77vh",
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  separate: {
    borderCollapse: 'separate',
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableCompanyHead: {
    minWidth: '300px',
    position: 'sticky',
    left: 0,
    padding: "6px 16px 6px 16px",
    borderRight: 'solid 3px #CFCFCF',
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableCompanyBody: {
    width: '300px',
    position: 'sticky',
    left: 0,
    borderRight: 'solid 3px #CFCFCF',
    fontSize: "12pt",
    wordBreak: "break-all",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableInChargeHead: {
    minWidth: '200px',
    left: 0,
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableChargeBody: {
    width: '200px',
    left: 0,
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
});

const CompanyPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [companyGroups, setCompanyGroups] = useState(null);
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const tmpUser = JSON.parse(localStorage.getItem("user"));
      setUser(tmpUser);
      const res = await axios.get('/company_groups');
      setOptions(res.data);
      setCompanyGroups(res.data);
    })();
  }, []);

  const linkToAddCompanyGroup = () => {
    props.history.push("/company_groups/add");
  };

  return (
    <>
      {(user?.company_id.is_osg && user?.is_admin) ? (
        <SubNavbar className="addCompanyGroupBtn">
          <OsgButton
            onClick={linkToAddCompanyGroup}
            style={{width:"250px"}}
          >
            <AddIcon />
            {t('企業グループの作成')}
          </OsgButton>
        </SubNavbar>
      ) : null}

      <TableContainer className={classes.table}>
        <Table className={classes.separate} size="small" aria-label="company table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCompanyHead} style={{backgroundColor: "#D4E7FF"}}>{t('企業グループ名')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} >{t('グループに属するカスタマー企業')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} >{t('グループ管理者')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}}>{t('詳細')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(companyGroups) ? (
              <>
                {companyGroups.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className={classes.tableCompanyBody} style={{backgroundColor: "#D4E7FF"}} component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>
                      {
                        row.companies.length > 0
                          ? row.companies.map((c, index) => (
                            <React.Fragment key={index}>
                              {c.name}{index < row.companies.length - 1 && <br />}
                            </React.Fragment>
                          ))
                          : null
                      }
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>
                      {
                        row.group_admin_users.length > 0
                          ? row.group_admin_users.map((u, index) => (
                            <React.Fragment key={index}>
                              {u.username} ({u.email}){index < row.group_admin_users.length - 1 && <br />}
                            </React.Fragment>
                          ))
                          : null
                      }
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">
                      <Button href={`/company_groups/${row._id}`} style={{color: "#00559D"}}>
                      {t('詳細')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
          ) : (
            <TableRow>
              <TableCell colSpan={9} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompanyPage;
