import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import styled from "styled-components";
import { OsgButton } from "../../Common/UIcomponents/index";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const Details = styled.div`
  max-width: 1000px;
  margin: 20px 0;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const useStyles = makeStyles((theme) => ({
  listTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#00559D"
  },
  listText:  {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  listTextInput:  {
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  submitBtn:  {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  dialogListTitle: {
    width: "40%",
  },
  dialogListText: {
    width: "60%"
  }
}));

const BoxEditPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  // const user = JSON.parse(localStorage.getItem('user'));
  const [box, setBox] = useState([]);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    name: '',
    currency: '',
    department: '',
    address: '',
    in_charge: '',
    tel: '',
    created_by: '',
    status: ''
  });
  const id = props.match.params.id;
  const boxStatus = [t('未稼働'), t('稼働中'), t('稼働停止'), t('非表示')];

  const companyList = [
    { name: null, label: t("企業名"), value: values.customer_id?.name },
    { name: "name", label: t("BOX名"), value: values.name },
    { name: "currency", label: t("BOXで扱う製品の通貨"), value: values.currency },
    { name: "department", label: t("BOXを設置する部署"), value: values.department },
    { name: "address", label: t("BOX設置先住所"), value: values.address },
    { name: "in_charge", label: t("BOX設置先の担当者"), value: values.in_charge },
    { name: "tel", label: t("電話番号"), value: values.tel },
    { name: null, label: t("BOX稼働ステータス"), value: values.status ? boxStatus[values.status] : boxStatus[0] }
  ];
  const agentCompanyList = [
    { name: t("BOX管理企業名"), value: box.agent_id?.name },
    { name: t("住所"), value: box.agent_id?.address },
    { name: t("BOX管理企業連絡先"), value:box.agent_id?.tel },
    { name: t("担当者名"), value: box.agent_id?.in_charge },
    { name: t("BOX登録日"), value: box.createdAt },
    { name: t("BOX稼働開始日"), value: box.updatedAt }
  ];

  useEffect(() => {
    (async () => {
      let editVal = {}
      const res = await axios.get(`boxes/${id}`);
      _.map(res.data, (val, key) => {
        if (typeof values[key] !== 'undefined') {
          editVal[key] = val
        }
      });
      setValues({ ...editVal });
      setBox({ ...res.data });
    })();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    axios.put(`/boxes/${id}`, values).then((res) => {
      if (res.data.success) {
        handleClose()
      }
      props.history.push(`/boxes/${id}`)
    });
  };

  return (
    <>
      <div style={{borderBottom: "solid 1px #DFDFDF"}}>
        <form className={classes.root} noValidate autoComplete="off">
          <Details>
            <div className={classes.listTitle}>
              <span style={{marginLeft: 15, fontWeight: "bold"}}>{t('BOX設置先企業情報')}</span>
            </div>
            <List style={{padding: 0}}>
              {companyList.map((v,index) => {
                return (
                  <ListItem
                    key={index}
                    className={classes.listItem}
                    dense
                    style={index === 3 || index === 7 ? {marginTop: 10} : {}}
                  >
                    <ListItemText className={classes.listName} primary={v.label} />
                    {v.name ?
                      <TextField
                        size="small"
                        className={classes.listTextInput}
                        name={v.name}
                        onChange={handleChange}
                        value={v.value}
                      />
                    :
                      <ListItemText className={classes.listText} primary={v.value} />
                    }
                  </ListItem>
                );
              })}
            </List>
            <div className={classes.submitBtn}>
              <OsgButton onClick={handleClickOpen}>
                {t('登録')}
              </OsgButton>
            </div>
          </Details>
        </form>
      </div>

      <Details>
        <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
          <span style={{marginLeft: 15, fontWeight: "bold"}}>{t('BOX管理企業情報')}</span>
        </div>
        <List style={{padding: 0}}>
          {agentCompanyList.map((v,index) => {
            return (
              <ListItem
                key={index}
                className={classes.listItem}
                dense
                style={index === 4 ? {marginTop: 10} : {}}
              >
                <ListItemText className={classes.listName} primary={v.name} />
                <ListItemText className={classes.listText} primary={v.value} />
              </ListItem>
            );
          })}
        </List>
      </Details>

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <BoldText>{t('登録確認')}</BoldText>
        </DialogTitle>
        <DialogContent>
          <List>
            {companyList.map((v,index) => {
              return (
                <ListItem key={index}>
                  <ListItemText className={classes.dialogListTitle}>
                    <BoldText>{v.label}</BoldText>
                  </ListItemText>
                  <ListItemText className={classes.dialogListText}>
                    <BoldText>{ v.value }</BoldText>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} autoFocus>
            <span className="dangerText">{t('登録')}</span>
          </Button>
          <Button onClick={handleClose}>
            {t('戻る')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BoxEditPage;
