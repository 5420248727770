import { Button } from "@material-ui/core";
import styled from "styled-components";

const ButtonStyle = styled(Button)`
  color: white !important;
  font-weight: 600 !important;
  height: 40px;
  width: 200px;
  background-color: #00559D !important;
  align-self: center;

  &:hover {
    background-color: #013f72 !important;
  }

  &:disabled {
    background: lightgray !important;
  }
`;

const OsgButton = (props) => {
  return (
    <ButtonStyle
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </ButtonStyle>
  );
};

export default OsgButton;
