// import { useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import authentication from "../../../utils/Auth";
import Loader from "../Loader/Loader";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  (async () => {
    const authValue = await authentication.isAuthenticated();
    setIsAuthenticated(authValue);
  })();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === null) {
          return <Loader />;
        } else if (isAuthenticated) {
          return <Component {...props} handleUpdateUserLang={rest.handleUpdateUserLang} />;
        } else {
          return <Redirect to="/signin" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
