import styled from "styled-components";
import logo from "../../assets/logo-topbar.svg"

const Title = styled.div`
  color: #00559D;
  border-bottom: solid 3px #00559D;
`;

const LoginTitle = () => {
  return (
    <Title>
      <img src={logo} style={{width: '250px'}} alt={logo}/>
    </Title>
  );
};

export default LoginTitle;
