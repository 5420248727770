import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Typography
} from '@material-ui/core';
import styled from "styled-components";
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';

const Details = styled.div`
  max-width: 1200px;
  text-align: right;
`;

const useStyles = makeStyles({
  listItem: {
    display: "flex",
    padding: "0",
    backgroundColor: "#00559D",
    marginBottom: "10px",
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "100%",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
  },
  listText:  {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#F6FBFF",
  },
  companyList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 20px",
    width: "70%",
    marginTop: "0",
    backgroundColor: "#F6FBFF",
  },
  dialogContents: {
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "0 5%",
    borderBottom: "solid 1px #000"
  },
  deleteConfirmButton: {
    color: "#C90000",
    marginLeft: "10px",
  },
  deleteConfirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "50px",
  },
  deleteConfirmDialogTitle: {
    textAlign: "center",
    color: "#C90000",
    fontSize: "20px",
    fontWeight: "bold"
  },
  deleteConfirmUserName: {
    textAlign: "center",
    fontWeight: "bold"
  },
  deleteConfirmDescription: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  deleteButton: {
    background: "#C90000",
    fontWeight: "bold",
    width: "200px",
    height: "42px",
    marginRight: "20px",
    '&:hover': {
      background: '#C90000'
    }
  },
  backButton: {
    width: "200px",
    height: "42px",
    marginRight: "20px",
  },
  deleteConfirmTitle: {
    fontWeight: "bold",
    marginTop: "2px",
    marginBottom: "2px",
  },
  deleteConfirmImportantList: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  deleteConfirmImportantListItem: {
    color: "#C90000",
    marginTop: "2px",
    marginBottom: "2px",
  }
});

const CompanyDetailPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [id, setId] = useState('');
  const [companyGroup, setCompanyGroup] = useState({});
  const [user, setUser] = useState(null);
  const [deletable, setDeletable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    const id = props.match.params.id;
    setId(id);
    (async () => {
      const res = await axios.get(`company_groups/${id}`);
      setCompanyGroup(res.data);
    })();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user)
    if (user.company_id.is_osg && user.is_admin) {
      setDeletable(true);
    }
  }, []);

  const handleDeleteClick = async e => {
    const res = await axios.delete(`/company_groups/${id}`);
    if (res.data.success) {
      alert(t('削除しました'));
      props.history.push('/company_groups');
    }
  };

  return (
    <>
      <Details>
        {(user && (user.company_id?.is_osg && user.is_admin)) ? (
          <Button color="primary" href={`/company_groups/edit/${companyGroup._id}`}> {t("編集")} </Button>
        ) : null}
        <List>
          <ListItem dens className={classes.listItem}>
            <ListItemText className={classes.listTitle} primary={t("企業グループ名")} />
            <div className={classes.companyList}>
              <ListItemText className={classes.listText} primary={companyGroup.name} />
            </div>
          </ListItem>
          <ListItem dense className={classes.listItem} >
            <ListItemText className={classes.listTitle} primary={t("グループに属する企業")} />
            <div className={classes.companyList}>
              {companyGroup?.companies ? companyGroup.companies.map((company, index) => (
                <Typography key={index} className={classes.listText}>
                  {company.name}
                </Typography>
              )) : null}
            </div>
          </ListItem>
          <ListItem dense>
            <ListItemText primary={t('※「グループ管理者」は他社に対して閲覧できる (稼働状況、購入履歴、分析、ログ、ユーザー一覧)')} />
          </ListItem>
          <ListItem dense className={classes.listItem}>
            <ListItemText className={classes.listTitle} primary={t("グループ管理者")} />
            <div className={classes.companyList}>
              {companyGroup?.companies ? companyGroup.group_admin_users.map((company, index) => (
                <Typography key={index} className={classes.listText}>
                  {company.username}({company.email})
                </Typography>
              )) : null}
            </div>
          </ListItem>
          { deletable ?
            <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("グループの削除")} </Button>
          : null }
        </List>
      </Details>

      <Dialog
        open={showDeleteDialog} fullWidth maxWidth="sm">
        <div className={classes.deleteConfirmDialogContainer}>
          <div className={classes.deleteConfirmDialogTitle}>
            {t("企業グループの削除")}
          </div>
          <div className={classes.deleteConfirmUserName}>
            {t("対象企業グループ")}:&nbsp;&nbsp;{companyGroup.name}
          </div>

          <div className={classes.deleteConfirmDescription}>
            {t("企業グループを削除をすると、グループ管理者は、他社にアクセス、操作ができなくなります。")}
          </div>

          <div className={classes.deleteConfirmDescription}>
            {t("本当に削除しますか？")}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="outlined"
              onClick={ () => setShowDeleteDialog(false)}
              className={classes.backButton}
            >
              {t("戻る")}
            </Button>
            <Button
              className={classes.deleteButton}
              color="primary"
              variant="contained"
              onClick={handleDeleteClick}
            >
              {t("削除する")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CompanyDetailPage;
