// import React, { useState } from "react";
import AppRouter from "./AppRouter";
import SideNav from "../SideNav";
// import HeaderBar from "../HeaderBar";
import Loading from "../Loading";
import styled from "styled-components";
import { BreadCrumbs } from "../UIcomponents"
import history from "../../../utils/history";

const AppLayout = (props) => {
  // const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  //
  // const toggleVisibility = () => {
  //   setIsSideBarVisible(!isSideBarVisible);
  // };
  const AppLayout = styled.div``;
  const ContentsWrapper = styled.div`
    display: flex;
    min-height: 100vh;
    overflow: auto;
  `;
  const MainMenu = styled.div`
    width: 220px;
    box-sizing: border-box;
    background: lightgrey;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  const MainContents = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    position: relative;
    background-color: #fff;
    padding: 0px 20px;
    overflow-x: hidden;

    .mainContents {
      height: 100%;
      display: flex;
      flex-direction: column;

      .contentsBody {
        flex: 1;
        width: 100%;
      }
    }
  `;

  return (
    <AppLayout>
      {(history.location.pathname.match(/^\/logs\/battery\//)) ? (
        <AppRouter handleUpdateUserLang={props.handleUpdateUserLang} />
      ) : (
        <ContentsWrapper>
          <MainMenu>
            <SideNav />
          </MainMenu>
          <MainContents>
            <BreadCrumbs />
            <div style={{marginTop: "20px"}}>
              <AppRouter handleUpdateUserLang={props.handleUpdateUserLang} />
            </div>
            <Loading />
          </MainContents>
        </ContentsWrapper>
      )}
    </AppLayout>
  );
};

export default AppLayout;
