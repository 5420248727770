// import React from "react";
import {useEffect} from "react";
import axios from 'axios';
import { useUserContext } from '../../../contexts/UserContext';

const SignOutView = (props) => {
  const { handleRemoveUser } = useUserContext();

  useEffect(() => {
      handleRemoveUser();
      localStorage.removeItem("auth-token");
      localStorage.removeItem("user");
      localStorage.removeItem("parent-auth-token");
      localStorage.removeItem("parent-user");
      axios.defaults.headers.common['x-access-token'] = '';
      props.history.push('/')
    }
    , [])
  return (
    null
  );

}

export default SignOutView;
