import { Bar } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import styled from "styled-components";
import moment from 'moment-timezone';
import { OsgSelect } from "../../Common/UIcomponents";
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {NumericFormat} from "react-number-format"
import {makeStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import {
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  Input,
  ListItemText
} from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup
} from '@material-ui/lab';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip
);

export const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      title: {
        align: "end",
        display: true,
        text: "点",
      },
      stacked: true,
    },
  }
};

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .btn {
    margin-right: 20px;
    text-decoration: none
  }

  .toolNoSelect {
    width: 100%;
    text-align: right;
  }
`;

const Warning = styled.span`
  color: red
`;

const styles = {
  tabgroup:{
    marginBottom:"28px",
    border:"2px solid #00559D",
    borderradius:"8px",
    height:"42px",
  },
  tabButton:{
    color:"#00559D",
    fontSize:16,
    fontWeight:"bold",
    width:"138px",
    height:"42px",
    fontSize:"14px",
    border:"none",
    borderRadius:"0px",
  },
  dropdownbutton:{
    background:"#D4E7FF",
    width:"245px",
    height:"40px",
    border:"1px solid #AFAFAF",
    borderRadius:"5px",
    position:"relative",
    textAlign:"left",
    paddingLeft:"14px",
  },
  selectTxt:{
    marginTop:"4px",
    background:"#D4E7FF",
    height:"38px",
    lineHeight:"38px",
  }
};

const SelectBox = styled(Select)`
  background-color: #D4E7FF;

  .icon {
    position: absolute;
    right: 0;
    height: 40px;
    width: 40px;
    color: #fff;
    font-size: 25px;
    background-color: #00559D;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
  }
`;

const useStyles = makeStyles({
  tabgroup: {
    borderColor:"none",
  },

  tabButton:{
    background:"white",
    "&:hover":{
      backgroundColor:"white",
    },
  },

  selectBtn: {
    backgroundColor: "#00559D !important",
    color:"white !important",
    width: "100px",
    "&:hover":{
      backgroundColor: "none",
  }
  },

  dropdownBtn:{
    background:"#D4E7FF !important",
    height:"40px",
    "&::before":{
      content: '""',
      display:"none",
    },
    "&::after":{
      content: '""',
      display:"none",
    },
  },

  item:{
    width:"230px",
    margin:"0px 4px",
    background:"white !important",
    paddingLeft:10,
    "&:hover":{
      background:"#D4E7FF !important",
    },
  },
  itemSelected:{
    width:"230px",
    margin:"0px 4px",
    background:"white !important",
    "&:hover":{
      background:"#D4E7FF !important"
    } ,
  },

  period:{
    width:"auto",
    background:"white !important",
    textAlign:"center",
    paddingLeft:"0px !important",

  },
  periodSelected:{

  },
  chartZhPeriod:{
    fontWeight:"bold",
    fontSize:"20px",
    marginLeft:"20px",
    fontFamily: `
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Noto Sans TC",
      "PingFang TC",
      "Hiragino Sans CNS",
      "Microsoft JhengHei"
    `,
  },
  chartPeriod:{
    fontWeight:"bold",
    fontSize:"20px",
    marginLeft:"20px",
  }
});


const Chart = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [boxes, setBoxes] = useState([]);
  const [options, setOptions] = useState([]);
  const [boxId, setBoxId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [total, setTotal] = useState(0);
  const [term, setTerm] = useState(1);
  const [halfPeriodStart, setHalfPeriodStart] = useState(1);
  const [format, setFormat] = useState(t('YYYY年MM月DD日'));
  const [statisticsBase, setStatisticsBase] = useState({labels:[], datasets:[]}); // 1度取得したら動かさない
  const [statistics, setStatistics] = useState({labels:[], datasets:[]}); // グラフ追加表示するのチェックで動く
  const [showGraph, setShowGraph] = useState(false);
  const [graph, setGraph] = useState('edpNo');
  const [graphOptions] = useState([{
      name: t('ツールNo'),
      value: 'edpNo'
    },
    {
      name: t('呼び'),
      value: 'designation'
    }
  ]);
  const [toolTipOptions] = useState({
    edpNo: 'ツールNo',
    designation: '呼び',
    abbreviation: '製品記号'
});
  const [toolNos, setToolNos] = useState([]);
  const [selectedToolNos, setSelectedToolNos] = useState(['']);
  const [type, setType] = useState('1');
  const [currency, setCurrency] = useState('円');
  const [timezone, setTimezone] = useState('Japan');
  const [max, setMax] = useState(null);
  const [productTotal, setProductTotal] = useState(null);
  const [tickColor, setTickColor] = useState([]);
  const [itemColors, setItemColors] = useState([]);
  const [isNoPrice, setIsNoPrice] = useState(false);
  const [themParams, setThemeParams] = useState(
    {
      palette: {
        primary: {
          main: "#D4E7FF"
        },
        secondary:{
          main: "#00559D"
        },
      }
    }
  );
  const currentUser = JSON.parse(localStorage.getItem('user'));

  let chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        // ticks: {
        //   color: tickColor,
        // }
      },
      y: {
        title: {
          align: "end",
          display: true,
          text: type === '1' ? t("点") : currency,
        },
        max: max,
        stacked: true,
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function(context) {
            const additionalInfo = toolTipOptions[context.dataset.name];
            const label = context.dataset.label || '';
            let value;
            if (type === '1') {
              value = `${t('点数')}: ${context.raw}`;
            } else {
              value = `Total: ${context.raw} ${currency}`;
            }
            const result = [`${t(additionalInfo)}`, label, value];
            if (context.dataset.abbreviation) {
              result.splice(3, 0, t('製品記号'));
              result.splice(4, 0, context.dataset.abbreviation);
            }
            return result;
          }
        }
      }
    }
  };


  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.lang === 'zh') {
        ChartJS.defaults.font.family = [
          "-apple-system,BlinkMacSystemFont",
          "Segoe UI","Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "Roboto",
          "Helvetica",
          "Arial",
          "sans-serif",
          "Noto Sans TC",
          "PingFang TC",
          "Hiragino Sans CNS",
          "Microsoft JhengHei",
        ].join(',');
        setThemeParams({
          palette: {
            primary: {
              main: "#D4E7FF"
            },
            secondary:{
              main: "#00559D"
            },
          },
          typography: {
            fontFamily: [
              "-apple-system,BlinkMacSystemFont",
              "Segoe UI","Roboto",
              "Oxygen",
              "Ubuntu",
              "Cantarell",
              "Fira Sans",
              "Droid Sans",
              "Helvetica Neue",
              "Roboto",
              "Helvetica",
              "Arial",
              "sans-serif",
              "Noto Sans TC",
              "PingFang TC",
              "Hiragino Sans CNS",
              "Microsoft JhengHei",
            ].join(','),
          },
        });
      } else {
        ChartJS.defaults.font.family = [
          'Helvetica Neue',
          'Helvetica',
          'Arial',
          'sans-serif'
        ].join(',');
        setThemeParams({
          palette: {
            primary: {
              main: "#D4E7FF"
            },
            secondary:{
              main: "#00559D"
            },
          },
        });
      }
      setUser(user);
      const companiesRes = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      setOptions(companiesRes.data);

      setTo(moment().endOf('isoWeek'));
      setFrom(moment().add('isoWeek', -1).endOf('isoWeek').add(1, 'd'));

      setIsVisible(true)
    })();
  }, []);

  useEffect(() => {
    const stats = statisticsBase;
    let d = {...stats};
    d.datasets = filterByToolNo(d.datasets);
    checkTickColor(d.datasets)
    filterProductTotalByToolNo(d.datasets);
    setStatistics(d);
  }, [selectedToolNos]);

  useEffect(() => {
    getStatistics();
  }, [boxId]);

  useEffect(() => {
    getStatistics();
    const d = {...statisticsBase}
    filterProductTotalByToolNo(d.datasets);
    changeMax()
  }, [to]);

  useEffect(() => {
    getStatistics();
    const d = {...statisticsBase}
    filterProductTotalByToolNo(d.datasets);
    changeMax()
  }, [type]);

  useEffect(() => {
    const d = {...statisticsBase}
    if (!(selectedToolNos.length === 1 && selectedToolNos[0] === "")) {
      d.datasets = filterByToolNo(d.datasets);
      checkTickColor(d.datasets)
    }
    filterProductTotalByToolNo(d.datasets);
    if (showGraph) {
      // ツールNoか呼びを表示
      d.datasets = _.filter(d.datasets, v => {
        return v.name === graph || v.name === 'abbreviation';
      });
      setStatistics(d);
    } else {
      // 製品記号のグラフのみ表示
      d.datasets = _.filter(d.datasets, v => {
        return v.name === 'abbreviation';
      });
      setStatistics(d);
    }
  }, [showGraph, graph]);

  useEffect(() => {
    const d = {...statisticsBase}
    const tmpProductTotal = filterProductTotalByToolNo(d.datasets);
    changeMax(tmpProductTotal)
  }, [statisticsBase]);

  useEffect(() => {
    const d = {...statisticsBase}
    const tmpProductTotal = filterProductTotalByToolNo(d.datasets);
    changeMax(tmpProductTotal)
  }, [productTotal]);

  const changeMax = (productTotal) => {
    switch (type) {
      case '1':
        if (productTotal < 25 && !max) {
          setMax(25)
          chartOptions.scales.y.max = 25;
        } else if (productTotal === 25) {
          setMax(30)
          chartOptions.scales.y.max = 30;
        } else if (productTotal < 25) {
          setMax(25)
          chartOptions.scales.y.max = 25;
        } else {
          setMax(null)
          chartOptions.scales.y.max = null;
        }
        break;
      case '2':
        if (productTotal < 10000) {
          setMax(10000)
          chartOptions.scales.y.max = 10000;
        } else if (productTotal === 10000 && !max) {
          setMax(12000)
          chartOptions.scales.y.max = 12000;
        } else if (productTotal < 10000) {
          setMax(10000)
          chartOptions.scales.y.max = 10000;
        } else {
          setMax(null)
          chartOptions.scales.y.max = null;
        }
        break;
      default:
        setMax(null)
        chartOptions.scales.y.max = null;
        break;
    }
  }

  const filterByToolNo = (datasets) => {
    let d = [...datasets]
    if (showGraph) {
      // ツールNoか呼びを表示
      d = _.filter(d, v => {
        return v.name === graph || v.name === 'abbreviation';
      });
    } else {
      // 製品記号のグラフのみ表示
      d = _.filter(d, v => {
        return v.name === 'abbreviation';
      });
    }
    if (selectedToolNos.length === 1 && selectedToolNos[0] === "") {
      return d;
    }
    d = _.filter(d, v => {
      if (selectedToolNos.indexOf(v.label) > -1) {
        return true;
      }
      return showGraph && selectedToolNos.indexOf(v.abbreviation) > -1;
    });
    return d;
  };

  const filterProductTotalByToolNo = (datasets) => {
    let d = [...datasets]
    if (selectedToolNos.length === 1 && selectedToolNos[0] === "") {
      let sumList = _.map(d, (dm) => {
        if (!dm.abbreviation) {
          return _.sum(dm.data)
        }
      })
      if (sumList.length > 0) {
        setProductTotal(_.sum(sumList))
      } else {
        setProductTotal(0);
      }
      return _.sum(sumList);
    } else {
      d = _.filter(d, v => {
        if (selectedToolNos.indexOf(v.label) > -1) {
          return true;
        }
        return showGraph && selectedToolNos.indexOf(v.abbreviation) > -1;
      });

      let sumList = _.map(d, (dm) => {
        if (!dm.abbreviation) {
          return _.sum(dm.data)
        }
      })

      if (sumList.length > 0) {
        setProductTotal(_.sum(sumList))
      } else {
        setProductTotal(0);
      }
      return _.sum(sumList);
    }
  };

  const getStatistics = async () => {
    if (boxId === "") {
      return;
    }
    const res = await axios.get('/purchases/statistics', {
      params: {
        analytics_type: type,
        period_type: term,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
        box_id: boxId,
      }
    });

    setTotal(res.data.total);

    const getColor = (min, max) => Math.floor(Math.random() * (max - min)) + min;
    let tmpItemColors = itemColors;
    let itemColorIndex = null;
    for (let i = 0; i < res.data.datasets.length; i++) {
      itemColorIndex = tmpItemColors.findIndex((ic) => {
        return ic.label === res.data.datasets[i].label
      })
      if (itemColorIndex === -1) {
        res.data.datasets[i].backgroundColor = `rgb(${getColor(50, 100)}, ${getColor(100, 220)}, ${getColor(140, 245)})`;
        tmpItemColors.push({ label: res.data.datasets[i].label, backgroundColor: res.data.datasets[i].backgroundColor });
      } else {
        res.data.datasets[i].backgroundColor = tmpItemColors[itemColorIndex].backgroundColor;
      }

      if (res.data.datasets[i].abbreviation) {
        res.data.datasets[i].stack = 'stack2';
      } else {
        res.data.datasets[i].stack = 'stack1';
      }
    };
    const d = {...res.data}
    setItemColors(tmpItemColors);
    setStatisticsBase(d);

    if (showGraph) {
      // ツールNoか呼びを表示
      res.data.datasets = _.filter(res.data.datasets, v => {
        return v.name === graph || v.name === 'abbreviation';
      });
    } else {
      // 製品記号のグラフのみ表示
      res.data.datasets = _.filter(res.data.datasets, v => {
        return v.name === 'abbreviation';
      });
    }

    // 製品記号でフィルター
    if (!(selectedToolNos.length === 1 && selectedToolNos[0] === "")) {
      res.data.datasets = filterByToolNo(d.datasets);
      filterProductTotalByToolNo(d.datasets);
    }

    if (res.data.datasets.length === 0) {
      for (let i = 0; i< 7; i++) {
        res.data.datasets.push([0,0,0,0,0,0,0]);
      }
    }
    const isNullPrice = _.find(d.datasets, (dataset) => dataset.isNull === true);

    if (isNullPrice) {
      setIsNoPrice(true);
    } else {
      setIsNoPrice(false);
    }

    checkTickColor(d.datasets)

    setStatistics(res.data);
  };

  const checkTickColor = (datasets) => {
    let colors = [];
    if (type === '2') {
      _.map(datasets, (dataset) => {
        _.map(dataset.data, (d, i) => {
          if (d === null && (!colors[i] || colors[i] === '#000000')) {
            colors[i] = 'red';
          } else if (!colors[i]) {
            colors[i] = '#000000';
          }
        });
      });
    }
    setTickColor(colors);
  };

  const handleShowGraph = (e) => {
    const checked = e === undefined ? showGraph : e.target.checked;
    setShowGraph(checked);
  };

  const handleSetToday = async () => {
    setDefaultDate();
  };

  const handleChangeHalfPeriodStart = (e) => {
    const start = e.target.value;
    setHalfPeriodStart(start);
    const fromYear = moment(from).year();
    const currentFromYear = moment().year();
    const diff = fromYear - currentFromYear;
    const tmpFrom = moment().add(diff, "year").month(start - 1).startOf('month');
    const tmpTo = tmpFrom.clone();
    setFrom(tmpFrom)
    setTo(tmpTo.add(5, "month").endOf('month'));
  };

  const handleChangeYearlyStart = (e) => {
    const start = e.target.value;
    setHalfPeriodStart(start);
    const fromYear = moment(from).year();
    const currentFromYear = moment().year();
    const diff = fromYear - currentFromYear;
    const tmpFrom = moment().add(diff, "year").month(start - 1).startOf('month');
    const tmpTo = tmpFrom.clone();
    setFrom(tmpFrom)
    setTo(tmpTo.add(11, "month").endOf('month'));
  };

  const setDefaultDate = () => {
    switch (term) {
      case 1:
        setTo(moment().tz(timezone).endOf('isoWeek'));
        setFrom(moment().tz(timezone).add('isoWeek', -1).endOf('isoWeek').add(1, 'd'));
        break;
      case 2:
        setTo(moment().tz(timezone).endOf('month'));
        setFrom(moment().tz(timezone).startOf('month'));
        break;
      case 3:
        setTo(moment().tz(timezone).endOf('month'));
        setFrom(moment().tz(timezone).add(-5, "M").startOf('month'));
        break;
      case 4:
        setTo(moment().tz(timezone).endOf('year'));
        setFrom(moment().tz(timezone).startOf('year'));
        break;
      default:
    }
  };

  const isToday = () => {
    if (term === 1) {
      return from.format('YYYY/MM/DD') === moment().add('isoWeek', -1).endOf('isoWeek').add(1, 'd').format('YYYY/MM/DD')
    } else if (term === 2) {
      return from.format('MM') === moment().format('MM')
    } else if (term === 3) {
      return to.format('YYYY/MM') === moment().format('YYYY/MM')
    } else if (term === 4) {
      return from.format('YYYY') === moment().format('YYYY')
    } else {
      return false;
    }
  };

  const handleChangeTerm = async (event) => {
    const value = event.target.value;
    let tmpFrom = from;
    let tmpFromYear = tmpFrom.format('YYYY');
    switch (value) {
      case 1:
        setTo(moment().endOf('isoWeek'));
        setFrom(moment().add('isoWeek', -1).endOf('isoWeek').add(1, 'd'));
        setFormat(t("YYYY年MM月DD日"));
        break;
      case 2:
        if (term < value) {
          setFrom(moment(tmpFrom).startOf('month'));
          setTo(moment(tmpFrom).endOf('month'));
          setFormat(t("YYYY年MM月"));
        } else {
          setFrom(moment().startOf('month'));
          setTo(moment().endOf('month'));
          setFormat(t("YYYY年MM月"));
        }
        break;
      case 3:
        const from = moment(`${tmpFromYear}/01/01`).month(halfPeriodStart - 1).startOf('month');
        const to = from.clone();
        setFrom(from)
        setTo(to.add(5, "month").endOf('month'));
        setFormat(t("YYYY年MM月"));
        setHalfPeriodStart(1);
        break;
      case 4:
        if (term < value) {
          setTo(moment(tmpFrom).endOf('year'));
          setFrom(moment(tmpFrom).startOf('year'));
          setFormat(t("YYYY年"));
        } else {
          setTo(moment().endOf('year'));
          setFrom(moment().startOf('year'));
          setFormat(t("YYYY年"));
        }
        break;
      default:
        break;
    }
    setTerm(value);
  };

  const handleGoPast = async () => {
    setDate(-1);

  }

  const handleGoFuture = async () => {
    setDate(1);
  }

  const setDate = val => {
    switch (term) {
      case 1:
        setTo(moment(to).add(val * 7, 'd'));
        setFrom(moment(from).add(val * 7, 'd'));
        break;
      case 2:
        setTo(moment(to).add(val * 1, 'M').endOf('month'));
        setFrom(moment(from).add(val * 1, 'M'));
        break;
      case 3:
        setTo(moment(to).add(val * 6, 'M').endOf('month'));
        setFrom(moment(from).add(val * 6, 'M'));
        break;
      case 4:
        setTo(moment(to).add(val * 1, 'Y'));
        setFrom(moment(from).add(val * 1, 'Y'));
        break;
      default:
        setTo(moment(to).add(val * 7, 'd'));
        setFrom(moment(from).add(val * 7, 'd'));
    }
  }

  const handleChangeCompany = async (event) => {
    const companyId = event.target.value;
    setBoxes([]);
    setCompanyId(companyId);
    setSelectedToolNos(['']);
    setProductTotal(0);
    setStatisticsBase({labels:[], datasets:[]});
    setStatistics({labels:[], datasets:[]});

    if (companyId) {
      const res = await axios.get(`boxes/customer/${ companyId }`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.data !== null) {
        setBoxes(res.data);
      }
    } else {
      setBoxes([])
      setBoxId('');
    }
  };

  const handleChangeBox = async (event) => {
    const boxId = event.target.value;
    setBoxId(boxId);
    const res = await axios.get(`purchases/in_box/abbreviation/${boxId}`);
    setSelectedToolNos(['']);
    if (res.data !== null) {
      setToolNos(res.data);
    } else {
      setProductTotal(0);
    }
    const boxRes = await axios.get(`boxes/${boxId}`);
    if (boxRes.data !== null) {
      setCurrency(boxRes.data.currency);
      if (boxRes.data.timezone_id) {
        setTimezone(boxRes.data.timezone_id.value);
      } else {
        setTimezone('Japan');
      }
    }
  };

  const handleChangeToolNos = async (event) => {
    const toolNo = event.target.value;
    if (selectedToolNos.length === 1 && selectedToolNos[0] === "") {
      // すべての製品にチェックがあるだけ
      const t = toolNo.filter(v => {
        return v !== "";
      });
      setSelectedToolNos(t);
    } else {
      if (toolNo.indexOf("") > -1) {
        setSelectedToolNos(['']);
      } else {
        setSelectedToolNos(toolNo);
      }
    }
  };

  const handleChangeType = (event, newType) => {
    if (newType) {
      setType(newType);
      handleShowGraph();
    }
  };

  const handleChangeGraph = (event) => {
    const value = event.target.value;
    setGraph(value);
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
    anchorOrigin: { vertical: "top", horizontal: "left" },
    getContentAnchorEl: null,
  };

  return <>
    <MuiThemeProvider theme={createTheme(themParams)}>
      <h4>{t("購入")}</h4>
      <div style={{justifyContent: "space-between", display:"flex", height: "120px", maxWidth:"calc(100%)"}}>
        <div>
          <ToggleButtonGroup
            style={styles.tabgroup}
            value={type}
            exclusive
            onChange={handleChangeType}
            aria-label="text alignment"
            className='tabgroup'
          >
            <ToggleButton
              id="1"
              style={styles.tabButton }
              classes={{root:classes.tabButton,selected:classes.selectBtn}}
              value="1" aria-label="left aligned"
            >
              {t("本数")}
            </ToggleButton>
            <ToggleButton
              id="2"
              style={styles.tabButton}
              className = { type === "1" ?  classes.tabButton: classes.selectBtn }
              value="2" aria-label="justified"
            >
              {t("金額")}
            </ToggleButton>
          </ToggleButtonGroup>
            <div style={{borderRadius: 10, width:"auto",minWidth:"276px", height:"70px", backgroundColor:"#d4e7ff", display:"table",verticalAlign:"center",textAlign:"center",padding:"0px 40px"}}>
              <div style={{color:"#00559d", height:"70px",fontWeight:"bold",display:"flex",justifyContent:"space-between" ,verticalAlign:"center",alignItems: "center"}}>
              <div style={{fontSize:16,paddingRight:"32px"}}>{t("期間合計")}</div>
              <NumericFormat style={{fontSize:30,paddingRight:"32px"}} value={productTotal} displayType="text" thousandSeparator="," />
              <div style={{fontSize:16}}>{type === "1" ? t("点") : currency}</div>
            </div>
          </div>
        </div>
        <div>
          <SubNavbar style={{justifyContent: 'flex-end'}}>
            { customerId != null ?
              <OsgSelect onChange={handleChangeCompany} defaultValue={customerId} style={{width: '245px', marginRight: "20px"}}>
                <option value="">{t("企業を選択")}</option>
                {options.map((v) => {
                  return (
                    <option value={ v._id } key={ v._id }>
                      {v.name}
                    </option>
                  );
                })}
              </OsgSelect>
              : (
                <>
                  <div style={{width: '245px', justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                </>
              )}
            { boxId !== null && boxes !== null ?
              <OsgSelect onChange={handleChangeBox} defaultValue={ boxId } style={{width: "245px"}}>
                <option value="">{t("BOXを選択")}</option>
                {boxes.map((v) => {
                  return (
                    <option value={ v._id } key={ v._id }>
                      {v.name}
                    </option>
                  );
                })}
              </OsgSelect>
              : (
                <>
                  <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                </>
              )}
          </SubNavbar>
          <SubNavbar>
            <div className="toolNoSelect" style={{height:70}} >
              { isVisible ? <>
                <span style={{display:"inline-block", paddingTop:"10px", paddingRight:"20px"}}>{t("製品記号")}</span>
                <FormControl variant="outlined" style={{minwidth: "245px", height:"40px",border:"0px"}} size="small">
                  <SelectBox
                    style={styles.dropdownbutton}
                    className={classes.dropdownBtn}
                    labelId="abbreviation-label"
                    id="abbreviation"
                    multiple
                    renderValue={(selected) =>
                      {
                        if(selected[0] === ''){
                          return <div style={styles.selectTxt}>{t("すべての製品")}</div>
                        }else if(selected !== ""){
                          return <div style={styles.selectTxt}>{t("部分選択")}</div>
                        }
                      }
                    }
                    value={selectedToolNos}
                    onChange={handleChangeToolNos}
                    input={<Input />}
                    MenuProps={MenuProps}
                    IconComponent={() => {
                      return <div className="icon">▼</div>
                    }}
                  >
                      <MenuItem style={{height:40,textAlign:"left"}} classes={{root: classes.item,selected: classes.itemSelected}}
                      key={""} value={""}>
                        <Checkbox className={classes.checkbox} color="secondary" checked={selectedToolNos.indexOf('') > -1}  />
                        <ListItemText primary={t("すべての製品")} />
                      </MenuItem>
                    {toolNos.map(v => (
                      <MenuItem style={{height:40,position:"relative"}}
                      classes={{selected: classes.itemSelected, root: classes.item}}
                      key={v._id}
                      value={v._id}
                      >
                        <Checkbox style={{paddingLeft:"20px"}} className={classes.checkbox} color="secondary" checked={selectedToolNos.indexOf(v._id) > -1} />
                        <ListItemText primary={v._id} />
                      </MenuItem>
                    ))}
                  </SelectBox>
                  <div
                      style={{
                        position:"absolute",
                        marginTop:"52px",
                        right:"0px",
                        color:"#00559D",
                        background:"#F6FBFF",
                        display:"flex",
                        textAlign:"center",
                        justifyContent:"space-between",
                        fontSize:"16px",
                        lineHeight:"22px",
                      }}
                      >
                      {selectedToolNos.map((item, index) => {
                        if(index === 0 && item === ''){
                          return
                        }
                        if(index >= 5){
                          if(index === 5){
                            return <div style={{padding:"8px 8px", height:"22px",width:"auto", minWidth:"auto"}}>{t("他")}</div>
                          }
                        } else {
                          return (
                            <div style={{padding:"8px 8px", height:"22px",width:"", whiteSpace:"nowrap",minWidth:"74px"}} key={index}>
                              {item}{selectedToolNos.length !== (index + 1)  ? ',' : ''}
                            </div>
                          )
                        }
                      })}
                    </div>
                </FormControl>
              </>: (
                <>
                  <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                </>
              )}
            </div>
          </SubNavbar>
          <SubNavbar>
            <div className="toolNoSelect" style={{height:40}} >
              <FormControlLabel
                style={{justifyContent: 'flex-end'}}
                control={<Checkbox size="small" className={classes.checkbox} onChange={handleShowGraph} checked={showGraph} />} label={t("グラフ追加表示")}
              />
              <OsgSelect onChange={handleChangeGraph} defaultValue={ boxId } style={{width: "245px"}}>
                {graphOptions.map((g, i) => {
                  return (
                    <option value={ g.value } key={ `graph_${i}` }>
                      {g.name}
                    </option>
                  );
                })}
              </OsgSelect>
            </div>
          </SubNavbar>
        </div>
      </div>
      {boxId && <>
        <div style={{paddingTop:50,display:"flex",justifyContent:"space-between"}}>
          <div style={{marginBottom:"15px"}}>
          <Button style={{width:92}} variant="outlined" onClick={handleSetToday} disabled={isToday()}>{t("最新")}</Button>
          <FontAwesomeIcon icon={faAngleLeft} size="2xl" style={{color: "#afafaf", marginLeft:"20px"}} onClick={handleGoPast}/>
          <FontAwesomeIcon icon={faAngleRight} size="2xl" style={{color: "#afafaf", marginLeft:"20px"}} onClick={handleGoFuture}/>
          <span className={user.lang === 'zh' ? classes.chartZhPeriod : classes.chartPeriod}>
            {
              (term === 1 || term === 3) && from ?
                from.format(format) + " - " :
              (term === 4 && from) ?
                from.format(format)
              : null}
            {(term !== 4) && to && to.format(format)}
          </span>
          <Select
            variant="outlined"
            style={{height:"37px", marginLeft:"20px",overflow:"hidden",paddingLeft:"20px"}}
            value={term}
            onChange={handleChangeTerm}
            classes={{root:classes.period,selected:classes.periodSelected}}
            MenuProps={{anchorOrigin: { vertical: "top", horizontal: "left" }}}
          >
            <MenuItem className={ classes.itemSelected } value={1}>{t("週")}</MenuItem>
            <MenuItem className={ classes.itemSelected }
              value={2}>{t("月")}</MenuItem>
            <MenuItem className={ classes.itemSelected } value={3}>{t("半期")}</MenuItem>
            <MenuItem className={ classes.itemSelected } value={4}>{t("年")}</MenuItem>
          </Select>
          {(term === 3 || term === 4) && <>
            <span style={{fontSize:16, display:"inline-block", paddingTop:"10px", paddingLeft:"24px", paddingRight:"24px"}}>{t("始まり")}</span>
            <Select
              variant="outlined"
              style={{height:"37px",  overflow:"hidden",paddingLeft:"20px"}}
              classes={{root:classes.period,selected:classes.periodSelected}}
              value={halfPeriodStart}
              onChange={(term === 3) ? handleChangeHalfPeriodStart : handleChangeYearlyStart}
              MenuProps={{anchorOrigin: { vertical: "top", horizontal: "left" }}}
            >
              {_.map([1,2,3,4,5,6,7,8,9,10,11,12], (i, k) => (
                <MenuItem className={ classes.itemSelected } value={i} key={k}>
                  {(currentUser.lang === 'en') ? (
                    <>
                      {moment(`2022-${i}`).format('MMM')}
                    </>
                  ) : (
                    <>
                      {i} {t("月")}
                    </>
                  )}
                </MenuItem>
              ))}
            </Select>
            </>
          }
        </div>
        </div>
        <Bar style={{backgroundColor:"#f6f8f9",padding:28, maxWidth:"100%", maxHeight: window.innerHeight - 364}} data={statistics} options={chartOptions} />
        {(isNoPrice) && (<Warning>{t("※在庫データの単価が未登録のため、グラフに表示されない購入があります。「本数」ビューで参照してください。")}</Warning>)}
      </>
      }
    </MuiThemeProvider>
  </>;
}
export default Chart;