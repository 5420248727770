import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import BoxPage from "../../pages/box/BoxPage";
import BoxDetailPage from "../../pages/box/BoxDetailPage";
import BoxAddPage from "../../pages/box/BoxAddPage";
import BoxEditPage from "../../pages/box/BoxEditPage";
import ChartPage from "../../pages/statistics/ChartPage";
import HomePage from "../../pages/home/HomePage";
import LogPage from "../../pages/log/LogPage";
import BatteryLogPage from "../../pages/log/BatteryLogPage";
import UserPage from "../../pages/user/UserPage";
import UserAddPage from "../../pages/user/UserAddPage";
import UserEditPage from "../../pages/user/UserEditPage";
import CompanyPage from "../../pages/company/CompanyPage";
import CompanyAddPage from "../../pages/company/CompanyAddPage";
import CompanyEditPage from "../../pages/company/CompanyEditPage";
import CompanyDetailPage from "../../pages/company/CompanyDetailPage";
import CompanyGroupPage from "../../pages/company_group/CompanyGroupPage";
import CompanyGroupAddPage from "../../pages/company_group/CompanyGroupAddPage";
import CompanyGroupEditPage from "../../pages/company_group/CompanyGroupEditPage";
import CompanyGroupDetailPage from "../../pages/company_group/CompanyGroupDetailPage";
import TopupPage from "../../pages/topup/TopupPage";
import HistoryPage from "../../pages/history/HistoryPage";
import StockPage from "../../pages/stock/StockPage";
import MyPage from "../../pages/setting/MyPage";
import TranslationPage from "../../pages/translation/TranslationPage";
import TranslationCsvPage from "../../pages/translation/TranslationCsvPage";
// import BoxesViewPage from "../../pages/boxes/BoxesViewPage";
// import BoxesDetailsPage from "../../pages/boxes/BoxesDetailsPage";
// import BoxesAddPage from "../../pages/boxes/BoxesAddPage";
// import BoxesEditPage from "../../pages/boxes/BoxesEditPage";

const AppRouter = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Switch>
      {/*<Route exact path="/boxes" component={BoxesViewPage} />*/}
      {/*<Route exact path="/boxes/add" component={BoxesAddPage} />*/}
      {/*<Route exact path="/boxes/edit" component={BoxesEditPage} />*/}
      {/*<Route exact path="/boxes/delete" component={BoxesEditPage} />*/}
      <Route exact path="/dashboard" component={HomePage} />
      <Route exact path="/boxes/" component={BoxPage} />
      <Route exact path="/boxes/add" component={BoxAddPage} />
      <Route exact path="/boxes/edit/:id" component={BoxEditPage} />
      <Route exact path="/boxes/customer/:customerId" component={BoxPage} />
      <Route exact path="/boxes/:id" component={BoxDetailPage} />
      <Route exact path="/statistics/" component={ChartPage} />
      <Route exact path="/users/" component={UserPage} />
      <Route exact path="/users/add/:id" component={UserAddPage} />
      <Route exact path="/users/edit/:id" component={UserEditPage} />
      <Route exact path="/companies" component={CompanyPage} />
      <Route exact path="/companies/add" component={CompanyAddPage} />
      <Route exact path="/companies/edit/:id" component={CompanyEditPage} />
      <Route exact path="/companies/:id" component={CompanyDetailPage} />
      <Route exact path="/company_groups" component={CompanyGroupPage} />
      <Route exact path="/company_groups/add" component={CompanyGroupAddPage} />
      <Route exact path="/company_groups/edit/:id" component={CompanyGroupEditPage} />
      <Route exact path="/company_groups/:id" component={CompanyGroupDetailPage} />
      <Route exact path="/topup" component={TopupPage} />
      <Route exact path="/logs" component={LogPage} />
      <Route exact path="/logs/battery/:id" component={BatteryLogPage} />
      <Route exact path="/histories" component={HistoryPage} />
      <Route exact path="/stocks" component={StockPage} />
      <Route
        exact
        path="/settings"
        render={(_) => <MyPage handleUpdateUserLang={props.handleUpdateUserLang} />}
      />
      <Route exact path="/translation" component={TranslationPage} />
      <Route exact path="/translation/csv" component={TranslationCsvPage} />
      <Route exact path="/" render={() => {
        if (!user?.is_admin && user?.company_id.is_customer) {
          return <Redirect to={"/histories"} />
        } else {
          return <Redirect to={"/dashboard"} />
        }
      }} />
    </Switch>
  );
};

export default AppRouter;
