import React, { useEffect, useState } from 'react';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import ItemDetail from '../..//Common/ItemDetail';
import styled from "styled-components";
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment-timezone';
import Encoding from 'encoding-japanese';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Modal,
  TextField,
  CircularProgress,
  InputBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .btn {
    margin-right: 20px;
    text-decoration: none
  }

  .csvBtn {
    width: 100%;
    text-align: right;
  }
`;

const useStyles = makeStyles({
  table: {
    width: '100%',
    "@media (max-height:800px)":{
      maxHeight: "65vh",
    },
    "@media (max-height:600px)":{
      maxHeight: "58vh",
    },
    "@media (max-height:400px)":{
      maxHeight: "40vh",
    },
    maxHeight: "70vh",
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    borderBottom: "solid 1px #00559D"
  },
  forcusTab: {
    backgroundColor: '#00559D !important'
  },
  tabHeight: {
    minHeight: '40px',
    marginTop: '8px'
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00559D",
    marginLeft: "20px"
  },
  dialogSubTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#838383",
    marginLeft: "20px",
    marginBottom: 5
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  modalContainer: {
    borderWidth: 0,
    height: 250,
    width: 600,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
  },
  modalTextField: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25,
  },
  textField: {
    width: 200,
    marginLeft: 30,
    marginRight: 30
  },
  pageTextField: {
    marginLeft: 30,
    paddingLeft: 10,
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderRadius: '5px 0 0 5px',
    height: 40,
    width: 70,
    verticalAlign: 'top'
  },
  remarkTextField: {
    width: 520,
    backgroundColor: '#EAEAEA',
    border: 'none',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#858585',
      },
      '&:hover fieldset': {
        borderColor: '#858585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#858585',
      },
    },
  },
  pager: {
    marginTop: 10,
    display: 'flex',
  },
  uploadBtn: {
    backgroundColor: "#00559D",
    width: "100px",
    marginLeft: "20px"
  },
  dialogCenterCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  dialogCenterRowCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    flexDirection: 'column',
  },
  editContainer: {
    display: 'flex',
    alignItems: 'left',
    gap: '5px',
    minWidth: '200px',
    flexDirection: 'row',
    justifyContent: 'center',
    wordBreak: 'break-all',
  },
  memoText: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
  },
  memoButton: {
    display: 'flex',
    alignItems: 'center',
  },
  memoEdit: {
    borderBottom: "solid 1px #7B7E80",
  },
  listName: {
    height: "25px",
    width: "87%",
    fontWeight: "bold",
  },
});

const HistoryPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [boxes, setBoxes] = useState(null);
  const [options, setOptions] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenMemoDialog, setIsOpenMemoDialog] = useState(false);
  const [boxId, setBoxId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [targetPurchase, setTargetPurchase] = useState({});
  const [targetItem, setTargetItem] = useState({});
  const [logs, setLogs] = useState(null);
  const [logType, setLogType] = useState('purchase');
  const [pageNum, setPageNum] = useState(0);
  const [current, setCurrent] = useState(1);
  const [movePage, setMovePage] = useState(1);
  const [getParams] = useState(qs.parse(props.location.search, { ignoreQueryPrefix: true }));
  const [modal, setModal] = useState(false);
  const [isEditButtonDisable, setIsEditButtonDisable] = useState(false);
  const [from, setFrom] = useState(moment().format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [timezone, setTimezone] = useState("Selected TimeZone");
  const [note, setNote] = useState("");

  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      const companiesRes = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      setOptions(companiesRes.data);


      if (getParams.company_id) {
        setCompanyId(getParams.company_id);
        setCustomerId(getParams.company_id);

        const boxesRes = await axios.get(`boxes/customer/${ getParams.company_id }`);
        setBoxes(boxesRes.data);
        setBoxId(getParams.box_id);
      }

      if (user?.company_id?.is_agent && (user?.company_id?.is_customer && !user?.is_admin) ) {
        // 最初に登録した企業&boxを抽出
        const companies = _.orderBy(companiesRes.data, "createdAt");
        let boxId = null;
        if (companies.length > 0) {
          const tmpBoxes = await axios.get(`/boxes/customer/${companies[0]._id}`);
          setCustomerId(companies[0]._id);

          if (tmpBoxes.data.length > 0) {
            setBoxes(tmpBoxes.data);
            const box = _.orderBy(tmpBoxes.data, "createdAt")[0];
            boxId = box._id;
            setTimezone(box.timezone_id?.value);
            setBoxId(boxId);
          }
        }
        if (boxId) {
          handleGetPurchases(1, boxId);
        }
      } else if (user?.company_id?.is_customer && user?.is_admin) {
        const company = _.find(companiesRes.data, (company) => {
          return String(company._id) === String(user?.company_id?._id);
        });
        let boxId = null;
        const tmpBoxes = await axios.get(`/boxes/customer/${company._id}`);
        setCustomerId(company._id);

        if (tmpBoxes.data.length > 0) {
          setBoxes(tmpBoxes.data);
          const box = _.orderBy(tmpBoxes.data, "createdAt")[0];
          boxId = box._id;
          setTimezone(box.timezone_id?.value);
          setBoxId(boxId);
        }
        if (boxId) {
          handleGetPurchases(1, boxId);
        }
      } else {
        setBoxId('');
        setCustomerId('');
        setBoxes([]);

        const response = await axios.get(`purchases`, {
          params: {
            withCompanyGroup: true
          }
        });
        if (response.data !== null) {
          setLogs(response.data.logs);
          setPageNum(response.data.pageNum);
        }
      }
      setIsVisible(true)
    })();
  }, []);

  const handleOpenDialog = (item) => {
    setTargetItem(item);
    setIsOpenDialog(true);
  };

  const handleOpenMemoDialog = async (row) => {
    setNote(row.item.note);
    setTargetPurchase(row);
    setIsOpenMemoDialog(true);
  };

  const handleChangeCompany = async (event) => {
    const companyId = event.target.value;
    setBoxes(null);
    setCompanyId(companyId);
    if (companyId) {
      const res = await axios.get(`boxes/customer/${ companyId }`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.data !== null) {
        setBoxes(res.data);
      }
      setLogs(null);
      if (logType === 'purchase') {
        const purchase = await axios.get(`purchases`, {
          params: {
            page: 1,
            company_id: companyId,
            withCompanyGroup: true
          }
        });
        if (purchase.data !== null) {
          setLogs(purchase.data.logs);
          setPageNum(purchase.data.pageNum);
          setLogType('purchase');
          setIsVisible(true);
        }
      }
    } else {
      const response = await axios.get(`purchases`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (response.data !== null) {
        setLogs(response.data.logs);
        setPageNum(response.data.pageNum);
      }
      setBoxes([])
      setBoxId('');
    }
    setTimezone("Selected TimeZone");
  };

  const handleChangeBox = async (event) => {
    setTimezone("Japan (Selected TimeZone)");
    const editBoxId = event.target.value;
    setBoxId(editBoxId);
    handleGetPurchases(1, editBoxId);
    const response = await axios.get(`boxes/${ editBoxId }`);
    if (editBoxId !== "") {
      setTimezone(response.data.timezone_id?.value);
    }
  };

  const handleGetPurchases = async (page = 1, editBoxId = '') => {
    setLogType('purchase');
    setLogs(null);
    setIsVisible(true);
    setCurrent(page);
    if (editBoxId) {
      const response = await axios.get(`purchases/log/box/${ editBoxId }`, {
        params: {
          page
        }
      });
      if (response.data !== null) {
        setLogs(response.data.logs);
        setPageNum(response.data.pageNum);
      }
    } else {
      const response = await axios.get(`purchases`, {
        params: {
          page,
          company_id: companyId,
          withCompanyGroup: true
        }
      });
      if (response.data !== null) {
        setLogs(response.data.logs);
        setPageNum(response.data.pageNum);
      }
    }
  }

  const handlePageChanged = (_, page) => {
    handleGetPurchases(page, boxId);
    setMovePage(page);
  }

  const handlePageListChanged = () => {
    handleGetPurchases(movePage, boxId);
  }

  const handleModalOpen = () => {
    setModal(true)
  }

  const handleModalClose = () => {
    setModal(false)
  }

  const isModalDisabled = () => {
    return (!from || !to) || (moment(from) > moment(to))
  }

  const isDisabled = () => {
    return (!boxId);
  }

  const checkText = (event) => {
    if (!isNaN(event.target.value)) {
      setMovePage(Number(event.target.value));
    }
  }

  const isAvailablePage = () => {
    return Number(movePage) > Number(pageNum) || Number(movePage) === 0
  }

  const handleUpdateNote = async () => {
    setIsEditButtonDisable(true)
    await axios.put(`purchases/${targetPurchase._id}`, {
      note: note
    });
    if (boxId) {
      const response = await axios.get(`purchases/log/box/${ boxId }`, {
        params: {
          pageNum
        }
      });
      if (response.data !== null) {
        setLogs(response.data.logs);
        setPageNum(response.data.pageNum);
      }
    } else {
      const response = await axios.get(`purchases`, {
        params: {
          pageNum,
          company_id: companyId,
          withCompanyGroup: true
        }
      });
      if (response.data !== null) {
        setLogs(response.data.logs);
        setPageNum(response.data.pageNum);
      }
    }
    setIsVisible(true);
    alert(t('更新しました'));
    setIsOpenMemoDialog(false);
    setIsEditButtonDisable(false);
  }

  const convertMemo = (n) => {
    const texts = n.split("\n").map((item, index) => {
      return (
        <React.Fragment key={index}>{item}<br /></React.Fragment>
      );
    });
    return <>{texts}</>;
  }

  const handleCsvDownload = async () => {
    moment.locale('ja');
    let fromDate = moment(from);
    let toDate = moment(to);
    if (toDate.diff(fromDate) > (24 * 60 * 60 * 30 * 1000 )) {
      alert(t('ファイルサイズを制限しています。期間は30日以内を指定してください'));
      return false;
    }
    toDate = toDate.format('YYYYMMDD');
    fromDate = fromDate.format('YYYYMMDD');
    const box = _.find(boxes, (b) => {
      return boxId === b._id
    })

    const fileName = `${ box.name }_${ fromDate }_${ toDate }.csv`;
    const data = [[
      'day',
      'buyer_name',
      'company',
      'box',
      'category',
      'edp_no',
      'abbreviation',
      'designation',
      'number_of_flutes',
      'overall_thread_length_shank_dia',
      'grade',
      'surface_treatment',
      'weight',
      'materials',
      'drill_hole_dia',
      'standard_price',
      'quantity',
      'footnotes',
      'remark'
    ]];

    const response = await axios.get(`purchases/log/box/download/${ boxId }`, {
      params: {
        from: from,
        to: to
      }
    });

    response.data.logs.map(row => {
      return data.push([
        moment(row.createdAt).tz(row.box_id?.timezone_id ? row.box_id.timezone_id.value : "Japan").format('YYYY/MM/DD HH:mm:ss'),
        row.user_id.username,
        row.user_id.company_id.name,
        row.box_id?.name,
        row.item.category ,
        row.item.edp_no,
        row.item.abbreviation,
        row.item.designation,
        row.item.number_of_flutes,
        row.item.overall_thread_length_shank_dia,
        row.item.grade,
        row.item.surface_treatment,
        row.item.weight,
        row.item.materials,
        row.item.drill_hole_dia,
        row.item.standard_price,
        row.item?.purchase_quantity ?? 1 ,
        row.item.remarks ? row.item.remarks : null,
        row.item.note ? row.item.note : null,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    let csvBlob;
    if (user.lang === 'zh') {
      const BOM = "\uFEFF";
      csvBlob = new Blob([BOM + rows], { type: 'text/csv;charset=utf-8' });
    } else {
      const unicodeList = [];
      for (let i = 0; i < rows.length; i += 1) {
        unicodeList.push(rows.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
      const uInt8List = new Uint8Array(shiftJisCodeList);
      csvBlob = new Blob([uInt8List], { type: 'text/csv' });
    }
    const jsonURL = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  }

  return <>
    <SubNavbar style={{justifyContent: 'flex-end'}}>
      { customerId != null ?
        <OsgSelect onChange={handleChangeCompany} defaultValue={customerId} style={{width: '245px', marginRight: "20px"}}> 
          <option value="">{t("すべての企業")}</option>
          {options.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
        : (
          <>
            <div style={{width: '245px', justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
          </>
        )}
      { boxId !== null && boxes !== null ?
        <OsgSelect onChange={handleChangeBox} defaultValue={ boxId } style={{width: "245px"}}>
          <option value="">{t("すべてのBOX")}</option>
          {boxes.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
        : (
          <>
            <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
          </>
        )}
    </SubNavbar>
    <SubNavbar>
      { isVisible ?
        <div className="csvBtn">
          <OsgButton style={{width: '245px',}} onClick={() => handleModalOpen()} disabled={isDisabled()}>
            {t("購入履歴をCSVダウンロード")}
          </OsgButton>
        </div>
        : null }
    </SubNavbar>

    <Modal open={modal} className={classes.modal} onClose={() => handleModalClose()}>
      <div className={classes.modalContainer}>
        <div style={{textAlign: "right"}}>
          <Button onClick={() => handleModalClose()}>
            {t("閉じる")}
          </Button>
        </div>
        <div className={classes.modalTextField}>
          <TextField
            id="date"
            label="from"
            type="date"
            defaultValue={moment().format('YYYY-MM-DD')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setFrom(e.target.value)}
          />
          <TextField
            id="date"
            label="to"
            type="date"
            defaultValue={moment().format('YYYY-MM-DD')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        <div style={{textAlign: "center", marginTop: 50}}>
          <OsgButton onClick={handleCsvDownload} disabled={isModalDisabled()}>
            {t("購入履歴をダウンロード")}
          </OsgButton>
        </div>
      </div>
    </Modal>
    { user?.company_id.is_osg && <>
      {timezone && <div style={{paddingBottom: "5px"}}>TimeZone: Japan ({timezone})</div>}
    </>}
    {(user?.company_id.is_agent || user?.company_id.is_customer) && <>
      {timezone === "Selected TimeZone" && <div style={{paddingBottom: "5px"}}>TimeZone: Japan ({timezone})</div>}
      {timezone !== "Selected TimeZone" && <div style={{paddingBottom: "5px"}}>TimeZone: {timezone}</div>}
    </>}
    <TableContainer className={classes.table} >
      <Table aria-label="company table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5", width:100}}>{t("購入日")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t("購入者名")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="10%">{t("企業名")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t("BOX名")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t("分類")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t("ツールNo")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t("製品記号")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t("呼び")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="10%">{t("全長、ねじ長、シャンク径")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t("標準価格")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t("個数")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t("製品備考")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t("製品情報")}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="20%">{t("メモ")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(logs !== null && logs) ? (
            <>
              {_.map(logs, (row) => (
                <TableRow key={row._id}>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">
                    { (user?.company_id.is_osg || boxId === "") && <>
                      { moment(row.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD HH:mm:ss') }
                      <br />({ moment(row.createdAt).tz(row.box_id.timezone_id ? row.box_id.timezone_id.value : "Japan").format('HH:mm:ss') })
                    </> }
                    { (user?.company_id.is_agent || user?.company_id.is_customer) && boxId !== "" && <>
                      {timezone == "Selected TimeZone" && <>
                        { moment(row.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD HH:mm:ss') }
                        <br />({ moment(row.createdAt).tz(row.box_id.timezone_id ? row.box_id.timezone_id.value : "Japan").format('HH:mm:ss') })
                      </> }
                      {timezone !== "Selected TimeZone" && <>
                        { moment(row.createdAt).tz(row.box_id.timezone_id ? row.box_id.timezone_id.value : "Japan").format('YYYY/MM/DD HH:mm:ss') }
                      </> }
                  </> }
                  </TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ row.user_id.username }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.user_id.company_id.name }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ row.box_id?.name }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.item?.category }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.item?.edp_no }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.item?.abbreviation }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.item?.designation }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F5F5F5"}} align="center">{ row.item?.overall_thread_length_shank_dia }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.item?.standard_price }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F5F5F5"}} align="center">{ row.item?.purchase_quantity ?? 1 }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.item?.remarks }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F5F5F5"}} align="center">
                    <Button onClick={ () => handleOpenDialog(row.item) }>
                      <div style={{borderBottom: "solid 1px #7B7E80"}}>{t("詳細")}</div>
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF", width: '20%'}} align="left">
                    <div className={classes.editContainer}>
                      <div className={classes.memoText}>
                        {row?.item?.note ? convertMemo(row?.item?.note) : null}
                      </div>
                      {user.company_id.is_osg || user.company_id.is_agent || (user.company_id.is_customer && user.is_admin && user.company_id._id === row.user_id.company_id._id) ? (
                        <div className={classes.memoButton}>
                          <Button onClick={() => handleOpenMemoDialog(row)}>
                            <div className={classes.memoEdit}>
                              {t("編集")}
                            </div>
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {(logs !== null && logs && pageNum > 1) ? (
        <div className={classes.pager}>
          <Pagination
            count={pageNum}
            page={current}
            showFirstButton
            showLastButton
            onChange={handlePageChanged}
          />
          <div>
            <InputBase className={classes.pageTextField} value={movePage} onChange={(e) => checkText(e)} /> / {pageNum}
            <Button
              color="primary"
              className={classes.uploadBtn}
              variant='contained'
              disabled={isAvailablePage()}
              onClick={() => handlePageListChanged()}
            >
              {t('移動する')}
            </Button>
          </div>
        </div>
      ) : null}

    <Dialog open={ isOpenDialog } fullWidth maxWidth="md">
      <Button onClick={ () => setIsOpenDialog(false) }
        style={{
          margin: "0 0 0 auto",
          backgroundColor: "transparent",
          border:'none',
          position:'absolute',
          top:'15px',
          right:'24px',
        }}
      >{t('閉じる')}</Button>
      <ItemDetail item={ targetItem } />
      {boxes ? (
        <>
          {boxes.map((v) => {
            if (boxId === v._id) {
              return (
                <div key={ v._id } className={classes.dialogTitle}>
                  {v.name}
                </div>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : null}
      {options.map((v) => {
        if (companyId === v._id) {
          return (
            <div key={ v._id } className={classes.dialogSubTitle}>
              <span>
                {v.name}
              </span>
            </div>
          );
        } else {
          return null;
        }
      })}
    </Dialog>

    <Dialog open={ isOpenMemoDialog } fullWidth maxWidth="sm">
      <div className={classes.openMemoDialog}>
        <div className={classes.dialogCenterRowCol} style={{marginTop: 20, marginBottom: 20}}>
          <div className={classes.listName}>{t('メモ(任意)')}</div>
          <TextField
            id="outlined-multiline-static"
            placeholder={t("メッセージを共有したい場合は、こちらに記載してください")}
            multiline
            minRows={6}
            variant="outlined"
            defaultValue={targetPurchase?.item?.note}
            className={classes.remarkTextField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className={classes.dialogCenterCol} style={{marginTop: 20, marginBottom: 20}}>
          <Button
            color="primary"
            variant="outlined"
            style={{width: "200px", height: "40px", marginRight: 20}}
            onClick={ () => setIsOpenMemoDialog(false) }
          >{t('戻る')}</Button>
          <OsgButton style={{width: '200px',}} disabled={isEditButtonDisable} onClick={() => {
            setIsVisible(true);
            handleUpdateNote();
          }}>
            {t("更新")}
          </OsgButton>
        </div>
      </div>
    </Dialog>
  </>;
};

export default HistoryPage;
