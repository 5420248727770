import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog
} from '@material-ui/core';
import styled from "styled-components";
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';

const Details = styled.div`
  max-width: 1000px;
  text-align: right;
`;

const useStyles = makeStyles({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#00559D"
  },
  listText:  {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  dialogContents: {
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "0 5%",
    borderBottom: "solid 1px #000"
  },
  deleteConfirmButton: {
    color: "#C90000",
    marginLeft: "10px",
  },
  deleteConfirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "50px",
  },
  deleteConfirmDialogTitle: {
    textAlign: "center",
    color: "#C90000",
    fontSize: "20px",
    fontWeight: "bold"
  },
  deleteConfirmUserName: {
    textAlign: "center",
    fontWeight: "bold"
  },
  deleteConfirmDescription: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  deleteButton: {
    background: "#C90000",
    fontWeight: "bold",
    width: "200px",
    height: "42px",
    marginRight: "20px",
    '&:hover': {
      background: '#C90000'
    }
  },
  backButton: {
    width: "200px",
    height: "42px",
    marginRight: "20px",
  },
  deleteConfirmTitle: {
    fontWeight: "bold",
    marginTop: "2px",
    marginBottom: "2px",
  },
  deleteConfirmImportantList: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  deleteConfirmImportantListItem: {
    color: "#C90000",
    marginTop: "2px",
    marginBottom: "2px",
  },
});

const CompanyDetailPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [company, setCompany] = useState({});
  const [user, setUser] = useState(null);
  const [deletable, setDeletable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    const id = props.match.params.id;
    setId(id);
    const tmpUser = JSON.parse(localStorage.getItem("user"));
    setUser(tmpUser);
    (async () => {
      const res = await axios.get(`companies/${id}`);
      setCompany(res.data);
    })();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user)
    if (user.company_id.is_osg && user.is_admin) {
      setDeletable(true);
    }
  }, []);

  const handleDeleteClick = async e => {
    const res = await axios.delete(`/companies/${id}`);
    if (res.data.success) {
      alert(t('削除しました'));
      props.history.push('/companies');
    }
  };

  return (
    <>
      <Details>
        {(user && !(user.company_id?.is_customer && !user.is_admin)) ? (
          <Button color="primary" href={`/companies/edit/${company._id}`}> {t("編集")} </Button>
        ) : null}
        <List>
          <ListItem dense>
            <ListItemText className={classes.listTitle} primary={t("企業名")} />
            <ListItemText className={classes.listText} primary={company.name} />
          </ListItem>
          <ListItem dense>
            <ListItemText className={classes.listTitle} primary={t("企業コード(任意)")} />
            <ListItemText className={classes.listText} primary={company.code} />
          </ListItem>
          <ListItem dense className={classes.listItem} style={{marginTop: "10px"}}>
            <ListItemText className={classes.listTitle} primary={t("住所(任意)")} />
            <ListItemText className={classes.listText} primary={company.address} />
          </ListItem>
          <ListItem dense className={classes.listItem}>
            <ListItemText className={classes.listTitle} primary={t("企業連絡先(任意)")}/>
            <ListItemText className={classes.listText} primary={company.tel} />
          </ListItem>
          <ListItem dense className={classes.listItem}>
            <ListItemText className={classes.listTitle} primary={t("担当者名(任意)")} />
            <ListItemText className={classes.listText} primary={company.in_charge} />
          </ListItem>
          { company.is_customer ?
            <ListItem dense className={classes.listItem}>
              <ListItemText className={classes.listTitle} primary={t("契約BOX数")} />
              <ListItemText className={classes.listText} primary={company.boxes.filter(v => v.status !== 3 && !v.deletedAt).length} />
            </ListItem>
          :
            <Button color="primary" href={`/boxes`}> {t("本体管理へ")} </Button>
          }
        </List>
      </Details>
      { deletable ?
        <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("この企業を削除")} </Button>
      : null }
      <Dialog
        open={showDeleteDialog} fullWidth maxWidth="sm">
        <div className={classes.deleteConfirmDialogContainer}>
          <div className={classes.deleteConfirmDialogTitle}>
            {t("企業削除")}
          </div>
          <div className={classes.deleteConfirmUserName}>
            {t("対象企業")}:&nbsp;&nbsp;{company.name}
          </div>

          <div className={classes.deleteConfirmDescription}>
            {t("企業削除をすると、以下の要素が削除され、全ユーザーを対象に利用・閲覧ができなくなります。")}
          </div>

          { company.is_customer ? (
            <div className={classes.deleteConfirmImportantList}>
              <p className={classes.deleteConfirmImportantListItem}>{t("配下全てのカスタマー企業")}</p>
              <p className={classes.deleteConfirmImportantListItem}>{t("配下のカスタマー企業に紐づく全てのBOX")}</p>
              <p className={classes.deleteConfirmImportantListItem}>{t("配下のカスタマー企業に所属する全てのユーザー")}</p>
            </div>
          ) : company.is_agent ? (
            <>
              <div className={classes.deleteConfirmImportantList}>
                <p className={classes.deleteConfirmTitle}>{t("自社")}</p>
                <p className={classes.deleteConfirmImportantListItem}>{t("この企業のアカウント")}</p>
                <p className={classes.deleteConfirmImportantListItem}>{t("所属する全ユーザー")}</p>
              </div>
              <div className={classes.deleteConfirmImportantList}>
                <p className={classes.deleteConfirmTitle}>{t("配下のカスタマー")}</p>
                <p className={classes.deleteConfirmImportantListItem}>{t("配下全てのカスタマー企業")}</p>
                <p className={classes.deleteConfirmImportantListItem}>{t("配下のカスタマー企業に紐づく全てのBOX")}</p>
                <p className={classes.deleteConfirmImportantListItem}>{t("配下のカスタマー企業に所属する全てのユーザー")}</p>
              </div>
            </>
          ) : null}

          <div className={classes.deleteConfirmDescription}>
            {t("本当に削除しますか？")}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="outlined"
              onClick={ () => setShowDeleteDialog(false)}
              className={classes.backButton}
            >
              {t("戻る")}
            </Button>
            <Button
              className={classes.deleteButton}
              color="primary"
              variant="contained"
              onClick={handleDeleteClick}
            >
              {t("削除する")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CompanyDetailPage;
