import Bread from '@material-ui/core/Breadcrumbs';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Trans, useTranslation } from 'react-i18next';

const Topbar = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 10px;
  margin: 0px -20px;
`;

const PageLinkText = styled(NavLink)`
  text-decoration: none;
  color: #000;
  font-weight: bold;
  transition: color .25s;

  &:hover {
    color: gray
  }
`;

const BreadCrumbs = () => {
  const [t] = useTranslation();
  const routes = [
    { path: '/', breadcrumb: 'Top' },
    { path: '/dashboard', breadcrumb: 'Top' },
    { path: '/histories', breadcrumb: t('購入履歴') },
    { path: '/stocks', breadcrumb: t('在庫状況') },
    { path: '/topup', breadcrumb: t('製品の補充/撤去') },
    { path: '/companies/:id', breadcrumb: null },
    { path: '/companies', breadcrumb: t('企業一覧') },
    { path: '/boxes', breadcrumb: t('本体管理') },
    { path: '/boxes/add', breadcrumb: t('設置BOX新規登録') },
    { path: '/boxes/:id', breadcrumb: t('BOX詳細') },
    { path: '/statistics', breadcrumb: t('分析') },
    { path: '/logs', breadcrumb: t('各種ログ') },
    { path: '/users', breadcrumb: t('ユーザー管理') },
    { path: '/users/add', breadcrumb: t('ユーザーの新規登録') },
    { path: '/users/add/:id', breadcrumb: null },
    { path: '/users/edit/:id', breadcrumb: t('ユーザー情報') },
    { path: '/users/edit', breadcrumb: null },
    { path: '/settings', breadcrumb: t('マイページ') },
    { path: '/company_groups', breadcrumb: t('企業グループ') },
    { path: '/company_groups/add', breadcrumb: t('企業グループ新規作成') },
    { path: '/company_groups/edit', breadcrumb: t('企業グループ更新') },
    { path: '/company_groups/:id', breadcrumb: t('企業グループ詳細') },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Topbar>
      <Bread separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb">
        { breadcrumbs.map(({ match, breadcrumb}, index) => (
        <span key={match.url}>
          <PageLinkText to={match.url} style={index === breadcrumbs.length - 1 ? {color: "#00559D"} : {}}>
            {breadcrumb}
          </PageLinkText>
        </span>
        ))}
      </Bread>
      {/*
      <div>
        {user.username}
      </div>
      */}
    </Topbar>
  );
};

export default BreadCrumbs;
