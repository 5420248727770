import React, { useEffect, useState } from "react";
import { log } from '../../../utils/Logger.js';
import axios from "axios";
import Loader from "../../Common/Loader/Loader";
import { TextField, makeStyles } from "@material-ui/core";
import { Trans, useTranslation } from 'react-i18next';
import logo from "../../assets/logo-topbar.svg"
import moment from "moment-timezone";

const useStyles = makeStyles({
  article: {
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
    color: "#00559D",
  },
  updateInfo: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "#D4E7FF",
    "@media (max-width:600px)":{"width":"80%"},
  },
  updateColumn: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    paddingTop: "10px",
    borderTop: "solid 1px #00559D",
  },
  updateItemInfo: {
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    "@media (max-width:600px)":{"width":"80%"},
  },
  edpNo: {
    paddingRight: "15px",
  },
  loading: {
    alignItems: "center",
  },
  logo: {
    width: '600px',
    marginTop: "20px",
    "@media (max-width:600px)":{"width":"100%"},
  }
});

const TopupUpdatePage = (props) => {
  const [t] = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reserveData, setReserveData] = useState({});
  const [error, setError] = useState(' ');
  const classes = useStyles();
  const reserveItemCsvId = props.match.params.id;

  useEffect(() => {
    (async () => {
      let options = {};
      const itemRes = await axios.post('/items/reserve/refill',{
        reserve_item_csv_id: reserveItemCsvId
      });
      if (itemRes?.data.reserveItemCsv) {
        const boxId = itemRes.data.reserveItemCsv.box_id._id;
        options.items = itemRes.data.reserveItemCsv.items;
        options.user = itemRes.data.reserveItemCsv.reserve_user_id
        await log(`在庫数量 CSVアップロード/予約補充/予約反映`, 'topup', boxId, options)
        setReserveData(itemRes.data.reserveItemCsv)
        setIsSuccess(true);
      } else if (itemRes.data.error && itemRes.data.box_id) {
        setError(itemRes.data.error);
      } else if (itemRes.data.error) {
        setError(itemRes.data.error);
      }
      setIsLoading(false);
    })();
  }, [reserveItemCsvId]);

  return (
    <div className={classes.article}>
      {(isLoading) ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <>
          {(isSuccess) ? (
            <>
              <span className={classes.label}>{t("予約中のデータが反映されました。")}</span>
              <div className={classes.updateInfo}>
                <span className={classes.label}>{t("予約補充登録日")}</span>
                <span className={classes.data}>{moment(reserveData.createdAt).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')} (TimeZone: {reserveData.box_id.timezone_id?.value})</span>
                <span className={classes.label}>{t("企業名")}</span>
                <span className={classes.data}>{reserveData.company_id.name}</span>
                <span className={classes.label}>{t("BOX名")}</span>
                <span className={classes.data}>{reserveData.box_id.name}</span>
                <span className={classes.label}>{t("登録者名")}</span>
                <span className={classes.data}>{reserveData.reserve_user_id.username}</span>
                <span className={classes.label}>{t("作業者名")}</span>
                <span className={classes.data}>{reserveData.update_user_id.username}</span>
                <div className={classes.updateColumn}>
                  <span className={classes.label}>{t("データ反映")}</span>
                  <span className={classes.data}>{moment(reserveData.updatedAt).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')} (TimeZone: {reserveData.box_id.timezone_id?.value})</span>
                </div>
              </div>
              <div className={classes.updateItemInfo}>
                <span className={classes.label}>{t("以下の内容で補充予約が完了しました。")}</span>
                {reserveData.items.map((i, index) => <div key={index}>
                  <span className={classes.item}>{t("ツールNo")}.</span>
                  <span className={classes.edpNo}>{i.edp_no}</span>
                  <span className={classes.quantity}>+{i.refill_quantity}</span>
                </div>)}
              </div>
              <div>
                <img src={logo} className={classes.logo} alt={logo}/>
              </div>
            </>
          ) : (
            <span className={classes.label}>{t(error)}</span>
          )}
        </>
      )}
    </div>
  );
};
export default TopupUpdatePage;
