import React, {useEffect, useState} from "react";
import { TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import { I18n, JS } from "@aws-amplify/core";
import axios from "axios";
import useFormValidation from "../../CustomHooks/useFormValidation";
import validateForgotPassword from "./validateForgotPassword";
//import { makeStyles } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";
// import { addProfile } from "../../../store/Auth/actions";
import Loader from "../../Common/Loader/Loader";
import { OsgButton } from "../../Common/UIcomponents";
import LoginTitle from "./Title";
import { Trans, useTranslation } from 'react-i18next';

const Div = styled.div`
  padding: 10px 50px;
`;

const SubLoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #00559D;
  font-size: 1.5em;
  font-weight: bold;
`;

const LoginEnTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #00559D;
  font-size: 1.2em;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextFieldWithLabel = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin-top: 16px;
  margin-bottom: 5px;
  .textField {
    width: 100%;
  }
  .formLabel {
    display: flex;
    align-items: center;
    width: 25%;
    font-weight: bold;
    text-align: right;

    span {
      color: #8B8C8E;
      font-weight: normal;
      font-size: 0.8em;
    }
  }
`;

const SignInLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 45px;

  .back_to_Link {
    color: #8B8C8E;

    &:hover {
      color: #707070;
    }
  }
`;

const INITIAL_STATE = {
  email: "",
  password: "",
};

const ForgotPassword = (props) => {
  const [t] = useTranslation();
  // const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState("");

  const initiateResetPassword = async (values) => {
    const { email } = values;
    setIsLoading(true);
    try {
      const result = await axios.put('/users/reset_password',{
        email: email
      })

      if(result.data.success){
        setAuthError('');
        alert(t('パスワード再発行メールを送信しました'));
        props.history.push("/signin");
      } else {
        console.log(result.data);
        setAuthError(result.data.message);
      }
    } catch (e){
      setAuthError(e);
    } finally {
      setTimeout(()=> {
        setIsLoading(false);
      },1000)
    }



  //   dispatch(addProfile({ email: username, password }));
  //   setIsLoading(true);
  //   try {
  //     if (!Auth || typeof Auth.signIn !== "function") {
  //       throw new Error(
  //         "No Auth module found, please ensure @aws-amplify/auth is imported"
  //       );
  //     }
  //     const user = await Auth.signIn({ username, password });
  //     const authToken = user.signInUserSession.accessToken.jwtToken;
  //     localStorage.setItem("auth-token", authToken);
  //     props.history.push("/");
  //   } catch (err) {
  //     if (err.code === "UserNotConfirmedException") {
  //       props.history.push("/confirmSignUp");
  //     } else if (err.code === "PasswordResetRequiredException") {
  //       props.history.push("/reset-password");
  //     } else if (err.code === "InvalidParameterException") {
  //       setAuthError(
  //         username +
  //           " には使用できない文字が含まれています。メールアドレスを入力してください。"
  //       );
  //     } else {
  //       setAuthError(err.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    // handleBlur,
  } = useFormValidation(INITIAL_STATE, validateForgotPassword, initiateResetPassword);

  return (
    <Div>
      <LoginTitle></LoginTitle>
      <SubLoginTitle>パスワードを忘れた方</SubLoginTitle>
      <LoginEnTitle>Forget Password</LoginEnTitle>
      <Form onSubmit={handleSubmit}>
        <TextFieldWithLabel>
          <label className="formLabel">
            <div>
              mail
            </div>
          </label>
          <TextField
            name="email"
            variant="outlined"
            className={`textField ${authError ? "error-box" : ""}`}
            value={values.email}
            onChange={handleChange}
            margin="dense"
          />
        </TextFieldWithLabel>
        <Typography>
          <span className="error-text">
            {authError ? authError : ""}
          </span>
        </Typography>

        <div style={{marginTop: '50px'}}>
          <OsgButton type="submit" style={{textTransform: 'none'}}>Send</OsgButton>
        </div>
      </Form>
      <SignInLinks>
        <Link to="/signin" className="back_to_Link">戻る / Back</Link>
      </SignInLinks>
      {isLoading ? (
        <div className="isLoading">
          <Loader />
        </div>
      ) : null}
    </Div>
  );
};
export default ForgotPassword;
