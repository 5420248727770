import React, { useEffect, useState } from 'react';
import UserList from '../../Common/UserList';
import axios from 'axios';
import _ from 'lodash';
import styled from "styled-components";
import { OsgSelect, OsgButton } from "../../Common/UIcomponents";
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment-timezone';
import Encoding from 'encoding-japanese';
import {
  makeStyles,
  Button,
  CircularProgress,
  InputBase
} from '@material-ui/core';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .buttonList {
    margin-left: auto;
    text-decoration: none;
  }
  .addUserBtn {
    display: flex;
    justifyContent: flex-end;
    padding-bottom: 10px;
  }
  .downloadUserBtn {
    display: flex;
    justifyContent: flex-end;
  }
`;

const useStyles = makeStyles({
  pager: {
    marginTop: 10,
    display: 'flex',
  },
  uploadBtn: {
    backgroundColor: "#00559D",
    width: "100px",
    marginLeft: "20px"
  },
  pageTextField: {
    marginLeft: 30,
    paddingLeft: 10,
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderRadius: '5px 0 0 5px',
    height: 40,
    width: 70,
    verticalAlign: 'top'
  },
});

const UserPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [disableAddUserButton, setDisableAddUserButton] = useState(true);
  const operator = JSON.parse(localStorage.getItem('user'));
  const [pageNum, setPageNum] = useState(0);
  const [current, setCurrent] = useState(1);
  const [movePage, setMovePage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    (async () => {
      const tmpUser = JSON.parse(localStorage.getItem("user"));
      setUser(tmpUser);
      const res = await axios.get(`companies`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.status === 200) {
        setCompanies(res.data);
      }
      const users = await axios.get(`users/`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (users.status === 200) {
        setUsers(users.data.users);
        setPageNum(users.data.pageNum);
      }
      if (operator.company_id.is_customer && operator.is_admin) {
        setCompanyId(operator.company_id._id);
        setDisableAddUserButton(false);
      }
    })();
  }, []);

  const handlePageChanged = async (_, page) => {
    setUsers(null);
    setCurrent(page);
    let res = [];
    if (companyId) {
      res = await axios.get(`users/company/${companyId}`, {
        params: {
          page: page,
        }
      });
    } else {
      res = await axios.get('users/', {
        params: {
          page: page,
        }
      });
    }
    if (res.status === 200) {
      setUsers(res.data.users);
      setPageNum(res.data.pageNum);
    }
  }

  const handlePageListChanged = () => {
    handlePageChanged(null, movePage);
  }

  const handleChangeCompany = async (event) => {
    const id = event.target.value;
    let res = []
    setUsers(null);
    setCompanyId(id);
    setCurrent(1);
    try {
      if (id) {
        res = await axios.get(`users/addable/${id}`);
        setDisableAddUserButton(!res.data.addable)
      } else {
        setDisableAddUserButton(true);
      }

      if (id) {
        res = await axios.get(`users/company/${id}`);
      } else {
        res = await axios.get('users/');
      }
      if (res.status === 200) {
        setUsers(res.data.users);
        setPageNum(res.data.pageNum);
      }
    } catch (e) {
      console.log(e);
    };
  };

  const checkText = (event) => {
    if (!isNaN(event.target.value)) {
      setMovePage(Number(event.target.value));
    }
  }

  const isAvailablePage = () => {
    return Number(movePage) > Number(pageNum) || Number(movePage) === 0
  }

  const linkToAddUser = () => {
    if (companyId === '') {
      alert(t('企業を選択してください'));
      return;
    }
    props.history.push(`/users/add/${companyId}`);
  };

  const handleCsvDownload = async () => {
    setIsDownloading(true);
    let response = null;

    const fileName = `user_list.csv`;
    const data = [[
      'username',
      'company_name',
      'type',
      'is_admin',
      'status',
      'email',
    ]];

    response = await axios.get(`/users/csv`, {
      params: {
        company_id: companyId,
      }
    });

    response.data.users.map(row => {
      return data.push([
        row.username,
        row?.company_id?.name,
        row?.company_id?.is_osg ? 'osg' : row?.company_id?.is_agent ? t('商社') : t('カスタマー'),
        row.is_admin ? t('管理者') : t('一般'),
        row.status === 1 ? t('仮登録'): row.status === 2 ? t('アクティブ') : t('停止'),
        row.email,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    let csvBlob;
    if (user.lang === 'zh') {
      const BOM = "\uFEFF";
      csvBlob = new Blob([BOM + rows], { type: 'text/csv;charset=utf-8' });
    } else {
      const unicodeList = [];
      for (let i = 0; i < rows.length; i += 1) {
        unicodeList.push(rows.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
      const uInt8List = new Uint8Array(shiftJisCodeList);
      csvBlob = new Blob([uInt8List], { type: 'text/csv' });
    }
    const jsonURL = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  }

  return (
    <>
      <SubNavbar>
        { operator.company_id.is_customer && typeof operator.group_company_ids === 'undefined' ? null :
        <OsgSelect onChange={handleChangeCompany} style={{width: "200px"}}>
          <option value=''>{t('すべてのユーザー')}</option>
          {(companies) && (_.map(companies, (v) => {
            return (
              <option value={v._id} key={v._id}>
                {v.name}
              </option>
            );
          }))}
        </OsgSelect>
        }
        <div className="buttonList">
          <div className="addUserBtn">
            <OsgButton onClick={linkToAddUser} disabled={ disableAddUserButton } style={{width: "250px"}}>
              <AddIcon />
              {t('ユーザー新規作成')}
            </OsgButton>
          </div>
          <div className="downloadUserBtn">
            {isDownloading ?
              <CircularProgress />
            : (
              <OsgButton onClick={handleCsvDownload} style={{width: "250px"}}>
                {t("CSVダウンロード")}
              </OsgButton>
            )}
          </div>
        </div>
      </SubNavbar>
      <div style={{fontSize: "20px", fontWeight: "bold", marginBottom: 10}}>{t('ユーザー一覧')}</div>
      <UserList users={users} />
      {(users !== null && users && pageNum > 1) ? (
        <div className={classes.pager}>
          <Pagination count={pageNum} page={current} showFirstButton showLastButton onChange={handlePageChanged}  />
          <div>
            <InputBase className={classes.pageTextField} value={movePage} onChange={(e) => checkText(e)} /> / {pageNum}
            <Button
              color="primary"
              className={classes.uploadBtn}
              variant='contained'
              disabled={isAvailablePage()}
              onClick={() => handlePageListChanged()}
            >
              {t('移動する')}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserPage;
