import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import axios from 'axios';
import styled from "styled-components";
import { OsgButton } from "../../Common/UIcomponents/index";
import { Trans, useTranslation } from 'react-i18next';
import DualListBox from 'react-dual-listbox';
import '../../../css/react-dual-listbox.css';

const Details = styled.div`
  max-width: 1000px;
  text-align: center;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const useStyles = makeStyles({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  customerListTitleSelected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "100%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#C90000"
  },
  customerListTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "100%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#00559D"
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#C90000"
  },
  listText:  {
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  annotations: {
    paddingTop: "20px",
  },
  dialogListTitle: {
    paddingBottom: "0"
  },
  dialogListText: {
    width: "60%"
  },
  dialogListItem: {
    paddingTop: "0",
    paddingBottom: "0"
  }
});

const CompanyEditPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [id, setId] = useState('');
  const [companyGroup, setCompanyGroup] = useState(null);
  const [name, setName] = useState('');
  const [companySelected, setCompanySelected] = useState([]);
  const [companySelectedOptions, setCompanySelectedOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [customerUserSelected, setCustomerUserSelected] = useState([]);
  const [customerUserSelectedOptions, setCustomerUserSelectedOptions] = useState([]);
  const [customerUserOptions, setCustomerUserOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const id = props.match.params.id;
    setId(id);
    (async () => {
      const companyGroupRes = await axios.get(`company_groups/edit/${id}`);
      setCompanyGroup(companyGroupRes.data);
      const res = await axios.get('/companies/customers');
      const customerOptions = res.data.map((customer) => {
        return { value: customer._id, label: customer.name }
      });
      setCompanySelected(companyGroupRes.data.companies);
      setCustomerUserSelected(companyGroupRes.data.group_admin_users);

      const customerUsers = await axios.get('/users/customer_admin', {
        params: {
          customerIds: companyGroupRes.data.companies
        }
      });
      const customerUserOptions = customerUsers.data.map((customer) => {
        return { value: customer._id, label: `${customer.username}(${customer.email})` }
      });
      setName(companyGroupRes.data.name)
      setCompanyOptions(customerOptions);
      setCustomerUserOptions(customerUserOptions);
    })();
  }, [props.match.params.id]);

  useEffect(() => {
    const tmpCompanySelectedOptions = companyOptions.map((company) => {
      return companySelected.includes(company.value) ? company : null
    }).filter((company) => company !== null);
    setCompanySelectedOptions(tmpCompanySelectedOptions);
    if (!isInitialLoad) {
      const tmpCustomerUserSelected = customerUserSelected.filter((user) => {
        return customerUserOptions.map((customer) => customer.value).includes(user);
      });
      if (tmpCustomerUserSelected.length !== customerUserSelected.length) {
        setCustomerUserSelected(tmpCustomerUserSelected);
      }
    } else if (companyGroup && customerUserOptions !== null) {
      setIsInitialLoad(false);
    }
  }, [companyOptions, companySelected, customerUserSelected, customerUserOptions, companyGroup, isInitialLoad]);

  useEffect(() => {
    if (customerUserOptions !== null) {
      const tmpCustomerUserSelectedOptions = customerUserOptions.map((customer) => {
        return customerUserSelected.includes(customer.value) ? customer : null
      }).filter((customer) => customer !== null);
      setCustomerUserSelectedOptions(tmpCustomerUserSelectedOptions);
    }
  }, [customerUserOptions, customerUserSelected]);

  const handleClickOpen = async () => {
    const result = await axios.get("company_groups/validation", {
      params: {
        id: id,
        name: name,
        companies: companySelected,
        group_admin_users: customerUserSelected,
      }
    });
    if (result.data.validate) {
      setMessage('');
      setOpen(true);
    } else {
      setMessage(result.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const res = await axios.put(`/company_groups/${id}`, {
      name: name,
      companies: companySelected,
      group_admin_users: customerUserSelected,
    })
    if (res.data.success) {
      handleClose();
      alert(t('更新しました'));
      props.history.push('/company_groups');
    }
  };

  const handleCompany = async (newValue) => {
    const customerUsers = await axios.get('/users/customer_admin', {
      params: {
        customerIds: newValue
      }
    });
    const customerOptions = customerUsers.data.map((customer) => {
      return { value: customer._id, label: `${customer.username}(${customer.email})` }
    });
    setCustomerUserOptions(customerOptions);
    setCompanySelected(newValue);
  }

  const handleCustomerUser = (newValue) => {
    setCustomerUserSelected(newValue);
  }

  return (
    <>
      <Details>
        <List style={{marginBottom: '20px'}}>
          <form className={classes.root} noValidate autoComplete="off">
            <ListItem dense>
              <ListItemText primary={<Typography style={{ fontWeight: "bold" }}>{t('企業グループ更新')}</Typography>} />
            </ListItem>
            <ListItem dense>
              <ListItemText className={classes.listTitle} primary={t("企業グループ名")} />
              <TextField name="name" id="standard-basic" className={classes.listText} onChange={(event) => setName(event.target.value)} value={name} />
            </ListItem>
            <ListItem dense>
              <ListItemText className={classes.annotations} primary={t('右のカスタマーのリストからグループにする企業を2社以上選択してください')} />
            </ListItem>
            <ListItem dense>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.customerListTitleSelected}
                        variant="body1"
                        component="div">
                        {t("グループにするカスタマー企業")}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.customerListTitle}
                        variant="body1"
                        component="div">
                        {t("カスタマー企業一覧")}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem dense>
              <Grid item xs={12} sm={12}>
                <DualListBox
                  options={companyOptions ? companyOptions : []}
                  selected={companySelected}
                  onChange={(newValue) => handleCompany(newValue)}
                  iconsClass={companySelected.length === 0 ? 'fa1' : companyOptions.length === companySelected.length ? 'fa3' : 'fa6'}
                />
              </Grid>
            </ListItem>
            <ListItem dense>
              <ListItemText className={classes.annotations} primary={t('グループ管理者を選択してください(後ほど登録することも可)')} />
            </ListItem>
            <ListItem dense>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.customerListTitleSelected}
                        variant="body1"
                        component="div">
                        {t("グループ管理者")}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primary={
                      <Typography
                        className={classes.customerListTitle}
                        variant="body1"
                        component="div">
                        {t("選択中のカスタマー企業の管理者一覧")}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
            <ListItem dense>
              <Grid item xs={12} sm={12}>
                <DualListBox
                  options={customerUserOptions ? customerUserOptions : []}
                  selected={customerUserSelected}
                  onChange={(newValue) => handleCustomerUser(newValue)}
                  iconsClass={customerUserSelected.length === 0 ? 'fa1' : customerUserOptions?.length === customerUserSelected.length ? 'fa3' : 'fa6'}
                />
              </Grid>
            </ListItem>
          </form>
        </List>
        {message !== '' ? (
          <>
            <Typography className="dangerText">{t(message)}</Typography>
          </>
        ) : null}
        <OsgButton disabled={companySelected.length <= 1 || name === ''} onClick={handleClickOpen}>
          {t('更新')}
        </OsgButton>

        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <BoldText>{t('企業グループ 内容確認')}</BoldText>
          </DialogTitle>
          <DialogContent>
          <List>
            <ListItem className={classes.dialogListTitle}>
              <ListItemText>
                <BoldText>{t('グループ名')}</BoldText>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.dialogListItem}>
              <ListItemText>{name}</ListItemText>
            </ListItem>
            <ListItem className={classes.dialogListTitle}>
              <ListItemText>
                <BoldText>{t('グループに属するカスタマー企業')}</BoldText>
              </ListItemText>
            </ListItem>
            {companySelectedOptions.map((company) => (
              <ListItem className={classes.dialogListItem} key={company.value}>
                <ListItemText className={classes.dialogListText} primary={company.label} />
              </ListItem>
            ))}
            <ListItem className={classes.dialogListTitle}>
              <ListItemText>
                <BoldText>{t('グループ管理者')}</BoldText>
              </ListItemText>
            </ListItem>
            {customerUserSelectedOptions.map((customer) => (
              <ListItem className={classes.dialogListItem} key={customer.value}>
                <ListItemText className={classes.dialogListText} primary={customer.label} />
              </ListItem>
            ))}
          </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setIsDisabled(true)
                handleSubmit(e)
              }}
              disabled={isDisabled}
            >
              <span  className="dangerText">{t('更新')}</span>
            </Button>
            <Button onClick={handleClose}>
            {t('戻る')}
            </Button>
          </DialogActions>
        </Dialog>
      </Details>
    </>
  );
};

export default CompanyEditPage;
