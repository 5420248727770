import React, { createRef, useState, useEffect } from 'react';
import moment from 'moment';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import axios from 'axios';
import styled from "styled-components";
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import {
  FormControl,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Encoding from 'encoding-japanese';

const DialogCloseBtn = styled(Button)`
  height: 10px !important;
  width: 50px;
  background-color: !important;
  margin: 5px 0px !important;
`;

const appButtonRef = createRef();
const dashboardButtonRef = createRef();

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxWidth: 1000,
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px"
  },
  listColumn: {
    display: "flex"
  },
  listName: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "20%",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "30px"
  },
  listSmallName: {
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    height: "20px",
    // width: "20%",
    textAlign: "left",
    // fontWeight: "bold",
    // paddingLeft: "30px"
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "23%"
  },
  header: {
    fontSize: '18px'
  },
  warning: {
    color: "#C90000",
  },
  link: {
    paddingLeft: "10px",
    color: "#00559D",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  listUpdateValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%",
    fontWeight: "bold"
  },
  tableContainer: {
    width: "74%",
    overflow: "scroll"
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  tableBody: {
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    borderBottom: "solid 2px #CACACA"
  },
  formControl: {
    width: "100%"
  },
  registerBtnContainer: {
    marginTop: "50px",
    marginBottom: "50px",
    marginRight: "50px"
  },
  registerBtn: {
    width: "100%",
    textAlign: "right",
    marginTop: "20px",
    bottom: 15
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px"
  },
  confirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    alignItems: "center",
  },
  dialogChosenMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "73%",
    marginTop: "15px",
    padding: "15px",
    backgroundColor: "#F6FBFF",
    borderRadius: 20
  },
  dialogTitle: {
    backgroundColor: "#00559D",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    width: "20%",
    marginTop: "10px",
    paddingLeft: "29%",
    paddingRight: "29%",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogDetail: {
    backgroundColor: "#FFFFFF",
    color: "#C90000",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    width: "40%",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  dialogCloseButton: {
    padding: "10px",
    width: "20px"
  }
}));

const TranslationCsvPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [languages, setLanguages] = useState([]);
  const [appError, setAppError] = useState('');
  const [dashboardError, setDashboardError] = useState('');
  const [multipleIds, setMultipleIds] = useState([]);
  const [header, setHeader] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [user, setUser] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [reserveItemCsvCount, setReserveItemCsvCount] = useState(0);
  const [existLanguages, setExistLanguages] = useState([]);
  const [notChangeJas, setNotChangeJas] = useState([]);
  const [unRegisterId, setUnRegisterId] = useState([]);
  const [csvType, setCsvType] = useState([]);
  const [multipleJas, setMultipleJas] = useState([]);
  const [logs, setLogs] = useState([]);
  const [columnList] = useState([
    '_id',
    'ja',
    'en'
  ]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setUser(JSON.parse(localStorage.getItem('user')));
    })();
  }, []);

  const handleAppOpenDialog = (e) => {
    if (appButtonRef.current) {
      appButtonRef.current.open(e);
    }
  };

  const handleDashboardOpenDialog = (e) => {
    if (dashboardButtonRef.current) {
      dashboardButtonRef.current.open(e);
    }
  };

  const handleOnAppCsvLoad = async (data) => {
    setCsvType('app')
    await handleOnFileLoad(data, 'app')
  }

  const handleOnDashboardCsvLoad = async (data) => {
    setCsvType('dashboard')
    await handleOnFileLoad(data, 'dashboard')
  }

  const handleOnFileLoad = async (data, fileType) => {
    setIsLoading(true)
    const titles = data.shift();

    let errorList = [];

    if (data[data.length - 1].data.length === 1 && data[data.length - 1].data[0] === "") {
      data.pop();
    }

    const incorrectDataColumn = _.filter(data, (d) => {
      return d.data.length !== 3
    })

    if (!_.isEqual(titles.data, columnList) || incorrectDataColumn.length > 0) {
      errorList.push(t("エラー：項目の数が一致しない行があります。"))
    } else {

      let response = null;

      if (fileType === 'app') {
        response = await axios.get(`/app_languages`);
      } else {
        response = await axios.get(`/languages`);
      }

      const existingData = response.data.map(row => {
        return row
      });

      const existDataList = data.filter((d) => {
        let result = false
        for (let key in existingData) {
          if (!d.data[0] && existingData[key]['ja'] === d.data[1]) {
            result = true
          }
        }
        return result;
      });

      if (titles.data[1] === 'ja' && existDataList.length > 0) {
        errorList.push(t("エラー：日本語名で既に存在する言語データがあります。"));
        setExistLanguages(existDataList);
      }

      const notChangeJaDataList = data.filter((d) => {
        let result = false
        for (let key in existingData) {
          if (d.data[0] === existingData[key]['_id'] && d.data[1] !== existingData[key]['ja']) {
            result = true
          }
        }
        return result;
      });

      if (titles.data[1] === 'ja' && notChangeJaDataList.length > 0) {
        errorList.push(t("エラー：日本語名は変更できません。"));
        setNotChangeJas(notChangeJaDataList);
      }

      const ids = data.map((raw) => {
        return raw.data[0];
      });

      const existIds = ids.filter((val) => {
        return typeof val !== 'undefined' && val !== '';
      });

      const multipleIds = existIds.filter((val, i, self) => {
        return self.indexOf(val) === i && i !== self.lastIndexOf(val) && self[0] !== '';
      });

      if (titles.data[0] === '_id' && multipleIds.length > 0) {
        setMultipleIds(multipleIds)
        errorList.push(t("エラー：以下のidが重複しています。"));
      }

      const registerJas = data.map((raw) => {
        return raw.data[1];
      });

      const multipleCsvJas = registerJas.filter((val, i, self) => {
        return self.indexOf(val) === i && i !== self.lastIndexOf(val)
      });

      if (titles.data[1] === 'ja' && multipleCsvJas.length > 0) {
        setMultipleJas(multipleCsvJas)
        errorList.push(t("エラー：ファイル内で以下の日本語が重複しています。"));
      }

      const unregisterIds = existIds.filter((d) => {
        let result = true;
        for (let key in existingData) {
          if (d === existingData[key]['_id'] || d === '') {
            result = false;
            break;
          }
        }
        return result;
      });

      if (titles.data[0] === '_id' && unregisterIds.length > 0) {
        setUnRegisterId(unregisterIds)
        errorList.push(t("エラー：以下の_idは登録されていません。登録する場合は_idを空にしてください。"));
      }
    }

    if (errorList.length === 0) {
      const languages = [];
      _.map(data, (row) => {
        languages.push(row.data);
      });
      setLanguages(languages);
      setHeader(titles.data);
      setAppError(null);
      setDashboardError(null);
      setMultipleIds([]);
    } else {
      if (fileType === 'app') {
        setAppError(errorList)
        setDashboardError(null);
      } else {
        setDashboardError(errorList)
        setAppError(null);
      }
    }
    setIsLoading(false)
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleSave = async () => {

    const kv = _.map(languages, (row) => {
      return _.zipObject(header, row);
    });
    let res = null;

    if (csvType === 'app') {
      res = await axios.post(`/app_languages`, {
        languages: kv,
      });
    } else {
      res = await axios.post(`/languages/csv`, {
        languages: kv,
      });
    }

    if (res.data.success) {
      alert(t('言語データの更新が完了しました'));
      setDialogVisible(false);
    } else {
      alert(t(res.data.error));
      setDialogVisible(false);
    }
    setLanguages(0)
  };

  const handleAppCsvDownload = async () => {
    setIsDownloading(true);

    let date = moment();

    date = date.format('YYYYMMDD');

    const fileName = `app_language_${ date }.csv`;
    const data = [[
      '_id',
      'ja',
      'en'
    ]];

    const response = await axios.get(`/app_languages`);

    response.data.map(row => {
      return data.push([
        row._id,
        row.ja,
        row.en,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    const unicodeList = [];
    for (let i = 0; i < rows.length; i += 1) {
      unicodeList.push(rows.charCodeAt(i));
    }
    const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
    const uInt8List = new Uint8Array(shiftJisCodeList);
    const csv = new Blob([uInt8List], { type: 'text/csv' });
    const jsonURL = window.URL.createObjectURL(csv);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  }

  const handleDashboardCsvDownload = async () => {
    setIsDownloading(true);

    let date = moment();

    date = date.format('YYYYMMDD');

    const fileName = `language_${ date }.csv`;
    const data = [[
      '_id',
      'ja',
      'en'
    ]];

    const response = await axios.get(`/languages`);

    response.data.map(row => {
      return data.push([
        row._id,
        row.ja,
        row.en,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    const unicodeList = [];
    for (let i = 0; i < rows.length; i += 1) {
      unicodeList.push(rows.charCodeAt(i));
    }
    const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
    const uInt8List = new Uint8Array(shiftJisCodeList);
    const csv = new Blob([uInt8List], { type: 'text/csv' });
    const jsonURL = window.URL.createObjectURL(csv);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  }

  return (
    <>
      <div className={classes.listContainer}>
        <dl style={{width: "100%", margin: 0}}>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("APP 言語ファイル")}</dt>
            <dd className={classes.listValue}>
              <OsgButton
                onClick={handleAppCsvDownload}
              >
                {t("ダウンロード")}
              </OsgButton>
            </dd>
            <dd className={classes.listValue}>
              <CSVReader
                ref={appButtonRef}
                onFileLoad={handleOnAppCsvLoad}
                onError={handleOnError}
                config={{
                  encoding: 'sjis',
                }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <aside>
                    <OsgButton
                      onClick={handleAppOpenDialog}
                      disabled={isLoading}
                    >
                    {t("アップロード")}
                    </OsgButton>
                    <span style={{marginLeft: "20px"}}>{file && file.name}</span>
                  </aside>
                )}
              </CSVReader>
            </dd>
          </div>
          {(appError) ? (
            <>
              {appError.map(e =>
                <div className={classes.listColumn} style={{marginTop: "10px", marginLeft: '270px'}}>
                  <Typography>
                    <span className="error-text">
                      {e}
                      {(e === t("エラー：以下のidが重複しています。")) ? (
                        <>
                          {multipleIds.map(i => ` ${i},`)}
                        </>
                      ) : (e === t("エラー：日本語名は変更できません。")) ? (
                        <>
                          {notChangeJas.map(i => ` ${i.data[0]},`)}
                        </>
                        ) : (e === t("エラー：以下の_idは登録されていません。登録する場合は_idを空にしてください。")) ? (
                        <>
                          {unRegisterId.map(i => ` ${i},`)}
                        </>
                        ) : (e === t("エラー：ファイル内で以下の日本語が重複しています。")) ? (
                        <>
                          {multipleJas.map(i => ` ${i},`)}
                        </>
                        ) : (e === t("エラー：日本語名で既に存在する言語データがあります。")) ? (
                        <>
                          {existLanguages.map(i => ` ${i.data[1]},`)}
                        </>
                      ) : null}
                    </span>
                  </Typography>
                </div>
              )}
            </>
          ) : null}
        </dl>
      </div>
      <div className={classes.listContainer}>
        <dl style={{width: "100%", margin: 0}}>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("ダッシュボード言語ファイル")}</dt>
            <dd className={classes.listValue}>
              <OsgButton
                onClick={handleDashboardCsvDownload}
              >
                {t("ダウンロード")}
              </OsgButton>
            </dd>
            <dd className={classes.listValue}>
              <CSVReader
                ref={dashboardButtonRef}
                onFileLoad={handleOnDashboardCsvLoad}
                onError={handleOnError}
                config={{
                  encoding: 'sjis',
                }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <aside>
                    <OsgButton
                      onClick={handleDashboardOpenDialog}
                      disabled={isLoading}
                    >
                    {t("アップロード")}
                    </OsgButton>
                    <span style={{marginLeft: "20px"}}>{file && file.name}</span>
                  </aside>
                )}
              </CSVReader>
            </dd>
          </div>
          {(dashboardError) ? (
            <>
              {dashboardError.map(e =>
                <div className={classes.listColumn} style={{marginTop: "50px", marginLeft: '270px'}}>
                  <Typography>
                    <span className="error-text">
                      {e}
                      {(e === t("エラー：以下のidが重複しています。")) ? (
                        <>
                          {multipleIds.map(i => ` ${i},`)}
                        </>
                      ) : (e === t("エラー：日本語名は変更できません。")) ? (
                        <>
                          {notChangeJas.map(i => ` ${i.data[0]},`)}
                        </>
                        ) : (e === t("エラー：以下の_idは登録されていません。登録する場合は_idを空にしてください。")) ? (
                        <>
                          {unRegisterId.map(i => ` ${i},`)}
                        </>
                        ) : (e === t("エラー：ファイル内で以下の日本語が重複しています。")) ? (
                        <>
                          {multipleJas.map(i => ` ${i},`)}
                        </>
                        ) : (e === t("エラー：日本語名で既に存在する言語データがあります。")) ? (
                        <>
                          {existLanguages.map(i => ` ${i.data[1]},`)}
                        </>
                      ) : null}
                    </span>
                  </Typography>
                </div>
              )}
            </>
          ) : null}
        </dl>
      </div>

      {languages.length > 0 ? (
        <>
          <h4>{t("プレビュー")}</h4>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size="small" aria-label="item table">
              <TableHead>
                <TableRow>
                  {header.map((title, index) => (
                    <TableCell
                      className={classes.tableHead}
                      key={ index }
                      style={index % 2 ? {backgroundColor: "#939393"} : {backgroundColor: "#BEBEBE"}}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {languages.map((row) => (
                  <TableRow>
                    {row.map((value, index) => (
                      <TableCell
                        className={classes.tableBody}
                        key={ index }
                        style={index % 2 ? {backgroundColor: "#DFDFDF"} : {backgroundColor: "#F5F5F5"}}
                        align="center"
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.registerBtnContainer}>
            <div className={classes.registerBtn}>
              <OsgButton
                onClick={() => setDialogVisible(true)}
                disabled={reserveItemCsvCount > 0}
              >
                {t("登録する")}
              </OsgButton>
            </div>
          </div>
        </>
      ) : null}

      <Dialog open={cancelDialogVisible} fullWidth maxWidth="sm">
        <div className={classes.dialogContainer}>
          <div style={{fontWeight: "bold", marginTop: "10px", marginBottom: "20px", textAlign: "center" }}>
            {t("予約中のデータを反映せずに取り消しますか？")}
          </div>

          <div className={classes.buttonContainer} style={{marginTop: "20px", textAlign: "right" }}>
            <Button
              color="secondary"
              variant="text"
              onClick={() => {
                setCancelDialogVisible(false)
              }}
              style={{height: "42px", marginRight: "0", color: "#C90000"}}
            >
              {t("予約の取り消し")}
            </Button>
            <Button
              variant="text"
              onClick={ () => setCancelDialogVisible(false) }
              style={{color: "#000000"}}
            >
              {t("キャンセル")}
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={dialogVisible} fullWidth maxWidth="sm">
        <div className={classes.confirmDialogContainer}>

          <div className={classes.dialogTitle}>
            {t("言語の登録")}
          </div>

          <div className={classes.dialogDetail}>
            {t("更新数")}&nbsp;&nbsp;&nbsp;{t("合計")}{languages.length}{t("点")}
          </div>

          <div style={{marginTop: "20px"}}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDialogVisible(false)}
              style={{width: "200px", height: "42px", marginRight: "20px"}}
            >
              {t("戻る")}
            </Button>
            <OsgButton
              onClick={(e) => {
                setIsDisabled(true)
                handleSave(e)
              }}
              disabled={isDisabled}
            >
            {t("登録する")}
            </OsgButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TranslationCsvPage;
