import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  CircularProgress
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import axios from 'axios';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import AddIcon from '@material-ui/icons/Add';
import styled from "styled-components";
import { Trans, useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .formControl {
    background-color: #fff;
  }

  .addCompanyBtn {
    margin-left: auto;
    text-decoration: none
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "@media (max-height:800px)":{
      maxHeight: "77vh",
    },
    "@media (max-height:600px)":{
      maxHeight: "70vh",
    },
    "@media (max-height:400px)":{
      maxHeight: "50vh",
    },
    maxHeight: "77vh",
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  separate: {
    borderCollapse: 'separate',
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableCompanyHead: {
    minWidth: '300px',
    position: 'sticky',
    left: 0,
    padding: "6px 16px 6px 16px",
    borderRight: 'solid 3px #CFCFCF',
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableCompanyBody: {
    width: '300px',
    position: 'sticky',
    left: 0,
    borderRight: 'solid 3px #CFCFCF',
    fontSize: "12pt",
    wordBreak: "break-all",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableInChargeHead: {
    minWidth: '200px',
    left: 0,
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableChargeBody: {
    width: '200px',
    left: 0,
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
});

const CompanyPage = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [companies, setCompanies] = useState(null);
  const [options, setOptions] = useState([]);



  useEffect(() => {
    (async () => {
      const res = await axios.get('/companies');
      setOptions(res.data);
      setCompanies(res.data);
    })();
  }, []);

  const handleChangeCompany = async (event) => {
    if(event.target.value === "all") {
      const res = await axios.get('/companies');
      setCompanies(res.data);
    } else {
      const companyId = event.target.value;
      const res = await axios.get(`companies/${ companyId }`);
      setCompanies([res.data]);
    }
  };

//  const handleDelete = (id) => {
//    axios.delete(`companies/${id}`).then((res) => {
//      if (res.data.success) {
//        getCompanies();
//      }
//    });
//  };

  return (
    <>
      <SubNavbar>
        <FormControl variant="outlined" size="small">
          <OsgSelect
            native
            onChange={handleChangeCompany}
            className="formControl"
          >
            <option value="all">{t('すべての企業')}</option>
            {options.map((v) => {
              return (
                <option value={ v._id } key={ v._id }>
                  {v.name}
                </option>
              );
            })}
          </OsgSelect>
        </FormControl>
        <Link to="/companies/add" className="addCompanyBtn">
          <OsgButton style={{width:"250px"}}>
            <AddIcon />
            {t('企業新規作成')}
          </OsgButton>
        </Link>
      </SubNavbar>
      <TableContainer className={classes.table}>
        <Table className={classes.separate} size="small" aria-label="company table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCompanyHead} style={{backgroundColor: "#D4E7FF"}}>{t('企業名')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} >{t('企業種別')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} >{t('企業コード')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} >{t('住所')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} >{t('企業連絡先')}</TableCell>
              <TableCell className={classes.tableInChargeHead} style={{backgroundColor: "#F5F5F5"}} >{t('担当者')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} >{t('契約Box数')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} >{t('企業アカウント詳細')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('稼働BOX一覧を見る')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(companies) ? (
              <>
                {companies.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className={classes.tableCompanyBody} style={{backgroundColor: "#D4E7FF"}} component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.is_osg ? 'OSG' : row.is_agent ? t('商社') : t('カスタマー') }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{row.code}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF", maxWidth:"250px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{row.address}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{row.tel}</TableCell>
                    <TableCell className={classes.tableChargeBody} style={{backgroundColor: "#F6FBFF"}} align="center">{row.in_charge}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.is_customer ? row.boxes.filter(v => v.status !== 3 && !v.deletedAt).length : <span>-</span> }</TableCell>
                    {/*<TableCell align="right">{row.boxes.length}</TableCell>*/}
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">
                      <Button href={`/companies/${row._id}`} style={{color: "#00559D"}}>
                      {t('詳細')}
                      </Button>
                      {/*
                      <Button variant="contained" color="primary" href={`/companies/edit/${row._id}`}> 編集 </Button>
                      <Button onClick={() => handleDelete(row._id)} variant="contained" color="secondary"> 削除 </Button>
                      */}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                      { row.is_customer ?
                      <Button href={`/boxes/customer/${row._id}`}>
                        <ArrowForwardIcon></ArrowForwardIcon>
                      </Button>
                      : null }
                    </TableCell>
                  </TableRow>
                ))}
              </>
          ) : (
            <TableRow>
              <TableCell colSpan={9} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompanyPage;
