import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";
import _ from 'lodash';
import { OsgSelect } from "../../Common/UIcomponents";
import moment from 'moment';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  .btn {
    margin-right: 20px;
    text-decoration: none
  }

  .csvBtn {
    width: 100%;
    text-align: right;
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "@media (max-height:800px)":{
      maxHeight: "75vh",
    },
    "@media (max-height:600px)":{
      maxHeight: "70vh",
    },
    "@media (max-height:400px)":{
      maxHeight: "50vh",
    },
    maxHeight: "75vh",
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    borderBottom: "solid 1px #00559D"
  },
  titleFont: {
    fontSize: "12pt",
    fontWeight: "bold"
  }
});

const HomePage = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [boxes, setBoxes] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [customerId, setCustomerId] = useState(0);
  const [boxStatus, setBoxStatus] = useState([t('未稼働'), t('稼働中'), t('稼働停止')]);

  useEffect(() => {
    (async () => {
      const res = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      if (res?.data == null) {
        setCustomers([]);
      } else {
        setCustomers(res.data);
      }
    })();
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.company_id?.is_osg) {
      let status = [...boxStatus];
      status.push(t('非表示'));
      setBoxStatus(status);
    }
    getBoxes();
  }, []);

  const getBoxes = async (customer_id = '0') => {
    setCustomerId(customer_id);
    const url = customer_id === '0' ? '/boxes' : `/boxes/customer/${customer_id}`;
    const boxes = await axios.get(url, {
      params: {
        withCompanyGroup: true
      }
    });
    if (boxes?.data == null) {
      setBoxes([]);
    } else {
      // setBoxes(typeof boxes.data == 'array' ? boxes.data : [boxes.data]);
      setBoxes(boxes.data);
    }
  };

  const handleChangeCustomer = (event) => {
    getBoxes(event.target.value);
  };

  const handleChangeStatus = async (event) => {
    const val = event.target.value === '' ? 'all' : event.target.value;
    const boxes = await axios.get(`/boxes/status/${ val }/${customerId}`);
    setBoxes(boxes.data);
  };

  const statusByLog = (logs, status) => {
    const date = moment().add(-3, 'd')
    // Todo date.isBefore(moment(l.createdAt))の期限が超えたら
    // l?.text.indexOf('パスワードが間違っています') === -1の条件は不要になる
    const error_log = _.filter(logs, (l) => {
      return l?.log_type === 2 && l?.text.indexOf('パスワードが間違っています') === -1 && date.isBefore(moment(l.createdAt))
    });
    if (error_log.length > 0 && status) {
      return t('アラート')
    } else {
      return boxStatus[status]
    }
  }

  return (
    <>
      <SubNavbar style={{justifyContent: 'flex-end'}}>
        {(boxes) ? (
          <>
            <OsgSelect onChange={handleChangeCustomer} style={{width: '245px', marginRight: "20px"}}>
              <option value="0">{t('すべての企業')}</option>
              {_.map(customers, (v) => {
                return (
                  <option value={ v._id } key={ v._id }>
                    {v.name}
                  </option>
                );
              })}
            </OsgSelect>
            <OsgSelect onChange={handleChangeStatus} style={{width: "245px"}}>
              <option value={ '' }>{t('すべての状況')}</option>
              { _.map(boxStatus, (v, k) => {
                return (
                  <option value={ k } key={ k }>
                    { v }
                  </option>
                );
              })}
            </OsgSelect>
          </>
        ) : (
          <>
            <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
            <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
          </>
        )}
      </SubNavbar>
      <SubNavbar style={{marginTop: "20px"}}>
        <div className={classes.titleFont}>
        {t('稼働BOX一覧')}
        </div>
      </SubNavbar>
      <TableContainer className={classes.table}>
        <Table size="small" aria-label="company table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('BOX名')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}}>{t('設置企業名')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('設置場所')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}}>{t('状況')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('詳細を見る')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(boxes) ? (
              <>
                {_.map(boxes, (row) => (
                  <TableRow key={row._id}>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{row?.name}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{row.customer_id?.name}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{row.address}</TableCell>
                    <TableCell
                      className={classes.tableBody}
                      align="center"
                      style={ statusByLog(row.logs, row.status) === t('アラート') ? {backgroundColor: "#C90000"} : row.status === 0 ? {backgroundColor: "#AFAFAF"} : row.status === 1 ? {backgroundColor: "#00559D"} : {backgroundColor: "#AFAFAF"}}
                    >
                      <span style={{color: "#fff"}}>
                        {statusByLog(row.logs, row.status)}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                      <Link to={`/logs?company_id=${row.customer_id?._id}&box_id=${row._id}&log_type=error`} style={{textDecoration: "none"}}>
                        <Button>
                        <div style={row.status === 2 ? {borderBottom: "solid 1px #C90000"} : {borderBottom: "solid 1px #7B7E80"}}>
                          <span style={row.status === 2 ? {color: "#C90000"} : {color: "#000"}}>
                          {t('詳細')}
                          </span>
                        </div>
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};


export default HomePage;
