import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Button,
  InputBase,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    display: 'flex',
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  time: {
    padding: '16px',
    wordBreak: 'break-all',
    fontSize: "1vw",
    width: "20%",
    borderBottom: "solid 1px #00559D",
    "@media (max-width:600px)":{
      "fontSize":"14px",
      "width": "100px",
    },
  },
  text: {
    padding: '16px',
    wordBreak: 'break-all',
    fontSize: "1vw",
    width: "80%",
    borderBottom: "solid 1px #00559D",
    "@media (max-width:600px)":{
      "fontSize":"14px",
      "width": "300px",
    },
  },
  tableContainer: {
    overflowWrap: 'break-word',
  },
  forcusTab: {
    backgroundColor: '#00559D !important'
  },
  tabHeight: {
    minHeight: '40px',
    marginTop: '8px'
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00559D",
    marginLeft: "20px"
  },
  dialogSubTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#838383",
    marginLeft: "20px",
    marginBottom: 5
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  modalContainer: {
    borderWidth: 0,
    height: 250,
    width: 600,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
  },
  modalTextField: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25,
  },
  textField: {
    width: 200,
    marginLeft: 30,
    marginRight: 30
  },
  pageTextField: {
    marginLeft: 30,
    paddingLeft: 10,
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderRadius: '5px 0 0 5px',
    height: 40,
    width: 70,
    verticalAlign: 'top'
  },
  pager: {
    marginTop: 10,
    display: 'flex',
  },
  uploadBtn: {
    backgroundColor: "#00559D",
    width: "100px",
    marginLeft: "20px"
  },
  container: {
    width: "80%",
    margin: "auto",
    overflowWrap: 'normal',
    "@media (max-width:600px)":{
      "width":"100%",
    },
  },
  title: {
    textAlign: 'center'
  },
  boxName: {
    textAlign: 'center',
    fontSize: '22px',
    fontWeight: "bold",
    paddingBottom: '20px'
  }
});

const BatteryLogPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [box, setBox] = useState(null);
  const [logs, setLogs] = useState([]);
  const [boxId, setBoxId] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [current, setCurrent] = useState(1);
  const [movePage, setMovePage] = useState(null);
  const [getParams] = useState(qs.parse(props.location.search, { ignoreQueryPrefix: true }));

  useEffect(() => {
    setBoxId(props.match.params.id);
    (async () => {
      if (props.match.params.id) {

        const boxesRes = await axios.get(`boxes/${ props.match.params.id }`);
        setBox(boxesRes.data);
      }

      if (props.match.params.id) {
        const logsRes = await axios.get(`/logs/battery`, {
          params: {
            box_id: props.match.params.id,
          }
        });
        setLogs(logsRes.data.log);
        setPageNum(logsRes.data.pageNum);
      } else {
        setBox([]);
      }
    })();
  }, []);


  const handlePageListChanged = () => {
    handleGetLogs(boxId, movePage);
  }

  const handlePageChanged = (_, page) => {
    handleGetLogs(boxId, page);
  }

  const handleGetLogs = async (editBoxId, page = 1) => {
    setLogs(null);
    setCurrent(page);
    const response = await axios.get(`/logs/battery`, {
      params: {
        box_id: editBoxId,
        page
      }
    });
    setLogs(response.data.log);
    setPageNum(response.data.pageNum);
  }

  const checkText = (event) => {
    if (!isNaN(event.target.value)) {
      setMovePage(Number(event.target.value))
    }
  }

  const isAvailablePage = () => {
    return Number(movePage) > Number(pageNum) || Number(movePage) === 0
  }

  return <>
    <div className={classes.container}>
      {(box) ? (
        <>
          <h2 className={classes.title}>{t('直近のバッテリーログ')}</h2>
          <div className={classes.boxName}>{t('BOX名')}: {box.name}</div>
          <TableContainer>
            <div className={classes.table} size="middle" aria-label="company table">
              {(logs !== null && logs) ? (
                <>
                  {_.map(logs, (row) => (
                    <div className={classes.tableRow} key={row._id}>
                      <div className={classes.time} style={{backgroundColor: "#D4E7FF"}} width="20%">{ moment(row.createdAt).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm:ss') }</div>
                      <div className={classes.text} style={{backgroundColor: "#fff"}} width="80%">{ row.text }</div>
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <div colSpan={3} align='center'>
                    <CircularProgress />
                  </div>
                </div>
              )}
            </div>
          </TableContainer>
          {(logs !== null && logs && pageNum > 1) ? (
            <div className={classes.pager}>
              <Pagination
                count={pageNum}
                page={current}
                showFirstButton
                showLastButton
                boundaryCount={2}
                onChange={handlePageChanged}
              />
              <div>
                <InputBase className={classes.pageTextField} value={movePage} onChange={(e) => checkText(e)} /> / {pageNum}
                <Button
                  color="primary"
                  className={classes.uploadBtn}
                  variant='contained'
                  disabled={isAvailablePage()}
                  onClick={() => handlePageListChanged()}
                >
                  {t('移動する')}
                </Button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  </>;
};

export default BatteryLogPage;
