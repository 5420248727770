import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import { List, ListItem, ListItemText, Divider} from "@material-ui/core";
import styled from "styled-components";
import logo from '../../assets/logo-sidenav.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useUserContext } from '../../../contexts/UserContext';

const SideNavArea = styled.div`
  height: 100%;
  background-color: #00559D;
`;
const LogoArea = styled.div`
  margin: 16px;
  a{
    text-decoration: none;
  }
`;
const ListArea = styled.div`

  nav {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .MuiButtonBase-root{
    width: 220px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0;
    height: 32px;
    a{
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 16px;
      text-decoration: none;
      width: 100%;
      span{
        font-weight: bold;
      }
    }
    a:hover{
      opacity: 0.8;
    }
    .active {
      background-color: #D4E7FF;
      color: #00559D;
    }
  }
  hr{
    width: 80%;
    margin-top: 4px;
    border-top: 1px solid #fff;
    margin-bottom: 4px;
  }
  .menu-item{
    color: white;
  }
`;

const ChangeUserBox = styled.div`
  border: 2px solid #fff;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: auto;
  max-width: 150px;
  padding: 15px;
  word-break: break-all;
`;

const ChangeUserBoxEn = styled.div`
  border: 2px solid #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin: auto;
  max-width: 160px;
  padding: 12px;
  word-break: break-all;
`;

const ChangeUserBoxTitle = styled.p`
  margin: 2px;
`;

const SideNav = () => {
  const [t] = useTranslation();
  // const user = JSON.parse(localStorage.getItem("user"));
  const parentUser = JSON.parse(localStorage.getItem("parent-user"));
  const [userType, setUserType] = useState('');
  const { user } = useUserContext();

  useEffect(() => {
    let tmpUserType = '';
    if (parentUser.company_id.is_osg && parentUser.is_admin && user.company_id.is_agent) {
      tmpUserType = '商社管理者';
    } else {
      tmpUserType = 'カスタマー管理者';
    }
    setUserType(tmpUserType);
  }, []);

  return (
    <SideNavArea>
      <LogoArea>
        <Link to="/dashboard">
          <img src={logo} style={{marginTop: 10}} alt={'MONOlithbox'}/>
        </Link>
      </LogoArea>
      <ListArea>
        <List component="nav">
          {user?.company_id?.is_osg || user?.company_id?.is_agent || (user?.is_admin && user?.company_id.is_customer) ? (
            <ListItem button>
              <NavLink
                activeClassName="active"
                to="/dashboard"
                className="flex menu-item bg-icon home-icon"
              >
                <ListItemText primary={t("TOP（稼働状況）")} />
              </NavLink>
            </ListItem>
          ) : null}
          <ListItem button>
            <NavLink
              activeClassName="active"
              to="/histories"
              className="flex menu-item bg-icon package-icon "
            >
              <ListItemText primary={t("購入履歴")} />
            </NavLink>
          </ListItem>
          {user?.company_id?.is_osg || user?.company_id?.is_agent || (user?.is_admin && user?.company_id?.is_customer) ? (
            <ListItem button>
              <NavLink
                to="/statistics"
                className="flex menu-item bg-icon announcement-icon"
              >
                <ListItemText primary={t("分析")} />
              </NavLink>
            </ListItem>
          ) : null}
          {user?.company_id?.is_osg || user?.company_id?.is_agent || (user?.is_admin && user?.company_id?.is_customer) ? (
          <ListItem button>
            <NavLink
              activeClassName="active"
              to="/logs"
              className="flex menu-item bg-icon package-icon "
            >
              <ListItemText primary={t("各種ログ")} />
            </NavLink>
          </ListItem>
          ) : null}
          <ListItem button>
            <NavLink
              activeClassName="active"
              to="/stocks"
              className="flex menu-item bg-icon contact-icon"
            >
              <ListItemText primary={t("在庫状況")} />
            </NavLink>
          </ListItem>
          {(user?.company_id?.is_agent) && (
            <ListItem button>
              <NavLink
                activeClassName="active"
                to="/topup"
                className="flex menu-item bg-icon group-icon"
              >
                <ListItemText
                  primary={t("製品の補充/撤去")}
                />
              </NavLink>
            </ListItem>
          )}
          <Divider />
          {user?.company_id?.is_osg ? (
            <ListItem button>
              <NavLink
                activeClassName="active"
                exact to="/company_groups"
                className="flex menu-item  bg-icon org-icon"
              >
                <ListItemText primary={t("企業グループ")} />
              </NavLink>
            </ListItem>
          ) : null}
          {user?.company_id?.is_osg || user?.company_id?.is_agent ? (
            <ListItem button>
              <NavLink
                activeClassName="active"
                exact to="/companies"
                className="flex menu-item  bg-icon org-icon"
              >
                <ListItemText primary={t("企業一覧")} />
              </NavLink>
            </ListItem>
          ) : null}
          {user?.company_id?.is_agent || user?.company_id?.is_osg ? (
            <>
              <ListItem button>
                <NavLink
                  activeClassName="active"
                  exact to="/boxes"
                  className="flex menu-item  bg-icon org-icon"
                >
                  <ListItemText primary={t("本体管理")} />
                </NavLink>
              </ListItem>
              {/*
              <ListItem button>
                <NavLink
                  activeClassName="active"
                  to="/boxes/add"
                  className="flex menu-item bg-icon location-icon"
                >
                  <ListItemText primary={t("設置BOX新規登録")} />
                </NavLink>
              </ListItem>
              */}
            </>
          ) : null}
          {user?.company_id?.is_osg || user?.company_id?.is_agent || (user?.is_admin && user?.company_id?.is_customer) ? (
            <ListItem button>
              <NavLink
                to="/users"
                className="flex menu-item bg-icon announcement-icon"
              >
                <ListItemText primary={t("ユーザー管理")} />
              </NavLink>
            </ListItem>
          ) : null}
          {user?.company_id?.is_osg || user?.company_id?.is_agent || (user?.is_admin && user?.company_id?.is_customer) ? (
            <Divider />
          ) : null}
          {user?.company_id?.is_customer ? (
          <ListItem button>
            <NavLink
              to={`/companies/${user?.company_id._id}`}
              className="flex menu-item bg-icon announcement-icon"
            >
              <ListItemText primary={t("自社登録情報")} />
            </NavLink>
          </ListItem>
          ) : null }
          <ListItem button>
            <NavLink
              to="/settings"
              className="flex menu-item bg-icon announcement-icon"
            >
              <ListItemText primary={t("マイページ")} />
            </NavLink>
          </ListItem>
          {user?.is_admin && user?.company_id?.is_osg ? (
          <ListItem button>
            <NavLink
              to="/translation"
              className="flex menu-item bg-icon announcement-icon"
            >
              <ListItemText primary={t("翻訳")} />
            </NavLink>
          </ListItem>) : null}
          <ListItem button>
            <NavLink
              to="/signout"
              className="flex menu-item bg-icon announcement-icon"
            >
              <ListItemText primary={t("ログアウト")} />
            </NavLink>
          </ListItem>
        </List>
      </ListArea>
      {parentUser.company_id?.is_osg && !user?.company_id?.is_osg && user.lang === 'ja' ? (
        <ChangeUserBox>{user.company_id?.name}<ChangeUserBoxTitle>{t(userType)}</ChangeUserBoxTitle>に切り替え中</ChangeUserBox>
      ) : (parentUser.company_id?.is_osg && !user.company_id?.is_osg && user.lang === 'en') ? (
        <ChangeUserBoxEn>{user.company_id.name}<ChangeUserBoxTitle>Currently switching</ChangeUserBoxTitle><ChangeUserBoxTitle>to</ChangeUserBoxTitle><ChangeUserBoxTitle>{t(userType)}</ChangeUserBoxTitle></ChangeUserBoxEn>
      ) : null}
    </SideNavArea>
  );
};

export default SideNav;
