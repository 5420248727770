import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useDynamicStyles = (windowHeight) => makeStyles({
  table: {
    minWidth: 650,
    maxHeight: windowHeight - 300,
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  separate: {
    borderCollapse: 'separate',
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "6px 16px 6px 16px",
    backgroundColor: "#D4E7FF",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableUserHead: {
    minWidth: '300px',
    position: 'sticky',
    borderRight: 'solid 3px #CFCFCF',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableUserBody: {
    width: '300px',
    position: 'sticky',
    borderRight: 'solid 3px #CFCFCF',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
  tableCompanyHead: {
    minWidth: '300px',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontWeight: "bold",
    fontSize: "12pt",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableCompanyBody: {
    width: '300px',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
  titleFont: {
    fontSize: "1.2vw",
    fontWeight: "bold"
  }
});

const UserList = (props) => {
  const [t] = useTranslation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数でイベントリスナーを削除
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const classes = useDynamicStyles(windowHeight)();

  return (
    <>
      <TableContainer className={classes.table} >
        <Table className={classes.separate} size="small" aria-label="company table">
          <TableHead>
            <TableRow>
            <TableCell className={classes.tableUserHead} style={{backgroundColor: "#D4E7FF"}}>{t('ユーザー名')}</TableCell>
            <TableCell className={classes.tableCompanyHead} style={{backgroundColor: "#F5F5F5"}}>{t('企業名')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('企業種別')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}}>{t('区分')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('状態')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}}>{t('メールアドレス')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}}>{t('詳細')}</TableCell>
              {/*<TableCell className={classes.tableHead}>名前</TableCell>
              <TableCell className={classes.tableHead}>E-mail</TableCell>
              <TableCell className={classes.tableHead}>詳細</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.users) ? (
              <>
                {props.users.map((row) => {
                  {/* バックエンド側でフィルタリング */}
                  {/* if (/.*@osg\.guestlogin/.test(row.email)) return null; */}

                  return <TableRow key={row._id}>
                    <TableCell className={classes.tableUserBody} style={{backgroundColor: "#D4E7FF"}}> {row.username}</TableCell>
                    <TableCell className={classes.tableCompanyBody} style={{backgroundColor: "#F6FBFF"}}>{ row.company_id?.name }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ row.company_id?.is_osg ? 'OSG' : row.company_id?.is_agent ? t('商社') : t('カスタマー') }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.is_admin ? t('管理者') : t('一般') }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.status === 1 ? t('仮登録'): row.status === 2 ? t('アクティブ') : t('停止') }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.email }</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                      <Button href={`/users/edit/${row._id}`} style={{color: "#00559D"}}> {t('詳細')} </Button>

                    {/*</TableCell>]
                    <TableCell className={classes.tableBody} width="30%"> {row.username}</TableCell>
                    <TableCell className={classes.tableBody} width="40%">{ row.email }</TableCell>
                    <TableCell className={classes.tableBody} width="10%" align="center">
                      <Button href={`/users/edit/${row._id}`}>
                        <ArrowForwardIcon></ArrowForwardIcon>
                    </Button>*/}
                      { /*
                      <Button variant="contained" color="primary" href={`/users/edit/${row._id}`}> 編集 </Button>
                      <Button variant="contained" color="secondary"> 削除 </Button>
                      */ }
                    </TableCell>
                  </TableRow>
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserList;
