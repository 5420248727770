import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import styled from "styled-components";
import axios from 'axios';
import { OsgButton } from "../../Common/UIcomponents/index";
import { Trans, useTranslation } from 'react-i18next';
import { useUserContext } from '../../../contexts/UserContext';

const Details = styled.div`
  max-width: 1000px;
  text-align: center;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const useStyles = makeStyles({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#00559D"
  },
  listText:  {
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  dialogListTitle: {
    width: "40%",
  },
  dialogListText: {
    width: "60%"
  }
});

const CompanyEditPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { user, handleUpdateUser } = useUserContext();

  const [id, setId] = useState('');
  const [open, setOpen] = useState(false);
  const [sessionUser, setSessionUser] = useState({});
  const [values, setValues] = useState({
    name: '',
    code: '',
    address: '',
    tel: '',
    in_charge: '',
  });

  useEffect(() => {
    const id = props.match.params.id;
    setId(id);
    const sessionUser = JSON.parse(localStorage.getItem('user'));
    setSessionUser(sessionUser);
    axios.get(`companies/${id}`).then((res) => {
      setValues({ ...res.data });
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const res = await axios.put(`/companies/${id}`, values);
    if (res.data.success) {
      handleClose();
      const tmpSessionUser = JSON.parse(localStorage.getItem('user'));
      let company = tmpSessionUser.company_id;
      company.name = values.name;
      if (id === sessionUser.company_id._id) {
        localStorage.setItem('user', JSON.stringify({ ...user, company_id: company }));
        const updateUser = localStorage.getItem('user');
        handleUpdateUser(JSON.parse(updateUser));
      }
      props.history.push(`/companies/${id}`);
    }
  };

  return (
    <>
      <Details>
        <List style={{marginBottom: '20px'}}>
          <form className={classes.root} noValidate autoComplete="off" style={{marginTop: '37px'}}>
            <ListItem dense>
              <ListItemText className={classes.listTitle} primary={t("企業名")} />
              <TextField name="name" id="standard-basic" className={classes.listText} onChange={handleChange} value={ values.name } />
            </ListItem>
            <ListItem dense>
              <ListItemText className={classes.listTitle} primary={t("企業コード(任意)")} />
              <TextField name="code" id="standard-basic" className={classes.listText} onChange={handleChange} value={ values.code } />
            </ListItem>
            <ListItem dense className={classes.listItem} style={{marginTop: "10px"}}>
              <ListItemText className={classes.listTitle} primary={t("住所(任意)")} />
              <TextField name="address" id="standard-basic" className={classes.listText} onChange={handleChange}  value={ values.address } />
            </ListItem>
            <ListItem dense className={classes.listItem}>
              <ListItemText className={classes.listTitle} primary={t("企業連絡先(任意)")} />
              <TextField name="tel" id="standard-basic" className={classes.listText} onChange={handleChange} value={ values.tel }/>
            </ListItem>
            <ListItem dense className={classes.listItem}>
              <ListItemText className={classes.listTitle} primary={t("担当者名(任意)")} />
              <TextField name="in_charge" id="standard-basic" className={classes.listText} onChange={handleChange} value={ values.in_charge }/>
            </ListItem>
          </form>
        </List>
        <OsgButton onClick={handleClickOpen}>
        {t("登録する")}
        </OsgButton>

        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <BoldText>{t("登録確認")}</BoldText>
          </DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
                <ListItemText className={classes.dialogListTitle}>
                  <BoldText>{t("企業名")}</BoldText>
                </ListItemText>
                <ListItemText className={classes.dialogListText}>
                  <BoldText>{ values.name }</BoldText>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText className={classes.dialogListTitle} primary={t("企業コード(任意)")} />
                <ListItemText className={classes.dialogListText} primary={ values.code } />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.dialogListTitle} primary={t("住所(任意)")} />
                <ListItemText className={classes.dialogListText} primary={ values.address } />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.dialogListTitle} primary={t("企業連絡先(任意)")} />
                <ListItemText className={classes.dialogListText} primary={ values.tel } />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.dialogListTitle} primary={t("担当者名(任意)")} />
                <ListItemText className={classes.dialogListText} primary={ values.in_charge } />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} autoFocus>
              <span className="dangerText">{t("登録")}</span>
            </Button>
            <Button onClick={handleClose}>
            {t("戻る")}
            </Button>
          </DialogActions>
        </Dialog>
      </Details>
    </>
  );
};

export default CompanyEditPage;
