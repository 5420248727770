import React, { createRef, useState, useEffect } from 'react';
import moment from 'moment';
import { log } from '../../../utils/Logger.js';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import axios from 'axios';
import styled from "styled-components";
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import {
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const DialogCloseBtn = styled(Button)`
  height: 10px !important;
  width: 50px;
  background-color: !important;
  margin: 5px 0px !important;
`;

const buttonRef = createRef();

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "50px"
  },
  listColumn: {
    display: "flex"
  },
  listName: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "15%",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "30px"
  },
  header: {
    color: "#C90000",
    fontSize: '18px'
  },
  warning: {
    color: "#C90000",
  },
  link: {
    cursor: "pointer",
    paddingLeft: "10px",
    color: "#00559D",
  },
  listUpdateName: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "20%",
    textAlign: "left",
    fontWeight: "bold",
    paddingLeft: "30px",
    color: "#C90000",
  },
  listUpdateValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%",
    fontWeight: "bold",
    color: "#C90000",
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%"
  },
  errorContainer: {
    display: "flex",
    color: "#C90000",
  },
  errorMessage: {
    fontWeight: "bold",
    width: "380px",
  },
  errorEdpNoTitle: {
    fontWeight: "bold",
    width: "80px",
  },
  errorEdpNo: {
    fontWeight: "bold",
    width: "450px",
  },
  tableContainer: {
    width: "calc(100vw - 280px)",
    overflow: "scroll"
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  tableBody: {
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    borderBottom: "solid 2px #CACACA"
  },
  formControl: {
    width: "100%"
  },
  registerBtnContainer: {
    position: "fixed",
    width: "calc(100vw - 280px)",
    height: "120px",
    bottom: 0,
    background: "linear-gradient(rgba(0,0,0,0),rgba(255,255,255,1))"
  },
  registerBtn: {
    position: "absolute",
    width: "100%",
    textAlign: "right",
    bottom: 15
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px"
  },
  dialogChosenMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
    marginTop: "30px",
    padding: "15px",
    backgroundColor: "#D4E7FF",
    borderRadius: 20
  },
  dialogTitle: {
    backgroundColor: "#C90000",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    width: "30%",
    marginTop: "20px",
    paddingLeft: "20%",
    paddingRight: "20%",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogDetail: {
    backgroundColor: "#FCF2F2",
    color: "#C90000",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "25px",
    width: "40%",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  dialogCloseButton: {
    padding: "10px",
    width: "20px"
  }
}));

const CsvForm = (props) => {
  const [t] = useTranslation();
  const [customerList, setCustomerList] = useState([]);
  const classes = useStyles();

  const [boxList, setBoxList] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [boxId, setBoxId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [boxName, setBoxName] = useState('');
  const today = moment().format(t('YYYY年MM月DD日(ddd)'));
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const [multipleItems, setMultipleItems] = useState([]);
  const [emptyItems, setEmptyItems] = useState([]);
  const [abbreviationEmptyItems, setAbbreviationEmptyItems] = useState([]);
  const [minusItems, setMinusItems] = useState([]);
  const [nanItems, setNanItems] = useState([]);
  const [priceNanItems, setPriceNanItems] = useState([]);
  const [integerItems, setIntegerItems] = useState([]);
  const [type, setType] = useState('append');
  const [header, setHeader] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [parentUser, setParentUser] = useState({});
  const [reserveItemCsvCount, setReserveItemCsvCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [columnList] = useState([
    'edp_no',
    'abbreviation',
    'designation',
    'number_of_flutes',
    'overall_thread_length_shank_dia',
    'grade',
    'surface_treatment',
    'category',
    'weight',
    'materials',
    'drill_hole_dia',
    'standard_price',
    'quantity',
    'reorder_point',
    'location',
    'footnotes',
    'image',
  ]);

  useEffect(() => {
    (async () => {
      if (props.location.state?.boxId && props.location.state?.customerId ) {
        setCustomerId(props.location.state.customerId)
        const defaultCustomerList = await axios.get('/companies/');
        setCustomerList(defaultCustomerList.data);
        const customer = _.find(defaultCustomerList.data, (c) => {
          return c._id == props.location.state.customerId;
        });
        setCustomerName(customer.name)
        const res = await axios.get(`/boxes/customer/${props.location.state.customerId}`);
        setBoxList(res.data);
        const box = _.find(res.data, (c) => {
          return c._id == props.location.state.boxId;
        });
        setBoxName(box.name)
        setBoxId(props.location.state.boxId)
        const reserveItemRes = await axios.get(`/reserve_item_csv`, {
          box_id: boxId,
          customer_id: customerId
        });
        if (reserveItemRes.data.reserveItemCsvCount > 0) {
          setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
        }
      } else {
        setCustomerList(props.customerList);
      }
      setParentUser(JSON.parse(localStorage.getItem('parent-user')));
    })();
  }, []);

  const handleSetCustomerId = async (event) => {
    setCustomerId(event.target.value);
    setBoxList(null);
    const res = await axios.get(`/boxes/customer/${event.target.value}`);
    const selectedCustomer = customerList.find(c => c._id === event.target.value);
    setCustomerName(selectedCustomer.name);
    setBoxList(res.data);
  };

  const handleSetBoxId = async (event) => {
    setBoxId(event.target.value);
    const reserveItemRes = await axios.get(`/reserve_item_csv`, {
      params: {
        box_id: event.target.value
      }
    });
    const selectedBox = boxList.find(b => b._id === event.target.value);
    setBoxName(selectedBox.name);
    if (reserveItemRes.data.reserveItemCsvCount > 0) {
      setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
    } else {
      setReserveItemCsvCount(0)
    }
  };

  const handleSetReserveItemCsvCount = async (event) => {
    const reserveItemRes = await axios.get(`/reserve_item_csv`, {
      params: {
        box_id: boxId
      }
    });
    setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const titles = data.shift();

    let errorList = [];
    const items = [];
    _.map(data, (row) => {
      if (row.data.length > 1) {
        items.push(row);
      }
    });
    data = items;
    const incorrectDataColumn = _.filter(data, (d) => {
      return d.data.length !== 17
    })

    if (titles.data[14] === 'remarks' || titles.data.length === 16) {
      errorList.push(t("エラー：CSVのフォーマットが古い可能性があります。"))
    } else if (!_.isEqual(titles.data, columnList) || incorrectDataColumn.length > 0 || titles.data.length !== 17) {
      errorList.push(t("エラー：CSVのフォーマットが正しくありません。"))
    } else {

      const edpNoEmptyDataList = data.filter((raw) => {
        return !raw.data[0];
      });

      if (titles.data[0] === 'edp_no' && edpNoEmptyDataList.length > 0) {
        errorList.push(t("エラー：EDP No.に空欄があります。"));
      }

      const abbreviationEmptyDataList = data.filter((raw) => {
        return !raw.data[1];
      });

      if (titles.data[1] === 'abbreviation' && abbreviationEmptyDataList.length > 0) {
        errorList.push(t("エラー：製品記号に空欄があります。"));
        setAbbreviationEmptyItems(abbreviationEmptyDataList);
      }

      const weightNanDataList = data.filter((raw) => {
        return String(raw.data[8]).match(/^[-+]?\d*.?\d+$/) === null && String(raw.data[8]) !== '';
      });

      if (titles.data[8] === 'weight' && weightNanDataList.length > 0) {
        errorList.push(t("エラー：数値以外の数量が入力されています。"));
        setNanItems(weightNanDataList);
      }

      const nanStandardPrices = data.filter((raw) => {
        const value = raw.data[11];
        const isNumber = /^-?\d+(\.\d+)?$/.test(value);
        const hasComma = /^-?\d{1,3}(,\d{3})*(\.\d+)?$/.test(value);
        return !isNumber && (hasComma || value !== '');
      });
      const minusDataList = data.filter((raw) => {
        return raw.data[12] < 0;
      });

      const emptyDataList = data.filter((raw) => {
        return !raw.data[12];
      });

      const nanDataList = data.filter((raw) => {
        return String(raw.data[12]).match(/^[-+]?\d*.?\d+$/) === null && String(raw.data[12]) !== '';
      });

      const integerDataList = data.filter((raw) => {
        return !Number.isInteger(Number(raw.data[12])) && String(raw.data[12]) !== '';
      });

      const minusOPDataList = data.filter((raw) => {
        return raw.data[13] < 0;
      });

      const nanOPDataList = data.filter((raw) => {
        return String(raw.data[13]).match(/^[-+]?\d*.?\d+$/) === null && String(raw.data[13]) !== '';
      });

      const integerOPDataList = data.filter((raw) => {
        return !Number.isInteger(Number(raw.data[13])) && String(raw.data[13]) !== '';
      });

      if (titles.data[11] === 'standard_price' && nanStandardPrices.length > 0) {
        errorList.push(t("エラー：標準価格はカンマ｢,｣は使用できません。"));
        setPriceNanItems(nanStandardPrices);
      }
      if ((titles.data[12] === 'quantity' && minusDataList.length > 0) || (titles.data[13] === 'reorder_point' && minusOPDataList.length > 0)) {
        errorList.push(t("エラー：マイナスの数字は登録できません"));
        setMinusItems(minusDataList.concat(minusOPDataList));
      }
      if (titles.data[12] === 'quantity' && emptyDataList.length > 0) {
        errorList.push(t("エラー：個数に空欄があります。0の場合は0を入力してください。"));
        setEmptyItems(emptyDataList);
      }
      if ((titles.data[12] === 'quantity' && nanDataList.length > 0) || (titles.data[13] === 'reorder_point' && nanOPDataList.length > 0) ) {
        errorList.push(t("エラー：数値以外の数量が入力されています。"));
        setNanItems(nanDataList.concat(nanOPDataList));
      }

      if ((titles.data[12] === 'quantity' && integerDataList.length > 0) || (titles.data[13] === 'reorder_point' && integerOPDataList.length > 0)) {
        errorList.push(t("エラー：整数のみを入力してください。"));
        setIntegerItems(integerDataList.concat(integerOPDataList));
      }

      const epdNos = data.map((raw) => {
        return raw.data[0];
      });

      const multipleEpsNos = epdNos.filter((val, i, self) => {
        return self.indexOf(val) === i && i !== self.lastIndexOf(val);
      });

      if (titles.data[0] === 'edp_no' && multipleEpsNos.length > 0) {
        setMultipleItems(multipleEpsNos)
        errorList.push(t("エラー：以下のEdp Noが重複しています。"));
      }
    }

    if (errorList.length === 0) {
      const items = [];
      _.map(data, (row) => {
        if (row.data[0] !== '') {
          items.push(row.data);
        }
      });
      setItems(items);
      setHeader(titles.data);
      setError(null);
      setMultipleItems([]);
      setMinusItems([]);
    } else {
      setError(errorList)
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleReserveCancel = async () => {
    const res = await axios.put(`/reserve_item_csv`, {
      box_id: boxId,
      customer_id: customerId,
    });

    if (res.data.reserveItemCsv.is_canceled) {
      log('在庫数量 CSVアップロード/予約補充/予約キャンセル', 'topup', boxId);
      alert(t('予約をキャンセルしました。'));
      setCancelDialogVisible(false);
      handleSetReserveItemCsvCount();
    }
  }

  const handleSave = async () => {
    let options = {};
    let tmpHeader = header;
    // 項目名変更があったため、表示場はfootnotesだがここで名称を置き換える
    tmpHeader[15] = 'remarks';
    const kv = _.map(items, (row) => {
      return _.zipObject(tmpHeader, row);
    });
    const res = await axios.post(`/items/topup`, {
      box_id: boxId,
      customer_id: customerId,
      items: kv,
      type: "renew",
    });
    if (res.status === 200 && res.data.success === true) {
      options.items = kv;
      log('在庫品目 CSV更新', 'topup', boxId, options);
      alert(t("データの洗い替えを完了しました"))
      props.history.push({ pathname: '/stocks', state: { customer_id: customerId, box_id: boxId }});
    } else if (res.data.error.errors) {
      let messages = [];
      for (const [key, value] of Object.entries(res.data.error.errors)) {
        messages.push(`${t('カラム名')}: ${key} ${t('値')}: ${value.value}`)
      }
      const message = messages.join('\n');
      alert(`${t('入力に誤りがあります。')}\n${message}`);
      setDialogVisible(false);
    } else {
      alert(t(res.data.error));
      setDialogVisible(false);
    }
  };

  return (
    <>
      <div className={classes.listContainer}>
        <dl style={{width: "100%", margin: 0}}>
          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listUpdateName}>
              <h2 className={classes.header}>{t("在庫品目 更新")}</h2>
            </dt>
            <dd className={classes.listUpdateValue}>
              {(reserveItemCsvCount > 0) ? (
                <>
                  <span className={classes.warning}>{t('このBOXは予約中のデータがあるので、更新は出来ません。')}</span>
                  <span className={classes.link} onClick={() => setCancelDialogVisible(true)}>-&gt;{t('予約を取り消す')}</span>
                </>
              ) : null}
            </dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("更新日")}</dt>
            <dd className={classes.listValue}>{today}</dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("補充者名")}</dt>
            <dd className={classes.listValue}>{parentUser.username}</dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("会社名")}</dt>
            <dd className={classes.listValue}>
              <FormControl className={classes.formControl}>
                <OsgSelect
                  value={customerId}
                  onChange={handleSetCustomerId}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{width: '254px'}}
                >
                  <option value=""> - </option>
                  {customerList.map((row) => {
                    return <option key={ row._id } value={row._id}>{row.name}</option>;
                  })}
                </OsgSelect>
              </FormControl>
            </dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}>{t("補充するBOXを選択")}</dt>
            <dd className={classes.listValue}>
              {(boxList) ? (
                <FormControl className={classes.formControl}>
                  <OsgSelect
                    value={boxId}
                    onChange={handleSetBoxId}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{width: '254px'}}
                  >
                    <option value=""> - </option>
                    {boxList.map((row) => {
                      return <option key={ row._id } value={row._id}>{row.name}</option>;
                    })}
                  </OsgSelect>
                </FormControl>
              ) : (
                <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              )}
            </dd>
          </div>

          <div className={classes.listColumn} style={{marginTop: "20px"}}>
            <dt className={classes.listName}></dt>
            <dd className={classes.listValue}>
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                config={{
                  encoding: 'sjis',
                }}
                noClick
                noDrag
              >
                {({ file }) => (
                  <aside>
                    <OsgButton
                      onClick={handleOpenDialog}
                      disabled={reserveItemCsvCount > 0 || !boxId}
                    >
                    {t("アップロード")}
                    </OsgButton>
                    <span style={{marginLeft: "20px"}}>{file && file.name}</span>
                  </aside>
                )}
              </CSVReader>
            </dd>
          </div>
          {(error) ? (
            <>
              {error.map((e, index) =>
                <div
                  className={classes.listColumn}
                  style={{marginTop: "10px"}}
                  key={  `error_${index}` }
                >
                <dt className={classes.listName}></dt>
                <dd className={classes.errorContainer}>
                  <Typography className={classes.errorMessage}>{e}</Typography>
                  <Typography className={classes.errorEdpNoTitle}>Edp No. </Typography>
                  <Typography className={classes.errorEdpNo}>
                    {(e === t("エラー：マイナスの数字は登録できません")) ? (
                      <>
                        {minusItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : (e === t("エラー：以下のEdp Noが重複しています。")) ? (
                      <>
                        {multipleItems.map(i => ` ${i},`)}
                      </>
                    ) : (e === t("エラー：標準価格はカンマ｢,｣は使用できません。")) ? (
                      <>
                        {priceNanItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : (e === t("エラー：数値以外の数量が入力されています。")) ? (
                      <>
                        {nanItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : (e === t("エラー：整数のみを入力してください。")) ? (
                      <>
                        {integerItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : (e === t("エラー：個数に空欄があります。0の場合は0を入力してください。")) ? (
                      <>
                        {emptyItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : (e === t("エラー：製品記号に空欄があります。")) ? (
                      <>
                        {abbreviationEmptyItems.map(i => ` ${i.data[0]},`)}
                      </>
                    ) : null}
                  </Typography>
                </dd>
              </div>)}
            </>
          ) : null}
        </dl>
      </div>

      {items.length > 0 ? (
        <>
          <h4>{t("プレビュー")}</h4>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size="small" aria-label="item table">
              <TableHead>
                <TableRow>
                  {header.map((title, index) => (
                    <TableCell
                      className={classes.tableHead}
                      key={  `header_${index}` }
                      style={index % 2 ? {backgroundColor: "#939393"} : {backgroundColor: "#BEBEBE"}}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row, itemIndex) => (
                  <TableRow key={ `i_column_${itemIndex}` }>
                    {row.map((value, index) => (
                      <TableCell
                        className={classes.tableBody}
                        key={ `i_row_${index}` }
                        style={index % 2 ? {backgroundColor: "#DFDFDF"} : {backgroundColor: "#F5F5F5"}}
                        align="center"
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.registerBtnContainer}>
            <div className={classes.registerBtn}>
              <OsgButton onClick={() => setDialogVisible(true)}>
                {t("登録をする")}
              </OsgButton>
            </div>
          </div>
        </>
      ) : null}

      <Dialog open={cancelDialogVisible} fullWidth maxWidth="sm">
        <div style={{textAlign: "right"}}>
          <DialogCloseBtn onClick={ () => setCancelDialogVisible(false) }>{t("閉じる")}</DialogCloseBtn>
        </div>
        <div className={classes.dialogContainer}>
          <div style={{fontWeight: "bold", marginTop: "10px", marginBottom: "20px" }}>
            {t("予約中のデータを反映せずに取り消しますか？")}
          </div>

          <div style={{marginTop: "20px"}}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setCancelDialogVisible(false);
                handleReserveCancel();
              }}
              style={{width: "200px", height: "42px", marginRight: "20px"}}
            >
              {t("予約の取り消し")}
            </Button>
            <OsgButton
              onClick={(e) => {
                setCancelDialogVisible(false);
              }}
              disabled={isDisabled}
            >
            {t("キャンセル")}
            </OsgButton>
          </div>
        </div>
      </Dialog>

      <Dialog open={dialogVisible} fullWidth maxWidth="sm">
        <div className={classes.dialogContainer}>
          <div style={{fontSize: "20px", fontWeight: "bold"}}>
            {boxName}
          </div>

          <div style={{fontSize: "15px", color: "#C4C4C4"}}>
            {customerName}
          </div>

          <div className={classes.dialogTitle}>
            {t("在庫項目の更新")}
          </div>

          <div className={classes.dialogDetail}>
            {t("更新点数")}&nbsp;&nbsp;&nbsp;{t("合計")}{items.length}{t("点")}
          </div>

          <div style={{fontWeight: "bold", marginTop: "20px" }}>
            {t("洗い替え")}
          </div>

          <div style={{fontWeight: "bold", color: "#C90000", marginTop: "10px", marginBottom: "20px" }}>
            {t("登録中の在庫データを削除し、書き換えます")}
          </div>

          <div style={{marginTop: "20px"}}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setDialogVisible(false)}
              style={{width: "200px", height: "42px", marginRight: "20px"}}
            >
              {t("戻る")}
            </Button>
            <OsgButton
              onClick={(e) => {
                setIsDisabled(true)
                handleSave(e)
              }}
              disabled={isDisabled}
            >
            {t("書き換える")}
            </OsgButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CsvForm;
