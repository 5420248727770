import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router";
import authentication from "../../../utils/Auth";
import Loader from "../Loader/Loader";

export const AuthRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    (async () => {
      await authentication.logout();
      const authValue = await authentication.isAuthenticated();
      setIsAuthenticated(authValue);
    })();
  }, []);

  return (
    <div className="">
      <Route
        {...rest}
        render={(props) => {
          if (isAuthenticated === null) {
            return (
              <div className="loading-spinner">
                <Loader />
              </div>
            );
          } else if (!isAuthenticated) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    </div>
  );
};
export default AuthRoute;
