import { FormControl,Select } from "@material-ui/core";
import React from 'react';
import styled from "styled-components";


const SelectBox = styled(Select)`
  background-color: #D4E7FF;

  .icon {
    position: absolute;
    right: 0;
    height: 100%;
    width: 40px;
    color: #fff;
    font-size: 25px;
    background-color: #00559D;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
  }
`;

const OsgSelect = (props) => {
  return (
    <FormControl variant="outlined" className={props.className} style={props.style} size="small">
      <SelectBox
        native
        disabled={props.disabled}
        name={props.name}
        onChange={props.onChange}
        value={ props.value }
        defaultValue={ props.defaultValue }
        IconComponent={() => {
          return <div className="icon">▼</div>
        }}
      >
        {props.children}
      </SelectBox>
    </FormControl>
  );
};

export default OsgSelect;
