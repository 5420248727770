import React, { useEffect, useState } from 'react';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import styled from "styled-components";
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import moment from 'moment-timezone';
import Encoding from 'encoding-japanese';
import Pagination from '@material-ui/lab/Pagination';
import {
  makeStyles,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
  Button,
  Modal,
  TextField,
  InputBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .btn {
    margin-right: 20px;
    text-decoration: none
  }

  .csvBtn {
    width: 100%;
    text-align: right;
  }
`;

const OsgTab = styled(Tab)`
  height: 10px !important;
  background-color: #BEBEBE !important;
  color: #fff !important;
  font-size: 20px !important;
  border-radius: 15px 15px 0 0 !important;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "0.5vw",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "0.5vw",
    borderBottom: "solid 1px #00559D",
    wordBreak: "break-all",
  },
  tableTopupHead: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableTopupBody: {
    borderBottom: "solid 1px #00559D",
    wordBreak: "break-all",
    textAlign: "center",
  },
  forcusTab: {
    backgroundColor: '#00559D !important',
    textTransform: 'none',
  },
  tabHeight: {
    minHeight: '40px',
    marginTop: '8px',
    textTransform: 'none',
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00559D",
    marginLeft: "20px"
  },
  dialogSubTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#838383",
    marginLeft: "20px",
    marginBottom: 5
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  modalContainer: {
    borderWidth: 0,
    height: 250,
    width: 600,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
  },
  modalTextField: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 25,
  },
  textField: {
    width: 200,
    marginLeft: 30,
    marginRight: 30
  },
  pageTextField: {
    marginLeft: 30,
    paddingLeft: 10,
    backgroundColor: '#fff',
    borderTop: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderRadius: '5px 0 0 5px',
    height: 40,
    width: 70,
    verticalAlign: 'top'
  },
  pager: {
    marginTop: 10,
    display: 'flex',
  },
  uploadBtn: {
    backgroundColor: "#00559D",
    width: "100px",
    marginLeft: "20px"
  },
});

const LogPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [boxes, setBoxes] = useState(null);
  const [options, setOptions] = useState([]);
  const [boxId, setBoxId] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [logs, setLogs] = useState([]);
  const [logType, setLogType] = useState('topup');
  const [pageNum, setPageNum] = useState(0);
  const [current, setCurrent] = useState(1);
  const [value, setValue] = useState(0);
  const [movePage, setMovePage] = useState(null);
  const [getParams] = useState(qs.parse(props.location.search, { ignoreQueryPrefix: true }));
  const [from, setFrom] = useState(moment().tz('UTC').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().tz('UTC').format('YYYY-MM-DD'));
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [timezone, setTimezone] = useState('Japan');
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (getParams.box_id) {
      setValue(3);
      setBoxId(getParams.box_id);
      setLogType('error');
    }
    (async () => {
      setUser(JSON.parse(localStorage.getItem("user")));
      const companiesRes = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      setOptions(companiesRes.data);
      if (getParams.company_id) {
        setCompanyId(getParams.company_id);
        setCustomerId(getParams.company_id);

        const boxesRes = await axios.get(`boxes/customer/${ getParams.company_id }`, {
          params: {
            withCompanyGroup: true
          }
        });
        setBoxes(boxesRes.data);
      }

      if (getParams.box_id) {
        const logsRes = await axios.get(`/logs`, {
          params: {
            box_id: getParams.box_id,
            log_type: 2
          }
        });
        setLogs(logsRes.data.log)
      } else {
        setCustomerId('');
        setBoxes([]);
      }
      setIsVisible(true)
    })();
  }, []);

  const handleChangeCompany = async (event) => {
    const companyId = event.target.value;
    setBoxes(null);
    setLogs(null);
    setCompanyId(companyId);
    if (companyId) {
      const res = await axios.get(`boxes/customer/${ companyId }`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.data !== null) {
        setBoxes(res.data);
      }
    } else {
      setBoxes([]);
    }
    setLogs({});
  };

  const handleChangeBox = async (event) => {
    const editBoxId = event.target.value;
    setBoxId(editBoxId);
    if (editBoxId) {
      const selectedBox = _.find(boxes, (b) => {
        return b._id === editBoxId;
      });
      if (selectedBox.timezone_id) {
        setTimezone(selectedBox.timezone_id.value);
      } else {
        setTimezone('Japan');
      }
      handleGetLogs(logType, editBoxId);
    } else {
      setLogs({});
      setPageNum(1);
      setCurrent(1);
      setTimezone('Japan');
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleGetLogs = async (log_type, editBoxId, page = 1) => {
    setLogs(null);
    setLogType(log_type);
    setCurrent(page);
    const response = await axios.get(`/logs`, {
      params: {
        box_id: editBoxId,
        log_type: log_type === 'info' ? 1 : log_type === 'error' ? 2 : log_type === 'status' ? 3 : 4,
        page
      }
    });
    setLogs(response.data.log);
    setPageNum(response.data.pageNum);
  }

  const handlePageChanged = (_, page) => {
    handleGetLogs(logType, boxId, page);
    setMovePage(page);
  }

  const handlePageListChanged = () => {
    handleGetLogs(logType, boxId, movePage);
  }

  const handleModalOpen = () => {
    setModal(true)
  }

  const handleModalClose = () => {
    setModal(false)
  }

  const isModalDisabled = () => {
    return (!from || !to) || (moment(from) > moment(to) || logs === null || !Object.keys(logs).length || logs?.length === 0)
  }
  const isDisabled = () => {
    return (!boxId || logType === 'topup');
  }

  const checkText = (event) => {
    if (!isNaN(event.target.value)) {
      setMovePage(Number(event.target.value))
    }
  }

  const isAvailablePage = () => {
    return Number(movePage) > Number(pageNum) || Number(movePage) === 0
  }

  const translateTopupLog = (text) => {
    if (text.search(/手入力/) !== -1) {
      const splitText = text.match(/[\d]+|[^\d\s]+/g);
      console.log(splitText)
      return `${t(splitText[0])} ${t(splitText[1])}${splitText[2]} ${splitText[3]}${t(splitText[4])} ${t(splitText[5])}`
    } else {
      return t(text);
    }
  }

  const handleTopupRenewLogDownload = async (log) => {
    setIsDownloading(true);
    moment.locale('ja');
    const data = [[
      'purchase_date',
      'username',
      'company',
      'edp_no',
      'abbreviation',
      'designation',
      'number_of_flutes',
      'overall_thread_length_shank_dia',
      'grade',
      'surface_treatment',
      'category',
      'weight',
      'materials',
      'drill_hole_dia',
      'standard_price',
      'quantity',
      'reorder_point',
      'location',
      'footnotes',
      'image',
    ]];
    const createDate = moment(log.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD_HH:mm:ss');
    const fileName = `${ log.box_id.name }_${ createDate }.csv`;

    const response = await axios.get(`/logs/download/${log._id}`);
    console.log(response.data)
    response.data.log[0].items.map(row => {
      return data.push([
        moment(response.data.log[0].createdAt).tz(response.data.log[0].box_id?.timezone_id ? response.data.log[0].box_id.timezone_id.value : "Japan").format('YYYY/MM/DD HH:mm:ss'),
        response.data.log[0].user_id.username,
        response.data.log[0].user_id.company_id.name,
        row?.edp_no ? row?.edp_no : null,
        row?.abbreviation ? row?.abbreviation : null,
        row?.designation ? row?.designation : null,
        row?.number_of_flutes ? row?.number_of_flutes : null,
        row?.overall_thread_length_shank_dia ? row?.overall_thread_length_shank_dia : null,
        row?.grade ? row?.grade : null,
        row?.surface_treatment ? row?.surface_treatment : null,
        row?.category ? row?.category : null,
        row?.weight ? row?.weight : null,
        row?.materials ? row?.materials : null,
        row?.drill_hole_dia ? row?.drill_hole_dia : null,
        row?.standard_price ? row?.standard_price : null,
        row?.quantity ? row?.quantity : null,
        row?.reorder_point ? row?.reorder_point : null,
        row?.location ? row?.location : null,
        row?.remarks ? row?.remarks : null,
        row?.image ? row?.image : null,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    let csvBlob;
    if (user.lang === 'zh') {
      const BOM = "\uFEFF";
      csvBlob = new Blob([BOM + rows], { type: 'text/csv;charset=utf-8' });
    } else {
      const unicodeList = [];
      for (let i = 0; i < rows.length; i += 1) {
        unicodeList.push(rows.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
      const uInt8List = new Uint8Array(shiftJisCodeList);
      csvBlob = new Blob([uInt8List], { type: 'text/csv' });
    }
    const jsonURL = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };


  const handleTopupAppendLogDownload = async (log) => {
    setIsDownloading(true);
    moment.locale('ja');
    const data = [[
      'purchase_date',
      'username',
      'company',
      'edp_no',
      'refill_quantity',
    ]];
    const createDate = moment(log.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD_HH:mm:ss');
    const fileName = `${ log.box_id.name }_${ createDate }.csv`;

    const response = await axios.get(`/logs/download/${log._id}`);
    response.data.log[0].items.map(row => {
      return data.push([
        moment(response.data.log[0].createdAt).tz(response.data.log[0].box_id?.timezone_id ? response.data.log[0].box_id.timezone_id.value : "Japan").format('YYYY/MM/DD HH:mm:ss'),
        response.data.log[0].user_id.username,
        response.data.log[0].user_id.company_id.name,
        row?.edp_no ? row?.edp_no : null,
        row?.refill_quantity ? row?.refill_quantity : null
      ]);
    });
    console.log(data)
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    const unicodeList = [];
    for (let i = 0; i < rows.length; i += 1) {
      unicodeList.push(rows.charCodeAt(i));
    }
    const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
    const uInt8List = new Uint8Array(shiftJisCodeList);
    const csv = new Blob([uInt8List], { type: 'text/csv' });
    const jsonURL = window.URL.createObjectURL(csv);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const handleCsvDownload = async () => {
    setIsDownloading(true);
    moment.locale('ja');
    let fromDate = moment(from);
    let toDate = moment(to);
    let data = [[
      'createdAt',
      'name',
      'text'
    ]];
    if (toDate.diff(fromDate) > (24 * 60 * 60 * 30 * 1000 )) {
      alert(t('ファイルサイズを制限しています。期間は30日以内を指定してください'));
      setIsDownloading(false);
      return false;
    }
    toDate = toDate.format('YYYYMMDD');
    fromDate = fromDate.format('YYYYMMDD');

    const box = _.find(boxes, (b) => {
      return boxId === b._id
    })

    const fileName = `${ box.name }_${ fromDate }_${ toDate }.csv`;
    if (logType === 'topup') {
      data = [[
        '操作日',
        '操作者名',
        'BOX名',
        '変更内容'
      ]];
    }


    const response = await axios.get(`/logs/download`, {
      params: {
        box_id: boxId,
        log_type: logType === 'info' ? 1 : logType === 'error' ? 2 : logType === 'status' ? 3 : 4,
        from: from,
        to: to
      }
    });

    response.data.log.map(row => {
      if (logType === 'topup') {
        return data.push([
          moment(row.createdAt).tz(timezone).format('YYYY/MM/DD HH:mm:ss'),
          row?.user_id?.username,
          row?.box_id?.name,
          row.text,
        ]);
      }
      return data.push([
        moment(row.createdAt).tz(timezone).format('YYYY/MM/DD HH:mm:ss'),
        row?.box_id?.name,
        row.text,
      ]);
    });
    const rows = data.map(v => '"' + v.join('","') + '"').join('\n');
    const unicodeList = [];
    for (let i = 0; i < rows.length; i += 1) {
      unicodeList.push(rows.charCodeAt(i));
    }
    const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
    const uInt8List = new Uint8Array(shiftJisCodeList);
    const csv = new Blob([uInt8List], { type: 'text/csv' });
    const jsonURL = window.URL.createObjectURL(csv);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  }

  return <>
    <SubNavbar style={{justifyContent: 'flex-end'}}>
      { customerId != null ?
        <OsgSelect onChange={handleChangeCompany} defaultValue={ customerId } style={{width: '245px', marginRight: "20px"}}>
          <option value="">{t("企業を選択")}</option>
          {options.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
        : (
          <>
            <div style={{width: '245px', justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
          </>
        )}
      { boxId !== null && boxes !== null ?
        <OsgSelect onChange={(e) => {
          handleChangeBox(e);
        }} defaultValue={ boxId } style={{width: "245px"}}>
          <option value="">{t("BOXを選択")}</option>
          {boxes.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
        : (
          <>
            <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
              <CircularProgress size="2rem" />
            </div>
          </>
        )}
    </SubNavbar>
    <SubNavbar>
      { isVisible ?
        <div className="csvBtn">
          <OsgButton style={{width: '245px',}} onClick={() => handleModalOpen()} disabled={isDisabled()}>
            {t("ログのダウンロード")}
          </OsgButton>
        </div>
        : null }
    </SubNavbar>

    <Modal open={modal} className={classes.modal} onClose={() => handleModalClose()}>
      <div className={classes.modalContainer}>
        <div style={{textAlign: "right"}}>
          <Button onClick={() => handleModalClose()}>
            {t("閉じる")}
          </Button>
        </div>
        <div className={classes.modalTextField}>
          <TextField
            id="date"
            label="from"
            type="date"
            defaultValue={moment().format('YYYY-MM-DD')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setFrom(e.target.value)}
          />
          <TextField
            id="date"
            label="to"
            type="date"
            defaultValue={moment().format('YYYY-MM-DD')}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setTo(e.target.value)}
          />
        </div>
        <div style={{textAlign: "center", marginTop: 50}}>
          {isDownloading ?
            <CircularProgress />
          : (
            <OsgButton onClick={handleCsvDownload} disabled={isModalDisabled()}>
              {t("ログのダウンロード")}
            </OsgButton>
          )}
        </div>
      </div>
    </Modal>

    <div style={{paddingBottom: "5px"}}>TimeZone: Japan ({timezone})</div>
    <Tabs
      value={value}
      onChange={handleChangeTab}
      indicatorColor="primary"
    >
      <OsgTab label={t("在庫操作")} classes={{selected: classes.forcusTab, root: classes.tabHeight}} onClick={() => handleGetLogs('topup', boxId)} />
      <OsgTab label={`${t("操作ログ")} (iPad)`} classes={{selected: classes.forcusTab, root: classes.tabHeight}} onClick={() => handleGetLogs('info', boxId)} />
      <OsgTab label={`${t("稼働状況")} (iPad)`} classes={{selected: classes.forcusTab, root: classes.tabHeight}} onClick={() => handleGetLogs('status', boxId)} />
      <OsgTab label={`${t("エラー")} (iPad)`} classes={{selected: classes.forcusTab, root: classes.tabHeight}} onClick={() => handleGetLogs('error', boxId)} />
    </Tabs>
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="company table">
          {(logs !== null && logs) ? (
            <>
              {logType === 'topup' ? (
                <>
                  <TableHead>
                    <TableCell className={classes.tableTopupHead} style={{backgroundColor: "#fff", width:200}}>{t('操作日')}</TableCell>
                    <TableCell className={classes.tableTopupHead} style={{backgroundColor: "#D4E7FF", width:220}}>{t('操作者名')}</TableCell>
                    <TableCell className={classes.tableTopupHead} style={{backgroundColor: "#fff"}} width="16%">{t('BOX名')}</TableCell>
                    <TableCell className={classes.tableTopupHead} style={{backgroundColor: "#D4E7FF"}} width="16%">{t('変更内容')}</TableCell>
                    <TableCell className={classes.tableTopupHead} style={{backgroundColor: "#fff"}} width="16%">{t('CSVダウンロード')}</TableCell>
                  </TableHead>
                  {_.map(logs, (row) => (
                    <TableBody key={row._id}>
                      <TableRow>
                        <TableCell className={classes.tableTopupBody} style={{backgroundColor: "#fff", width:200}}>{ moment(row.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD HH:mm:ss') }
                          ({moment(row.createdAt).tz(timezone).format('HH:mm:ss')})
                        </TableCell>
                        <TableCell className={classes.tableTopupBody} style={{backgroundColor: "#D4E7FF", width:220}}>{ row?.user_id?.username }</TableCell>
                        <TableCell className={classes.tableTopupBody} style={{backgroundColor: "#fff"}} width="16%">{ row?.box_id?.name }</TableCell>
                        <TableCell className={classes.tableTopupBody} style={{backgroundColor: "#D4E7FF"}} width="16%">{ translateTopupLog(row.text) }</TableCell>
                        <TableCell className={classes.tableTopupBody} style={{backgroundColor: "#fff"}} width="16%">
                          {(row.text.search(/手入力/) === -1) ?
                            <Button onClick={ () => {
                              if (row.text === "在庫品目 CSV更新") {
                                handleTopupRenewLogDownload(row)
                              } else {
                                handleTopupAppendLogDownload(row)
                              }
                            }} style={{textDecoration: 'underline'}}>
                              {t('ダウンロード')}
                            </Button>
                          : null}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </>
              ) : <>
                {_.map(logs, (row) => (
                  <TableBody key={row._id}>
                    <TableRow>
                      <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF", width:200}}>{ moment(row.createdAt).tz("Asia/Tokyo").format('YYYY/MM/DD HH:mm:ss') }
                      ({moment(row.createdAt).tz(timezone).format('HH:mm:ss')})
                      </TableCell>
                      <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF", width:220}}>{ row?.box_id?.name }</TableCell>
                      <TableCell className={classes.tableBody} style={{backgroundColor: "#fff"}} width="50%">{ row.text }</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </>}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
      </Table>
      {(logs !== null && logs && pageNum > 1) ? (
        <div className={classes.pager}>
          <Pagination
            count={pageNum}
            page={current}
            showFirstButton
            showLastButton
            boundaryCount={2}
            onChange={handlePageChanged}
          />
          <div>
            <InputBase className={classes.pageTextField} value={movePage} onChange={(e) => checkText(e)} /> / {pageNum}
            <Button
              color="primary"
              className={classes.uploadBtn}
              variant='contained'
              disabled={isAvailablePage()}
              onClick={() => handlePageListChanged()}
            >
              {t('移動する')}
            </Button>
          </div>
        </div>
      ) : null}
    </TableContainer>
  </>;
};

export default LogPage;
