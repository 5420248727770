import React, { useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Typography,
  Button,
  // CircularProgress,
  // Checkbox,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import { I18n } from "@aws-amplify/core";
import useFormValidation from "../../CustomHooks/useFormValidation";
import { ValidateSignUpRegister } from "./validateSignUp";
// import { makeStyles } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";
// import { RegisterUserSignup } from "../../../graphql/mutations";
// import gql from "graphql-tag";
// import { clientRegister } from "../../../config/amplify-config";
import Loader from "../../Common/Loader/Loader";
import { Trans, useTranslation } from 'react-i18next';

const Div = styled.div`
  padding: 24px;
`;

const SignupRegistrationForm = styled.div`
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextFieldWithLabel = styled.div`
  width: 100%;
  .textFieldWithLabel {
    width: 100%;
  }
`;

const InputEmail = styled(TextField)`
  width: 100%;
`;

const TryButton = styled(Button)`
  color: white !important;
  height: 40px;
  width: 100%;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(0deg, #f38200, #f3a800);
  align-self: center;
  &:disabled {
    background: lightgray;
  }
`;

const Login = styled.div`
  padding-top: 16px;
  text-align: end;

  .linkSignIn {
    color: #ff9900;
    text-decoration: underline;
  }
`;

const INITIAL_STATE = {
  email: "",
};

// const useStyles = makeStyles({
//   root: {
//     minWidth: 275,
//   },
//   bullet: {
//     display: "inline-block",
//     margin: "0 2px",
//     transform: "scale(0.8)",
//   },
//   heading: {
//     fontSize: 25,
//   },
//   title: {
//     fontSize: 14,
//   },
//   pos: {
//     marginBottom: 12,
//   },
// });
const SignUpView = (props) => {
  const [t] = useTranslation();
  // const dispatch = useDispatch();
  const [isLoading, toggleIsLoading] = useState(false);
  // const [formSubmit, setFormSubmit] = useState(false);
  let formSubmit = false;
  // const [authError, setAuthError] = useState("");
  let authError = '';
  // const [isTermsAgreed, toggleTermsAgreed] = useState(false);
  let isTermsAgreed = false;
  // const classes = useStyles();
  // const handleTermsAgreeChange = (e) => {
  //   toggleTermsAgreed(e.target.checked);
  // };
  const initiateSignUpRegister = (values) => {
    // const { email } = values;

    toggleIsLoading(true);

    // clientRegister
    //   .mutate({
    //     mutation: gql(RegisterUserSignup),
    //     variables: {
    //       email,
    //     },
    //   })
    //   .then((data) => {
    //     const response = data.data.registerUserSignup.toLowerCase();
    //     if (
    //       response.indexOf("error") !== -1 ||
    //       response.indexOf("failed") !== -1 ||
    //       response.indexOf("already") !== -1
    //     ) {
    //       setAuthError(data.data.registerUserSignup);
    //       toggleIsLoading(false);
    //       setFormSubmit(false);
    //     } else {
    //       toggleIsLoading(false);
    //       setFormSubmit(true);
    //     }
    //   })
    //   .catch((err) => {
    //     toggleIsLoading(false);
    //     setAuthError(err.message ? err.message : "Something went wrong");
    //   });
  };

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    // isSubmitting,
  } = useFormValidation(
    INITIAL_STATE,
    ValidateSignUpRegister,
    initiateSignUpRegister
  );

  function renderForm() {
    return (
      <SignupRegistrationForm>
        <Form onSubmit={handleSubmit}>
          <TextFieldWithLabel>
            <Typography>{t('メールアドレス')}</Typography>
            <InputEmail
              placeholder={t('メールアドレスを入力してください')}
              name="email"
              variant="outlined"
              className={`textField ${errors.email ? "error-box" : ""}`}
              value={values.email}
              onChange={handleChange}
              margin="dense"
            />
            <Typography>
              <span className="error-text">
                {errors.email ? errors.email : ""}
              </span>
            </Typography>
          </TextFieldWithLabel>
          <div className="errorMessageContenair">
            <Typography className="errorMessage" variant="subtitle2">
              {authError}
            </Typography>
          </div>
          <TryButton type="submit" disabled={!isTermsAgreed}>
            {t('登録')}
          </TryButton>
        </Form>
        <Login>
          <Link to="/login" className="linkSignIn">
            {t('ログイン')}
          </Link>
        </Login>
      </SignupRegistrationForm>
    );
  }

  function renderConfirmation() {
    return (
      <div className="signupRegistrationSent">
        <div className="registrationSent">
          <Typography>
            <span className="info-text">
              {t('ご入力いただいたメールアドレスに登録に必要なメールを送信しました')}
            </span>
          </Typography>
        </div>
        <div className="login">
          <Button
            className="button"
            onClick={() => props.history.push("/login")}
          >
            {t('ログイン画面へ')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth signUpPage">
      <div className="mainContainer">
        <Div className="mainContainerBox">
          <div className="logo-common" />
          {formSubmit ? renderConfirmation() : renderForm()}
        </Div>
      </div>

      {isLoading ? (
        <div className="isLoading">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};
export default SignUpView;
