import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  List,
  ListItem
} from '@material-ui/core';
import { OsgButton } from "../../Common/UIcomponents";

const useStyles = makeStyles({
  listContainer: {
    width: "100%",
  },
  forcusTab: {
    backgroundColor: '#00559D !important'
  },
  listName: {
    display: "flex",
    justifyContent: "center",
    height: "40px",
    width: "50%",
    fontWeight: "bold",
  },
  listValue: {
    padding: "0px 20px",
    height: "40px",
    width: "50%",
    marginRight: "10px",
    backgroundColor: "#F6FBFF",
  },
  csv: {
    padding: "0px 20px 30px",
  }
});

const TranslationPage = (props) => {
  const [lang, setLang] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const lang = await axios.get('langs');
      setLang(lang.data);
    })();
    /*
    const l = []
    _.map(enJson, (d, k) => {
      l.push({
        ja: k,
        en: d,
      });
    });
    setLang(l);
    */
  }, []);

  const handleChange = (index, value, langType) => {
    const l = [...lang]
    l[index][langType] = value;
    setLang(l);
  }

  const handleSubmit = async (index) => {
    try {
      const res = await axios.post('langs', {langs: [lang[index]]});
      if (res.data.success) {
        alert('変更しました');
      }
    } catch (e) {
      console.error(e);
        alert('エラーが発生しました');
    }
  }

  return (
    <>
      <List className={classes.listContainer}>
      <div className={classes.csv}>
        <OsgButton
          onClick={() => props.history.push("/translation/csv")}
        >
          CSV登録
        </OsgButton>
      </div>
      {lang.map((d, index) => {
        return <>
            <ListItem dense>
              <div className={classes.listName}>{d.ja}</div>
              <TextField className={classes.listValue} onChange={ (e) => handleChange(index, e.target.value, 'en') } value={ d.en } />
              <TextField className={classes.listValue} onChange={ (e) => handleChange(index, e.target.value, 'zh') } value={ d.zh } />
              <Button onClick={ () => handleSubmit(index) } variant="outlined" color="primary" style={{width: "100px"}}>更新</Button>
            </ListItem>
        </>
      })}
      </List>
    </>
  );
};

export default TranslationPage;
