
const ValidateForgotPassword = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "*メールアドレスを入力してください";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "*メールアドレスが正しくありません";
    }
    return errors;
  };

export default ValidateForgotPassword;
