class Authentication {
  async isAuthenticated() {
    const authToken = localStorage.getItem('auth-token');
    return authToken === null ? false: true;
  }

  logout = async () => {
    console.info('logout')
    localStorage.removeItem("auth-token");
    localStorage.removeItem("user");
    return true;
  };
}

const authentication = new Authentication();
export default authentication;
