import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OsgSelect } from "../../Common/UIcomponents";
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import styled from "styled-components";
import axios from 'axios';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { OsgButton } from "../../Common/UIcomponents";

const Details = styled.div`
  display: flex
`;

const useStyles = makeStyles({
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "40%",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#00559D",
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0
  },
  listAgentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "40%",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#00559D",
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0
  },
  listAgentText: {
    padding: "0px 20px",
    height: "40px",
    width: "60%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  listAgentNoEditableText: {
    padding: "0px 20px",
    width: "60%",
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#F6FBFF"
  },
  listOsgTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "40%",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#00559D",
    flexBasis: "40%",
    flexShrink: 0,
    flexGrow: 0
  },
  listOsgText: {
    padding: "0px 20px",
    width: "60%",
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#F6FBFF"
  },
  listText: {
    padding: "0px 20px",
    height: "40px",
    width: "60%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  listNoEditableText: {
    padding: "0px 20px",
    width: "55%",
    display: "flex",
    alignItems: "center",
    height: "40px",
    marginTop: "0",
    marginBottom: "0",
    backgroundColor: "#F6FBFF",
    flexBasis: "55%",
    flexShrink: 0,
    flexGrow: 0
  },
  subTitle: {
    fontWeight: "bold",
    marginRight: "auto",
    marginBottom: "10px",
  },
  valueText: {
    paddingTop: "10px",
    height: "30px",
    width: "50%",
    marginTop: "0",
    textAlign: "center",
    backgroundColor: "#F6FBFF",
  },
  editContainer: {
    display: "flex",
    alignItems: "start",
    width: "100%",
  },
  removeContainer: {
    // display: "flex",
    // alignItems: "end",
    width: "60%",
  },
  otherSettingContainer: {
    marginLeft: "15px",
  },
  deleteContainer: {
    display: "flex",
    alignItems: "start",
    width: "100%",
    marginTop: "20px",
  },
  dialogListTitle: {
    width: "40%",
  },
  dialogListText: {
    width: "60%"
  },
  deleteConfirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "50px",
  },
  deleteConfirmDialogTitle: {
    textAlign: "center",
    color: "#C90000",
    fontSize: "20px",
    fontWeight: "bold"
  },
  deleteConfirmUserName: {
    textAlign: "center",
    fontWeight: "bold"
  },
  deleteConfirmDescription: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  deleteConfirmButton: {
    color: "#C90000",
    marginLeft: "10px",
  },
  deleteButton: {
    background: "#C90000",
    fontWeight: "bold",
    width: "200px",
    height: "42px",
    marginRight: "20px",
    '&:hover': {
      background: '#C90000'
    }
  },
  backButton: {
    width: "200px",
    height: "42px",
    marginRight: "20px",
  }
});

const UserEditPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [userId, setUserId] = useState('');
  const [user, setUser] = useState({});
  const [editUser, setEditUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [chargeCompany, setChargeCompany] = useState(null);
  const [values, setValues] = useState({
    username: '',
    email: '',
    is_admin: false,
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editable, setEditable] = useState(false);
  const [deletable, setDeletable] = useState(false);

  useEffect(() => {
    (async () => {
      const userId = props.match.params.id;
      const user = JSON.parse(localStorage.getItem('user'));
      setEditUser(user);
      setUserId(userId);
      try {
        // 操作者の編集権限確認
        let res = await axios.get(`/users/editable/${ userId }`);
        setEditable(res.data.editable);
        res = await axios.get(`/users/${userId}`);
        if (user.company_id.is_osg && user.is_admin && userId !== user._id && res.data.email !== 'gharada@osg.co.jp') {
          setDeletable(true);
        }
        setUser(res.data);
        setValues({
          username: res.data.username,
          email: res.data.email,
          is_admin: res.data.is_admin,
        });

        if (res.data.company_id?.is_osg) {
          const companies = await axios.get('/companies/agents');
          setCompanies(companies.data);
          setFilterCompanies(companies.data.filter(company => !res.data.permissions?.agents.find(agent => company._id === agent._id)));
        }
        if (res.data.company_id?.is_agent) {
          const companies = await axios.get(`/companies/customers/${ res.data.company_id._id }`);
          setCompanies(companies.data);
          setFilterCompanies(companies.data.filter(company => !res.data.permissions?.customers.find(agent => company._id === agent._id)));
        }
        if (res.data.company_id?.is_customer) {
          const boxes = await axios.get(`/boxes/customer/${ res.data.company_id._id }`);
          boxes.data.map(box => {
            box.optionAdmin = box.box_roles.filter(v => v.is_admin && v.is_customer)[0]?._id;
            box.optionNormal = box.box_roles.filter(v => !v.is_admin && v.is_customer)[0]?._id;
            box.targetOption = res.data.permissions.boxes.filter(v => v.box_id === box._id )[0]?.boxrole_id;
          });
          setBoxes(boxes.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleResendInvitation = async () => {
    try {
      const res = await axios.post('/users/resend_invitation', { id: user._id, company_id: user.company_id._id});
      if (res.data.success) {
        alert('招待メールを再送しました');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetBox = async (e, box_id) => {
    const params = {
      box_id: box_id,
      boxrole_id: e.target.value,
    };
    // 0 は使用不可
    if (e.target.value == 0) {
      const filterBoxes = user.permissions.boxes?.filter(v => v.box_id !== box_id);
      user.permissions.boxes = filterBoxes;
      const newBoxes = boxes.map(box => {
        if (box._id === box_id) {
          box.targetOption = '0';
        }
        return box;
      })
      setBoxes(newBoxes)
      await axios.put(`/users/${ user._id }`, { permissions: user.permissions });
    } else {
      const box = user.permissions.boxes?.filter(v => v.box_id === box_id);
      if (box.length === 0) {
        // 新規
        user.permissions.boxes.push(params);
      } else {
        // 変更
        const filterBoxes = user.permissions.boxes?.filter(v => v.box_id !== box_id);
        filterBoxes.push(params);
        user.permissions.boxes = filterBoxes;
      }
      const newBoxes = boxes.map(box => {
        if (box._id === box_id) {
          box.targetOption = user.permissions.boxes.filter(v => v.box_id === box_id )[0]?.boxrole_id;
        }
        return box;
      })
      setBoxes(newBoxes)
      await axios.put(`/users/${ user._id }`, { permissions: user.permissions });
    }
  };

  const handleSetBoxPermission = async (id, value) => {
    // エージェントのBoxRoleは閲覧か非担当しかないためboxRoleIdは不要
    let newBoxes;
    if (value === '1') {
      // 補充者
      user.permissions.boxes.push({
        box_id: id,
      });
    } else {
      // 非補充者
      newBoxes = user.permissions.boxes.filter(v => {
        return v.box_id != id;
      });
      user.permissions.boxes = newBoxes;
    }
    const res = await axios.put(`/users/${ userId }`, { permissions: user.permissions });
    if (res.data.success) {
      const res = await axios.get(`/users/${ userId }`);
      setFilterCompanies(companies.filter(company => !res.data.permissions?.customers.find(agent => company._id === agent._id)));
      setUser(res.data);
    }
  };

  const handleAddCharge = async () => {
    const companyId = chargeCompany === null ? companies[0]._id : chargeCompany._id;

    if (user.permissions.customers.filter(v => v._id == companyId).length >= 1) {
      alert(t('すでに登録されています'));
      return;
    }
    user.permissions.customers.push(companyId);
    const r = await axios.get(`/boxes/customer/${ companyId }`);
    r.data.map(v => user.permissions.boxes.push({ box_id: v._id}));

    const res = await axios.put(`/users/${ userId }`, { permissions: user.permissions });
    if (res.data.success) {
      const res = await axios.get(`users/${ userId }`);
      setFilterCompanies(companies.filter(company => !res.data.permissions?.customers.find(customer => company._id === customer._id)));
      setUser(res.data);
    }
  };

  const handleAddChargeAgent = async () => {
    if (chargeCompany === null) {
      alert(t('企業を選択してください'));
      return;
    }
    const companyId = chargeCompany._id;

    if (user.permissions.agents.filter(v => v._id === companyId).length >= 1) {
      alert(t('すでに登録されています'));
      return;
    }
    user.permissions.agents.push(companyId);
    const res = await axios.put(`/users/${ userId }`, { permissions: user.permissions });
    if (res.data.success) {
      const res = await axios.get(`users/${ userId }`);
      setFilterCompanies(companies.filter(company => !res.data.permissions?.agents.find(agent => company._id === agent._id)));
      setUser(res.data);
    }
  };

  const handleRemoveAgent = async (id) => {
    const newagents = user.permissions.agents.filter(v => v._id !== id);
    user.permissions.agents = newagents;
    const res = await axios.put(`/users/${ userId }`, { permissions: user.permissions });
    if (res.data.success) {
      const res = await axios.get(`users/${ userId }`);
      setFilterCompanies(companies.filter(company => !res.data.permissions?.agents.find(agent => company._id === agent._id)));
      setUser(res.data);
    }
  };

  const handleRemoveCharge = async (id) => {
    const r = await axios.get(`/boxes/customer/${ id }`);
    const boxes = _.map(r.data, '_id');
    const permission = user.permissions.boxes
    const newBoxes = permission.filter(v => !boxes.includes(v.box_id));
    user.permissions.boxes = newBoxes;

    const newCustomers = user.permissions.customers.filter(v => v._id !== id);
    user.permissions.customers = newCustomers;
    const res = await axios.put(`/users/${ userId }`, { permissions: user.permissions });
    if (res.data.success) {
      const res = await axios.get(`users/${ userId }`);
      setFilterCompanies(companies.filter(company => !res.data.permissions?.customers.find(agent => company._id === agent._id)));
      setUser(res.data);
    }
  };

  const handleSetCompany = e => {
    const index = _.findIndex(companies, v => v._id === e.target.value);
    setChargeCompany(companies[index]);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!values.email || !values.email.match(/^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,18})?$/)) {
      alert(t('メールアドレスを入力してください'));
    } else {
      const res = await axios.put(`/users/${ userId }`, values);
      if (res.data.success) {
        alert(t('編集しました'));
        props.history.push("/users");
      } else if (res.data.error) {
        alert(t(res.data.error));
      }
    }
  };

  const handleDelete = async () => {
    setShowDeleteDialog(false);
    const res = await axios.delete(`/users/${userId}`, values);
    if (res.data.success) {
      alert(t('削除しました'));
    }
    props.history.push("/users");
  };

  return (
    <>
      <Details>
        <Dialog
          open={ showDeleteDialog }
          onClose={ () => setShowDeleteDialog(false) }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{fontWeight: "bold"}}>{ '本当にアカウントを削除しますか？' }</span>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete}>
              <span style={{color: "#C90000", fontWeight: "bold"}}>アカウントを削除</span>
            </Button>
            <Button onClick={ () => setShowDeleteDialog(false)} autoFocus>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showDeleteDialog} fullWidth maxWidth="sm">
          <div className={classes.deleteConfirmDialogContainer}>
            <div className={classes.deleteConfirmDialogTitle}>
              {t("ユーザー削除")}
            </div>
            <div className={classes.deleteConfirmUserName}>
              {t("対象ユーザー")}:&nbsp;&nbsp;{user.username}
            </div>

            <div className={classes.deleteConfirmDescription}>
              {t("ユーザー削除をすると、該当ユーザーは今後システムを利用できなくなります。")}
            </div>

            <div className={classes.deleteConfirmDescription}>
              {t("本当に削除しますか？")}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="outlined"
                onClick={ () => setShowDeleteDialog(false)}
                className={classes.backButton}
              >
                {t("戻る")}
              </Button>
              <Button
                className={classes.deleteButton}
                color="primary"
                variant="contained"
                onClick={handleDelete}
              >
                {t("削除する")}
              </Button>
            </div>
          </div>
        </Dialog>
        <List style={{marginBottom: '20px',minWidth: "800px"}}>
          <ListItem dense>
            <ListItemText className={classes.listTitle} primary={t("氏名")} />
            {editable
              ?
                <TextField name="username" id="standard-basic" className={classes.listText} onChange={ handleChange } value={ values.username } />
              :
                <ListItemText className={classes.listNoEditableText} primary={values.username} />
            }
          </ListItem>
          <ListItem dense>
            <ListItemText className={classes.listTitle} primary={t("メールアドレス")} />
            {editable
              ?
                <TextField name="email" id="standard-basic" className={classes.listText} onChange={ handleChange } value={ values.email } />
              :
                <ListItemText className={classes.listNoEditableText} primary={values.email} />
            }
          </ListItem>
          <ListItem dense>
            <ListItemText className={classes.listTitle} primary={t("企業権限")} />
            {editable
              ?
                <OsgSelect name="is_admin" className={classes.listText} onChange={ handleChange } value={ values.is_admin } disabled={ !editable }>
                  <option key="0" value={ false }>{t("一般")}</option>
                  <option key="1" value={ true }>{t("管理者")}</option>
                </OsgSelect>
              :
                <ListItemText className={classes.listNoEditableText} primary={values.is_admin ? t("管理者") : t("一般") } />
            }
          </ListItem>
        </List>
        { editable ?
          <div className={classes.editContainer}>
            <Button color="primary" onClick={ handleSubmit }> {t("更新")} </Button>
            { user.status === 1 ?
              <Button color="primary" onClick={handleResendInvitation}>{t("招待メール再送")}</Button>
            : null }
          </div>
        : null }
      </Details>

      { (user.company_id?.is_osg && !user.is_admin) ?
        <Details>
          <List style={{marginBottom: '20px', minWidth: "800px"}}>
            <ListItem dense>
              <h2>{t("担当するBOX管理企業")}</h2>
            </ListItem>
            { user.permissions?.agents?.map((company, index) => {
              return (
                <div key={ index } style={{marginBottom: 10}}>
                  <ListItem dense>
                    <ListItemText className={classes.listOsgTitle} primary={company.name } />
                    <ListItemText className={classes.listOsgText} primary={t('担当')}/>
                    { editable ?
                      <div className={classes.removeContainer}>
                        <Button color="primary" onClick={ () => handleRemoveAgent(company._id) }>{t("企業担当から外す")}</Button>
                      </div>
                    : null }
                  </ListItem>
                </div>
              )
            })}
            { editable && <ListItem dense>
              <div className={classes.editContainer}>
                <OsgSelect onChange={ handleSetCompany } style={{minWidth: "40%"}} disabled={ !editable }>
                  <option value=''>{t('担当する商社を選択してください')}</option>
                  { filterCompanies.map(company => (
                    <option key={ company._id } value={ company._id }>{ company.name }</option>
                  ))}
                </OsgSelect>
                { editable ?
                  <OsgButton onClick={ handleAddChargeAgent } style={{marginLeft: 10}}>
                    {t("担当商社を追加")}
                  </OsgButton>
                : null }
              </div>
            </ListItem>}
          </List>
        </Details>
        : null
      }

      { user.company_id?.is_agent ?
        <Details>
          <List style={{marginBottom: '20px', minWidth: "800px"}}>
            <ListItem dense>
              <h2>{t("担当するBOX設置企業")}</h2>
            </ListItem>
            {user.is_admin ? <>
              { user.company_id?.customers?.map((company, index) => {
                return (
                  <div key={ index } style={{marginBottom: '16px'}}>
                    <ListItem dense>
                      <div style={{fontWeight: "bold", marginRight: "auto", color: "#00559D"}}>{ company.name }</div>
                      {editable && user.company_id.is_agent && !user.is_admin ?
                        <div>
                          <Button color="primary" onClick={ () => handleRemoveCharge(company._id) }>{t("企業を外す")}</Button>
                        </div>
                      : null }
                    </ListItem>
                    {editable && user.company_id.is_agent && !user.is_admin ?
                      <>
                        {typeof company === 'object' ?
                          <>
                            { company.boxes.map((box, index) => {
                              const value = user.permissions.boxes.filter(v => v.box_id === box._id).length >= 1 ? 1 : 0;

                              return <div className={classes.editContainer} style={{width: "100%"}}>
                                <ListItem dense>
                                  <ListItemText className={classes.listAgentTitle} primary={box.name } />
                                  <OsgSelect
                                    className={classes.listAgentText}
                                    onChange={ (e) => handleSetBoxPermission(box._id, e.target.value) }
                                    value={ value }
                                  >
                                    <option key={ 1 } value="1">{t("補充者")}</option>
                                    <option key={ 0 } value="0">{t("Box非担当")}</option>
                                  </OsgSelect>
                                </ListItem>
                              </div>
                            })}
                          </>
                        : null}
                      </>
                    :
                      <>
                        { company.boxes.map((box, index) => {
                          return <ListItem key={ index } dense>
                            <ListItemText className={classes.listTitle} primary={box.name } />
                            <ListItemText className={classes.listNoEditableText} primary={t("補充者")}/>
                          </ListItem>
                        })}
                      </>
                    }
                  </div>
                )
              })}
            </> : <>
              { user.permissions?.customers?.map((company, index) => {
                return (
                  <div key={ index } style={{marginBottom: '16px'}}>
                    <ListItem dense>
                      <div style={{fontWeight: "bold", marginRight: "auto", color: "#00559D"}}>{ company.name }</div>
                      {editable && user.company_id.is_agent && !user.is_admin ?
                        <div>
                          <Button color="primary" onClick={ () => handleRemoveCharge(company._id) }>{t("企業を外す")}</Button>
                        </div>
                      : null }
                    </ListItem>
                    {editable && user.company_id.is_agent && !user.is_admin ?
                      <>
                        {typeof company === 'object' ?
                          <>
                            { company.boxes.map((box, index) => {
                              const value = user.permissions.boxes.filter(v => v.box_id === box._id).length >= 1 ? 1 : 0;

                              return <div className={classes.editContainer} style={{width: "100%"}}>
                                <ListItem dense>
                                  <ListItemText className={classes.listAgentTitle} primary={box.name } />
                                  <OsgSelect
                                    className={classes.listAgentText}
                                    onChange={ (e) => handleSetBoxPermission(box._id, e.target.value) }
                                    value={ value }
                                  >
                                    <option key={ 1 } value="1">{t("補充者")}</option>
                                    <option key={ 0 } value="0">{t("Box非担当")}</option>
                                  </OsgSelect>
                                </ListItem>
                              </div>
                            })}
                          </>
                        : null}
                      </>
                    :
                      <>
                        { company.boxes.map((box, index) => {
                          return <ListItem key={ index } dense>
                            <ListItemText className={classes.listTitle} primary={box.name } />
                            <ListItemText className={classes.listNoEditableText} primary={t("補充者")}/>
                          </ListItem>
                        })}
                      </>
                    }
                  </div>
                )
              })}
            </>}
            { (!user.is_admin && editable) && <ListItem dense>
              <div className={classes.editContainer}>
                <OsgSelect onChange={ handleSetCompany } style={{minWidth: "40%"}} disabled={ !editable }>
                  <option value=''>{t('担当企業を選択してください')}</option>
                  { filterCompanies.map(company => (
                    <option key={ company._id } value={ company._id }>{ company.name }</option>
                  ))}
                </OsgSelect>
                { editable ?
                  <OsgButton onClick={ handleAddCharge } style={{marginLeft: 20}}>
                    {t("担当を追加")}
                  </OsgButton>
                : null }
              </div>
            </ListItem>}
          </List>
        </Details>
        : null
      }

      { user.company_id?.is_customer && String(values.is_admin) === 'false' ?
        <Details>
          <List style={{marginBottom: '20px',minWidth: "800px"}}>
            <ListItem dense>
              <div className={classes.subTitle}>{ user.company_id.name }</div>
            </ListItem>
            { boxes.map(box => {
              return (
                <div key={ box._id }>
                  <ListItem dense>
                    <ListItemText className={classes.listTitle} primary={box.name} />
                    {(editable) ?
                      <OsgSelect
                        onChange={ (e) => handleSetBox(e, box._id) }
                        value={ box.targetOption }
                        className={classes.listText}
                        style={{width: "60%"}}
                      >
                        <option key="0" value="0">{t("使用不可")}</option>
                        <option key="1" value={box.optionAdmin}>{t("自社購入履歴全閲覧 + 購入者")}</option>
                        <option key="2" value={box.optionNormal}>{t("購入者")}</option>
                      </OsgSelect>
                    :
                      <ListItemText
                        className={classes.listNoEditableText}
                        primary={(box.targetOption === box.optionAdmin) ? t("自社購入履歴全閲覧 + 購入者") : (box.targetOption === box.optionNormal) ? t("購入者") : t("使用不可")}
                      />
                    }
                  </ListItem>
                </div>
              )
            })}
          </List>
        </Details>
        : null
      }
      { deletable ?
        <div className={classes.deleteContainer}>
          <Button onClick={ () => setShowDeleteDialog(true)}>
            <span className={classes.deleteConfirmButton}>
              {t("このユーザーを削除")}
            </span>
          </Button>
        </div>
      : null }
    </>
  );
};

export default UserEditPage;
