import '../App.scss';
import { BrowserRouter } from "react-router-dom";
import history from "../utils/history";
import MainRouter from "./MainRouter";
import axios from 'axios'
// import enJson from '../locales/en.json';
import jaJson from '../locales/ja.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { UserProvider } from "../contexts/UserContext";

function App() {
  const token = localStorage.getItem("auth-token");
  const user = JSON.parse(localStorage.getItem("user"));
  const query = document.location.search.substring(1);
  const parameters = query.split('&');
  let params = new Object();
  for (let i = 0; i < parameters.length; i++) {
    let element = parameters[i].split('=');
    let paramName = decodeURIComponent(element[0]);
    let paramValue = decodeURIComponent(element[1]);
    params[paramName] = decodeURIComponent(paramValue);
  }
  const lang = params?.lang ? params.lang : user !== null ? user.lang : 'ja';
  i18n.use(initReactI18next).init({
    debug: false,
    resources: {
      zh: { translation: window.zhJson },
      en: { translation: window.enJson },
      ja: { translation: jaJson },
    },
    lng: 'ja',
    fallbackLng: 'ja',
    returnEmptyString: false,
    interpolation: { escapeValue: false },
  });
  i18n.changeLanguage(lang);

  // if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  //   axios.defaults.baseURL = 'https://api.monolithbox.com'
  // }else if(process.env.REACT_APP_ENVIRONMENT === 'development') {
  //   axios.defaults.baseURL = 'https://osg-api.ankr.dev'
  // } else{
  //   axios.defaults.baseURL = 'http://localhost:3001';
  // }
  axios.defaults.headers.common['x-access-token'] = token;

  axios.interceptors.response.use(response => {
    if (!response?.data?.success && response?.data?.message === 'Invalid token') {
      history.push("/signin");
      history.go("/signin");
      return;
    }
    return response
  })
  document.title = (process.env.REACT_APP_ENVIRONMENT === 'production' ?  '' : '[開発環境]') + 'MONOlithbox' ;
  return (
    <div>
      <UserProvider>
        <BrowserRouter history={history}>
          <MainRouter />
        </BrowserRouter>
      </UserProvider>
      {/*<Loading />*/}
    </div>
  );
}

export default App;
