export const ValidateSignUp = (values) => {
  let errors = {};
  // for familyName
  if (!values.familyName) {
    errors.familyName ="*「姓」を入力してください";
  }

  // for firstName
  if (!values.firstName) {
    errors.firstName ="*「名」を入力してください";
  }
  // for Organisation
  // if (!values.organization) {
  //   errors.organization = "*Organization cannot be empty";
  // }
  // for password
  if (!values.password) {
    errors.password ="*パスワードを入力してください";
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword ="*パスワードが一致しません。";
  }

  return errors;
};

export const ValidateSignUpRegister = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email ="*メールアドレスを入力してください";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email ="無効なメールアドレスです";
  }

  return errors;
};

export const ValidateResetPassword = (values) => {
  let errors = {};

  if (!values.newPassword) {
    errors.newPassword ="*パスワードを入力してください";
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword ="*パスワードが一致しません。";
  }
  return errors;
};
