import React, { useEffect, useState } from "react";
import axios from "axios";
// import { makeStyles } from "@material-ui/core/styles";
import LoginTitle from "./Title";
import styled from "styled-components";
import { TextField} from "@material-ui/core";
import { OsgButton } from "../../Common/UIcomponents";
import { Trans, useTranslation } from 'react-i18next';
import Loader from "../../Common/Loader/Loader";
import i18n from 'i18next';

const Div = styled.div`
  padding: 10px 50px;
`;

const SubLoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #00559D;
  font-size: 1.5em;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextFieldWithLabel = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-top: 16px;
  margin-bottom: 5px;
  .textField {
    width: 80%;
  }
  .formLabel {
    align-items: center;
    width: 35%;
    font-weight: bold;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;

    span {
      color: #8B8C8E;
      font-weight: normal;
      font-size: 0.8em;
    }
  }
`;

// const useStyles = makeStyles({
// });

const VerifyPage = (props) => {
  const [t] = useTranslation();
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(null);
  const token = props.match.params.token;
  const lang = props.location.search.split('=')[1] ? props.location.search.split('=')[1] : 'ja';

  useEffect(() => {
    (async () => {
      const res = await axios.get(`/users/verify/${token}`);
      setStatus(res.data.status);
    })();
  }, [token]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const handleSubmit = async () => {
    const res = await axios.post('/users/verify',{
      password: password,
      token: token
    });
    if (res.data.success) {
      alert(t('パスワードを設定しました'));
      props.history.push(`/signin`);
    }
  };
  return (
    <Div>
      <LoginTitle></LoginTitle>
      {(status === 2) ? (
        <SubLoginTitle>{t('既に認証されています')}</SubLoginTitle>
      ) : (status === 1) ? (
        <>
          <Form>
            <SubLoginTitle>{t('パスワードの設定')}</SubLoginTitle>
            <TextFieldWithLabel>
              <label className="formLabel">
                <div>
                  Password<br />
                  <span>&lt;{t('半角英数字')}&gt;</span>
                </div>
              </label>
              <TextField
                placeholder={t("パスワードを入力してください")}
                className="textField"
                variant="outlined"
                name="password"
                type="password"
                onChange={ (e) => setPassword(e.target.value) }
                margin="dense"
              />
            </TextFieldWithLabel>
            <div style={{marginTop: '50px'}}>
              <OsgButton onClick={ handleSubmit }>{t('登録')}</OsgButton>
            </div>
          </Form>
        </>
      ) : (
        <div className="isLoading">
          <Loader />
        </div>)}
    </Div>
  );
};
export default VerifyPage;
