import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  CircularProgress
} from '@material-ui/core';
import styled from "styled-components";
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import AddIcon from '@material-ui/icons/Add';
import { Trans, useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .formControl {
    background-color: #fff;
  }

  .addCompanyBtn {
    margin-left: auto;
    text-decoration: none
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "@media (max-height:800px)":{
      maxHeight: "77vh",
    },
    "@media (max-height:600px)":{
      maxHeight: "70vh",
    },
    "@media (max-height:400px)":{
      maxHeight: "60vh",
    },
    maxHeight: "77vh",
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  separate: {
    borderCollapse: 'separate',
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "6px 16px 6px 16px",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableBoxHead: {
    minWidth: '300px',
    position: 'sticky',
    borderRight: 'solid 3px #CFCFCF',
    left: 0,
    fontWeight: "bold",
    fontSize: "12pt",
    padding: "6px 16px 6px 16px",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBoxBody: {
    width: '300px',
    position: 'sticky',
    borderRight: 'solid 3px #CFCFCF',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
  tableCompanyHead: {
    minWidth: '300px',
    left: 0,
    fontWeight: "bold",
    fontSize: "12pt",
    padding: "6px 16px 6px 16px",
    textAlign: "center",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableCompanyBody: {
    width: '300px',
    left: 0,
    padding: "6px 16px 6px 16px",
    fontSize: "12pt",
    wordBreak: "break-all",
    borderBottom: "solid 1px #00559D"
  },
});

const BoxPage = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [boxes, setBoxes] = useState(null);
  const [customers, setCustomers] = useState([]);
  const { customerId } = useParams();

  const user = JSON.parse(localStorage.getItem('user'));
  const boxStatus = [t('未稼働'), t('稼働中'), t('稼働停止'), t('非表示')];

  useEffect(() => {
    (async () => {
      const res = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      setCustomers(res.data);
    })();

    getBoxes(customerId);
  }, []);

  const getBoxes = async (customer_id = null) => {
    const url = customer_id === null ? '/boxes' : `/boxes/customer/${customer_id}`;
    const boxes = await axios.get(url, {
      params: {
        withCompanyGroup: true
      }
    });
    if (boxes.data == null) {
      setBoxes([]);
    } else {
      setBoxes(boxes.data);
    }
  };

  const handleChangeCustomer = async(event) => {
    setBoxes(null);
    if(event.target.value === "all") {
      getBoxes(customerId);
    } else {
      getBoxes(event.target.value);
    }
  };
  return (
    <>
      <SubNavbar>
        <FormControl variant="outlined" size="small">
          <OsgSelect
            native
            onChange={handleChangeCustomer}
            value={ customerId }
            className="formControl"
          >
            <option value="all">{t('すべての企業')}</option>
            {customers.map((v) => {
              return (
                <option value={v._id} key={ v._id }>
                  {v.name}
                </option>
              );
            })}
          </OsgSelect>
        </FormControl>
        {(!user.company_id.is_osg) ? (
          <Link to="/boxes/add" className="addCompanyBtn">
          <OsgButton style={{width:"250px"}} disabled={ user.company_id.is_osg }>
            <AddIcon />
            {t('BOX新規作成')}
          </OsgButton>
        </Link>
        ) : null}
      </SubNavbar>
      <TableContainer className={classes.table} >
        <Table className={classes.separate} size="small" aria-label="company table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableBoxHead} style={{backgroundColor: "#D4E7FF"}} width="40%">{t('BOX名')}</TableCell>
              <TableCell className={classes.tableCompanyHead} style={{backgroundColor: "#F5F5F5"}} width="30%">{t('企業名')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('通貨単位')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('BOXアカウント登録日')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('BOX稼働開始日')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('稼働ステータス')}</TableCell>
              <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('BOX登録情報')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(boxes) ? (
              <>
                {boxes.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell className={classes.tableBoxBody} style={{backgroundColor: "#D4E7FF"}}>{row.name}</TableCell>
                    <TableCell className={classes.tableCompanyBody} style={{backgroundColor: "#F6FBFF"}} component="th" scope="row">
                      {row.customer_id?.name}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{row.currency || 'ー'}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{moment(row.createdAt).tz(row.timezone_id ? row.timezone_id.value : "Japan").format('YYYY/MM/DD')}</TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                      {row.start_at ? moment(row.start_at).tz(row.timezone_id ? row.timezone_id.value : "Japan").format('YYYY/MM/DD') : null}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">
                      { boxStatus[row.status] }
                      {/*{row.status ? 
                        <Chip label={boxStatus[row.status]} style={{backgroundColor:'#00559D', color:'#fff'}} />
                      :
                        <Chip label={boxStatus[0]}/>
                      }*/}
                    </TableCell>
                    <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                      <Button href={`/boxes/${row._id}`} style={{color: "#00559D"}}>
                        {t('詳細')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={8} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BoxPage;
