import React, { Component } from "react";
// import { CircularProgress } from "@material-ui/core";
import Loader from "../Loader/Loader";
class Loading extends Component {
  render() {
    const style = this.props.style;

    let isLoading = false;
    for (let s in this.props.state) {
      isLoading |= this.props.state[s].isLoading;
    }

    return isLoading ? (
      <div
        style={{
          ...style,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          alignItems: "center",
          zIndex: 10000,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Loader />
      </div>
    ) : null;
  }
}

export default Loading;
