import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents/index";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  listColumn: {
    display: "flex",
    marginBottom: 10
  },
  listName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "40px",
    width: "40%",
    paddingRight: 20,
    fontWeight: "bold",
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%"
  },
  listTextInput: {
    width: "60%",
    backgroundColor: "#d4e7ff",
  }
}));

const BoxAddPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user'));
  const [companies, setCompanies] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    customer_id: '',
    name: '',
    currency: '',
    department: '',
    address: '',
    in_charge: '',
    tel: '',
    created_by: '',
    battery_notify: true,
  });

  useEffect(() => {
    (async () => {
      const res = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.status === 200) setCompanies(res.data);
    })();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (values.customer_id === '') {
      values.customer_id = companies[0]._id;
    }
    let errors = {};
    if (values.name === "") {
      errors.name = t("BOX名を入力してください");
    }
    if (values.currency === "") {
      errors.currency = t("通貨を入力してください");
    }

    if (Object.keys(errors).length === 0) {
      axios.post('/boxes', values).then((res) => {
        if (res.data.success) {
          alert(t('登録しました'));
        }
        props.history.push('/boxes')
      }).catch(e => {
        setIsDisabled(false);
      });
    } else {
      setIsDisabled(false);
      setErrors(errors);
    }
  };

  return (
    <>
      <form noValidate autoComplete="off">
        <section style={{borderBottom: "solid 1px #CFCFCF"}}>
          <div style={{maxWidth: 1000, textAlign: "center"}}>
            <h3>{t('BOX情報（必須）')}</h3>
            <div className={classes.listColumn} style={{marginBottom: 20}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('設置する企業を選択')}
              </div>
              <div className={classes.listValue}>
                <OsgSelect
                  id="customer_id"
                  name="customer_id"
                  value={values.customer_id}
                  onChange={handleChange}
                  style={{width: "100%"}}
                >
                  {companies.map((row) => {
                    return <option value={row._id} key={ row._id }>{row.name}</option>;
                  })}
                </OsgSelect>
              </div>
            </div>

            <Typography>
              <span className="error-text" style={{fontSize:12}}>
                {errors.name ? errors.name : ""}
              </span>
            </Typography>
            <div className={classes.listColumn} style={{marginBottom: -5}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('BOX名')}
              </div>
              <TextField
                name="name"
                size="small"
                variant="outlined"
                className={classes.listTextInput}
                label={t("BOX名")}
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            </div>
            <div className={classes.listColumn}>
              <div className={classes.listName}></div>
              <div className={classes.listValue} style={{fontSize: "12px"}}>
              {t('BOX名は、BOX名のみでどこの場所に設置しているかわかりやすい名前にすることをおすすめします。')}
              </div>
            </div>
            <Typography>
              <span className="error-text" style={{fontSize:12}}>
                {errors.currency ? errors.currency : ""}
              </span>
            </Typography>
            <div className={classes.listColumn} style={{marginBottom: -5}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('BOXで扱う製品の通貨単位')}
              </div>
              <TextField
                name="currency"
                size="small"
                variant="outlined"
                className={classes.listTextInput}
                label={t("BOXに入れる製品の通貨単位")}
                style={{width: "40%"}}
                onChange={handleChange}
              />
              <div className={classes.listValue} style={{width: "20%", color: "#BEBEBE"}}>
                <InfoOutlinedIcon style={{paddingLeft: 10}}/>
              </div>
            </div>
            <div className={classes.listColumn}>
              <div className={classes.listName}></div>
              <div className={classes.listValue} style={{fontSize: "12px"}}>
              {t('タブレットや製品の詳細画面で表示する通貨単位を指定します。')}
              </div>
            </div>
          </div>
        </section>

        <section style={{marginTop: 50}}>
          <div style={{maxWidth: 1000, textAlign: "center"}}>
            <h3>{t('BOX設置先情報（任意）')}</h3>
            <FormControl style={{width: "100%"}}>
              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                  <span style={{color: "#C4C4C4", fontSize: "12px", marginRight: 20}}>{t('任意')}</span>
                  {t('BOXを設置する部署')}
                </div>
                <TextField
                  name="department"
                  size="small"
                  variant="outlined"
                  className={classes.listTextInput}
                  label={t("BOX設置部署")}
                  onChange={handleChange}
                />
              </div>

              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                  <span style={{color: "#C4C4C4", fontSize: "12px", marginRight: 20}}>{t('任意')}</span>
                  {t('BOX設置先住所')}
                </div>
                <TextField
                  name="address"
                  size="small"
                  variant="outlined"
                  className={classes.listTextInput}
                  label={t("BOX設置先住所")}
                  onChange={handleChange}
                />
              </div>

              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                  <span style={{color: "#C4C4C4", fontSize: "12px", marginRight: 20}}>{t('任意')}</span>
                  {t('BOX設置先の担当者')}
                </div>
                <TextField
                  name="in_charge"
                  size="small"
                  variant="outlined"
                  className={classes.listTextInput}
                  label={t("BOX設置先の担当者")}
                  onChange={handleChange}
                />
              </div>

              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                  <span style={{color: "#C4C4C4", fontSize: "12px", marginRight: 20}}>{t('任意')}</span>
                  {t('電話番号')}
                </div>
                <TextField
                  name="tel"
                  size="small"
                  variant="outlined"
                  className={classes.listTextInput}
                  label={t("電話番号")}
                  onChange={handleChange}
                />
              </div>

              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                {t('登録日')}
                </div>
                <div className={classes.listValue}>
                  {moment().format('YYYY/MM/DD(ddd)')}
                </div>
              </div>

              <div className={classes.listColumn} style={{marginBottom: 20}}>
                <div className={classes.listName}>
                {t('登録者名')}
                </div>
                <div className={classes.listValue}>
                  {user.username}
                </div>
              </div>

              <OsgButton
                onClick={(e) => {
                  setIsDisabled(true)
                  handleSubmit(e)
                }}
                disabled={isDisabled}
                style={{marginTop: 50}}
              >
                {t('登録')}
              </OsgButton>
              <Link
                to="/boxes"
                style={{color: "#427DD4", fontSize: "12px", textDecoration: "none"}}
              >
                <p>
                {t('登録をやめてBOX本体管理に戻る')}
                </p>
              </Link>
            </FormControl>
          </div>
        </section>
      </form>
    </>
  );
};

export default BoxAddPage;
