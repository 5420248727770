import React, { useState, useEffect } from 'react';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import styled from "styled-components";
import axios from 'axios';
import moment from 'moment';
import ItemDetail from '../..//Common/ItemDetail';
import _ from 'lodash';
import Encoding from 'encoding-japanese';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  InputBase,
  CircularProgress,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  .warningContainer {
    text-align: left;
    width: 100%;
    margin-left: 0;
  }

  .right_side {
    margin-left: auto;
    text-decoration: none
    justify-content: flex-end;
  }

  .left_side {
    justify-content: flex-start;
  }

  .textField {
    padding-left: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    height: 40px;
    width: 207px;
    vertical-align: top;
  }

  .searchright_side {
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    transition: background-color .25s;
    height: 40px;

    &:hover {
      background-color: #ccc;
    }

    &:active {
      background-color: gray;
    }
  }
`;

const useDynamicStyles = (windowHeight) => makeStyles({
  table: {
    width: '100%',
    maxHeight: windowHeight - 330,
    overflowY: "scroll",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "10px",
      height: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: "#7d7d7d",
    },
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: "12pt",
    whiteSpace: "nowrap",
    textAlign: "center",
    padding: "6px 16px 6px 16px",
    borderTop: "solid 2px #00559D",
    borderBottom: "solid 2px #00559D"
  },
  tableBody: {
    fontSize: "12pt",
    whiteSpace: "nowrap",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  tableBoldBody: {
    fontWeight: "bold",
    fontSize: "16pt",
    whiteSpace: "nowrap",
    padding: "6px 16px 6px 16px",
    borderBottom: "solid 1px #00559D"
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00559D",
    marginLeft: "20px"
  },
  dialogSubTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#838383",
    marginLeft: "20px",
  },
  dialogCenterCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold"
  },
  warning: {
    color: "#C90000",
  },
  warningContainer: {
    textAlign: "left",
    width: "100%",
    marginLeft: 0,
  },
  left: {
    justifyContent: "flex-start",
  },
  right: {
    justifyContent: "flex-end",
  },
  label: {
    display: "flex",
    width: "90px",
    marginRight: "20px",
    fontSize: "12pt",
    alignItems: "center",
  }
});

const StockPage = (props) => {
  const [t] = useTranslation();
  const [boxes, setBoxes] = useState(null);
  const [options, setOptions] = useState([]);
  const [items, setItems] = useState(null);
  const [originalItems, setOriginalItems] = useState([]);
  const [sortedItems, setSortedItems] = useState({});
  const [boxId, setBoxId] = useState(null);
  const [box, setBox] = useState({ });
  const [customerId, setCustomerId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState('edp_no');
  const [sort, setSort] = useState('edp_no');
  const [targetItem, setTargetItem] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [deleteQuantity, setDeleteQuantity] = useState(0);
  const [buttonsDisable, setButtonsDisable] = useState(true);
  const [user, setUser] = useState(null);
  const [reserveItemCsvCount, setReserveItemCsvCount] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    (async () => {
      setUser(JSON.parse(localStorage.getItem("user")));
      const userData = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get('/companies/for_box', {
        params: {
          withCompanyGroup: true
        }
      });
      setOptions(res.data);
      if (props.location?.state) {
        setButtonsDisable(false)
        setCustomerId(props.location.state.customer_id);
        const res = await axios.get(`boxes/customer/${ props.location.state.customer_id }`, {
          params: {
            withCompanyGroup: true
          }
        });
        setBoxId(props.location.state.box_id);
        res.data !== null ? setBoxes(res.data) : setBoxes([]);
        const response = await axios.get(`boxes/${ props.location.state.box_id }`);
        if (response.data !== null) {
          setBox(response.data);
          setItems(response.data.products);
          setOriginalItems(response.data.products);
        }
        if (userData?.company_id.is_osg || userData?.company_id.is_agent) {
          const reserveItemRes = await axios.get(`/reserve_item_csv`, {
            params: {
              box_id: props.location.state.box_id,
              customer_id: props.location.state.customer_id
            }
          });
          setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
        }
      } else {
        setItems([]);
        setBoxes([]);
        setBoxId('');
        setCustomerId('');
      }
    })();
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      handleSort(sort);
    }
  }, [sort]);

  useEffect(() => {
    if (items && items.length > 0) {
      handleSort(sort);
    }
  }, [items]);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数でイベントリスナーを削除
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const classes = useDynamicStyles(windowHeight)();

  const handleSort = () => {
    let sorted;
    if (sort === 'image') {
        sorted = _.sortBy(items, [
            row => !!row['image'],
            row => parseInt(row['edp_no'].replace(/ /g, ''))
        ]);
        sorted = _.reverse(sorted);
    } else if (sort === 'remarks') {
        sorted = _.filter(items, row => row[sort]);
        sorted = sorted.concat(_.filter(items, row => !row[sort]));
        sorted = _.sortBy(sorted, [
            row => !row[sort],
            row => parseInt(row['edp_no'].replace(/ /g, ''))
        ]);
    } else if (sort === 'updatedAt') {
        sorted = _.reverse(_.sortBy(items, [
            row => row[sort],
            row => parseInt(row['edp_no'].replace(/ /g, ''))
        ]));
    } else if (sort === 'edp_no') {
      sorted = _.sortBy(items, [
          row => parseInt(row[sort].replace(/ /g, '')),
          row => parseInt(row['edp_no'].replace(/ /g, ''))
      ]);
    } else if (sort === 'abbreviation') {
      sorted = _.sortBy(items, [
          row => row[sort].replace(/ /g, ''),
          row => parseInt(row['edp_no'].replace(/ /g, ''))
      ]);
    } else if (sort === 'quantity') {
      sorted = _.sortBy(items, [
        row => row[sort],
        row => parseInt(row['edp_no'].replace(/ /g, ''))
      ]);
    } else {
      sorted = _.sortBy(items, [
          row => row[sort],
          row => parseInt(row['edp_no'].replace(/ /g, ''))
      ]);
    }
    setSortedItems(sorted);
  };

  const handleFilter = () => {
    if (searchText === '') {
      setItems(originalItems);
      return;
    }
    const items = _.filter(originalItems, row => {
      return row[type] === searchText;
    });

    setItems(items);
  };

  const handleChangeCompany = async (event) => {
    setCustomerId(event.target.value)
    setBoxes(null);
    setBoxId(null);
    setSortedItems([]);
    if (event.target.value) {
      const res = await axios.get(`boxes/customer/${ event.target.value }`, {
        params: {
          withCompanyGroup: true
        }
      });
      if (res.data !== null) {
        setBoxes(res.data);
      }
    } else {
      setBoxes([]);
    }
  };

  const handleCsvDownload = async () => {
    moment.locale('ja');
    const today = moment().format('YYYYMMDD');

    const fileName = `${ box.name }_${ today }.csv`;
    const data = [[
      'edp_no',
      'abbreviation',
      'designation',
      'number_of_flutes',
      'overall_thread_length_shank_dia',
      'grade',
      'surface_treatment',
      'category',
      'weight',
      'materials',
      'drill_hole_dia',
      'standard_price',
      'quantity',
      'reorder_point',
      'location',
      'footnotes',
      'image',
    ]];
    items.map(v => {
      return data.push([
        v.edp_no,
        v.abbreviation,
        v.designation,
        v.number_of_flutes,
        v.overall_thread_length_shank_dia,
        v.grade,
        v.surface_treatment,
        v.category,
        v.weight,
        v.materials,
        v.drill_hole_dia,
        v.standard_price,
        v.quantity,
        v.reorder_point,
        v.location,
        v.remarks,
        v.image,
      ]);
    });
    const rows = data.map(v => v.join(',')).join('\n');
    let csvBlob;
    if (user.lang === 'zh') {
      const BOM = "\uFEFF";
      csvBlob = new Blob([BOM + rows], { type: 'text/csv;charset=utf-8' });
    } else {
      const unicodeList = [];
      for (let i = 0; i < rows.length; i += 1) {
        unicodeList.push(rows.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'AUTO');
      const uInt8List = new Uint8Array(shiftJisCodeList);
      csvBlob = new Blob([uInt8List], { type: 'text/csv' });
    }
    const jsonURL = window.URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteItem = async () => {
    const index = _.findIndex(items, o => {return o._id === targetItem._id})
    const item = items[index]
    item.quantity = quantity - deleteQuantity;
    await axios.put(`/items/${ item._id }`, item);
    setQuantity(0);
    setDeleteQuantity(0);
    setIsOpenDialog(false);
    setIsDeleteDialog(false);
    setTargetItem(null);
  };

  const handleChangeBox = async (event) => {
    setBoxId(event.target.value)
    setItems(null)
    setSortedItems({});
    if (event.target.value) {
      setButtonsDisable(false)
    } else {
      setButtonsDisable(true)
    }
    if (user?.company_id.is_osg || user?.company_id.is_agent) {
      const reserveItemRes = await axios.get(`/reserve_item_csv`, {
        params: {
          box_id: event.target.value,
          customer_id: customerId
        }
      });
      setReserveItemCsvCount(reserveItemRes.data.reserveItemCsvCount)
    }
    const res = await axios.get(`boxes/${ event.target.value }`);
    if (res.data !== null) {
      setBox(res.data);
      setItems(res.data.products);
      setOriginalItems(res.data.products);
      setSort(sort);
    }
  };

  const handleOpenDialog = (item, isDelete, quantity = 0) => {
    setTargetItem(item);
    setIsDeleteDialog(isDelete);
    setIsOpenDialog(true);
    if (quantity !== 0) setQuantity(quantity);
  };

  return <>
    <SubNavbar className={classes.right}>
      <SubNavbar className={classes.left_side}>
        {(reserveItemCsvCount > 0) ?
          <div className={classes.warningContainer}>
            <span className={classes.warning}>{t('補充予約中です')}</span>
          </div>
          : null}
      </SubNavbar>
      { customerId != null ? (
        <OsgSelect onChange={handleChangeCompany} defaultValue={ customerId } style={{width: '311px', marginRight: "20px"}}>
          <option value="">{t('企業を選択')}</option>
          {options.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
      ) : (
        <>
          <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
            <CircularProgress size="2rem" />
          </div>
        </>
      )}
      { boxes !== null ? (
        <OsgSelect onChange={handleChangeBox} defaultValue={ boxId } style={{width: "311px"}}>
          <option value="">{t('BOXを選択')}</option>
          {boxes.map((v) => {
            return (
              <option value={ v._id } key={ v._id }>
                {v.name}
              </option>
            );
          })}
        </OsgSelect>
      ) : (
        <>
          <div style={{ width: "245px", justifyContent: 'center', display: 'flex'}}>
            <CircularProgress size="2rem" />
          </div>
        </>
      )}
    </SubNavbar>
    <SubNavbar className={classes.right}>
      <OsgButton style={{width:"150px"}}  onClick={ handleCsvDownload } disabled={ buttonsDisable }>
        {t('CSVダウンロード')}
      </OsgButton>
    </SubNavbar>
    <SubNavbar className={classes.left}>
      <Box className={classes.label}>
        <span>{t('並び順')}</span>
      </Box>
      <OsgSelect onChange={ (e) => { setSort(e.target.value); }} style={{width: '300px', marginRight: "20px"}}>
        <option value={ "edp_no" } key={ 'edp_no' }>{t('ツールNo')}.</option>
        <option value={ "quantity" } key={ 'quantity' }>{t('在庫が少ない順')}</option>
        <option value={ "updatedAt" } key={ 'updatedAt' }>{t('更新日')}</option>
        <option value={ "abbreviation" } key={ 'abbreviation' }>{t('製品記号')}</option>
        <option value={ "remarks" } key={ 'remarks' }>{t('備考記載順')}</option>
        <option value={ "image" } key={ 'image' }>{t('写真有無')}</option>
        <option value={ "category" } key={ 'category' }>{t('分類')}</option>
        <option value={ "location" } key={ 'location' }>{t('在庫ロケーション')}</option>
      </OsgSelect>
    </SubNavbar>
    <SubNavbar className={classes.left}>
      <Box className={classes.label}>
        <span>{t('検索')}</span>
      </Box>
      <Box>
        <OsgSelect className="right_side" onChange={(e) => { setType(e.target.value) }} style={{width: '180px', marginRight: "20px"}}>
          <option value={ "edp_no" } key={ 'edp_no' }>{t('ツールNo')}.</option>
          <option value={ "abbreviation" } key={ 'abbreviation' }>{t('製品記号')}</option>
        </OsgSelect>
        <InputBase placeholder="Search…" className="textField" onChange={ (e) => { setSearchText(e.target.value) }} onKeyDown={(e) => {
          if (e.key === 'Enter') {  // 'which'を'e.key'に書き換える
            handleFilter();
          }
        }} />
        <button className="searchright_side" onClick={handleFilter}><SearchIcon /></button>
      </Box>
    </SubNavbar>
    <div style={{paddingBottom: "5px", paddingTop: "9px"}}>(TimeZone: {box.timezone_id ? box.timezone_id.value : "Japan"})</div>
    <TableContainer className={classes.table}>
      <Table size="small" aria-label="company table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('更新日')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="10%">{t('登録者名')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="15%">{t('分類')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('ツールNo')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t('製品記号')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('呼び')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t('全長、ねじ長、シャンク径')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('標準価格')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('個数')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('発注点')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="5%">{t('製品写真の有無')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="10%">{t('製品備考')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#D4E7FF"}} width="10%">{t('在庫ロケーション')}</TableCell>
            <TableCell className={classes.tableHead} style={{backgroundColor: "#F5F5F5"}} width="5%">{t('製品情報')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedItems ? (
            <>
              {_.map(sortedItems, (row) => (
                <TableRow key={row._id}>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ moment(row.updatedAt).tz(box.timezone_id ? box.timezone_id.value : "Asia/Tokyo").format('YYYY/MM/DD') }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.user_id.username }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ row.category }</TableCell>
                  <TableCell className={classes.tableBoldBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.edp_no }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.abbreviation }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.designation }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.overall_thread_length_shank_dia }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.standard_price }</TableCell>
                  <TableCell className={classes.tableBoldBody} style={{backgroundColor: "#D4E7FF"}} align="center">
                    { row.quantity > 0 ?
                      <span>{row.quantity}</span>
                    :
                      <span className="dangerText">{row.quantity}</span>
                    }
                  </TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}} align="center">{ row.reorder_point }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}} align="center">{ row.image ? t('有') : t('無') }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>{ row.remarks }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#D4E7FF"}}>{ row.location }</TableCell>
                  <TableCell className={classes.tableBody} style={{backgroundColor: "#F6FBFF"}}>
                    <Button onClick={ () => handleOpenDialog(row, false) } style={{color: "#00559D"}}>
                    {t('詳細')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={13} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>


    <Dialog open={ isOpenDialog } fullWidth maxWidth="md">
      <Button onClick={ () => setIsOpenDialog(false) }
      style={{
        margin: "0 0 0 auto",
        backgroundColor: "transparent",
        border:'none',
        position:'absolute',
        top:'15px',
        right:'24px',
      }}
      >{t('閉じる')}</Button>
      <ItemDetail item={ targetItem } />
      { isDeleteDialog ?
      <div>
        <div className={classes.dialogTitle}>{ box.name }</div>
        <div className={classes.dialogSubTitle}>{ box.customer_id.name }</div>
        <div className={classes.dialogCenterCol}>
        {t('この製品をBOXから撤去します。撤去数')}
          <OsgSelect
            onChange={ e => { setDeleteQuantity(e.target.value) }}
            defaultValue={ deleteQuantity }
            style={{width: "100px", marginLeft: 10}}
          >
            <option value={ 0 }>0</option>
            {[...Array(quantity)].map((i, k) => {
              return (
                <option value={ k + 1 } key={k}>
                  {k + 1}
                </option>
              );
            })}
          </OsgSelect>
        </div>
        <div className={classes.dialogCenterCol} style={{marginTop: 20, marginBottom: 20}}>
          <Button
            color="primary"
            variant="outlined"
            style={{width: "200px", height: "40px", marginRight: 20}}
            onClick={ () => setIsOpenDialog(false) }
          >{t('しない')}</Button>
          <OsgButton onClick={ handleDeleteItem }>{t('撤去する')}</OsgButton>
        </div>
      </div> : null }
    </Dialog>
  </>
};

export default StockPage;
