import React, { useEffect, useState } from 'react';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Modal,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Switch,
  FormControlLabel,
  Dialog,
  FormGroup,
  Checkbox,
  Typography
} from '@material-ui/core';
import { OsgButton, OsgSelect } from "../../Common/UIcomponents/index";
import { Trans, useTranslation } from 'react-i18next';

const SubNavbar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;

const Details = styled.div`
  max-width: 1000px;
  margin: 20px 0;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const RemoveBtn = styled(Button)`
  max-width: 100px;
  margin-left: 15px !important;
  background-color: #838383 !important;
  color: #fff !important;

  &:hover {
    background-color: #6d6d6d !important;
  }
`

const useStyles = makeStyles({
  listTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listItem: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    height: "40px",
    width: "30%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#00559D"
  },
  listText:  {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    height: "40px",
    width: "70%",
    marginTop: "0",
    marginBottom: "1px",
    backgroundColor: "#F6FBFF",
  },
  listTextInput:  {
    width: "60%",
    backgroundColor: "#d4e7ff"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  modalContainer: {
    borderWidth: 0,
    width: 600,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
  },
  modalListTitle: {
    width: "40%",
  },
  modalListText: {
    width: "60%"
  },
  loading: {
    alignItems: "center",
  },
  deleteConfirmButton: {
    color: "#C90000",
  },
  deleteConfirmDialogContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "50px",
  },
  deleteConfirmDialogTitle: {
    textAlign: "center",
    color: "#C90000",
    fontSize: "20px",
    fontWeight: "bold"
  },
  deleteConfirmUserName: {
    textAlign: "center",
    fontWeight: "bold"
  },
  deleteConfirmDescription: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  deleteButton: {
    background: "#C90000",
    fontWeight: "bold",
    width: "200px",
    height: "42px",
    marginRight: "20px",
    '&:hover': {
      background: '#C90000'
    }
  },
  backButton: {
    width: "200px",
    height: "42px",
    marginRight: "20px",
  },
  column:  {
    marginLeft: "5px",
  },
  switchLabel:  {
    width: "0px",
  },
});

const BoxDetailPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [box, setBox] = useState({});
  const id = props.match.params.id;
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [timezoneDefault, setTimezoneDefault] = useState('');
  const [isLoading, setIsLoading] = useState({
    timezone: false,
    battery: false,
    guestLogin: false,
    purchaseNotify: [false, false, false, false, false, false, false, false],
  });
  const [purchaseNotify, setPurchaseNotify] = useState({
    purchase_notify: true,
    purchase_daily_notify: false,
    purchase_weekly_notify: false,
    purchase_monthly_notify: false,
    daily_notify_time: '00:00',
    weekly_notify_time: '00:00',
    weekly_notify_day: 0,
    monthly_notify_time: '00:00',
  });
  const [deletable, setDeletable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  let timezone = box.timezone_id ? box.timezone_id.value : "Asia/Tokyo";
  const boxStatus = [t('未稼働'), t('稼働中'), t('稼働停止'), t('非表示')];
  const companyList = [
    { name: t("企業名"), value: box.customer_id?.name },
    { name: t("BOX名"), value: box.name },
    { name: t("BOXで扱う製品の通貨"), value: box.currency },
    { name: t("BOXを設置する部署"), value: box.department },
    { name: t("BOX設置先住所"), value: box.address },
    { name: t("BOX設置先の担当者"), value: box.in_charge },
    { name: t("電話番号"), value: box.tel },
    { name: t("BOX稼働ステータス"), value: box.status ? boxStatus[box.status] : boxStatus[0] }
  ];
  const agentCompanyList = [
    { name: t("BOX管理企業名"), value: box.agent_id?.name },
    { name: t("住所"), value: box.agent_id?.address },
    { name: t("BOX管理企業連絡先"), value:box.agent_id?.tel },
    { name: t("担当者名"), value: box.agent_id?.in_charge },
    { name: t("BOX登録日"), value: moment(box.createdAt).tz(timezone).format('YYYY/MM/DD HH:mm:ss') +" (TimeZone:"+timezone+")" },
    { name: t("BOX稼働開始日"), value: box.start_at ? moment(box.start_at).tz(timezone).format('YYYY/MM/DD HH:mm:ss') +" (TimeZone:"+timezone+")" : null},
  ];

  const modalTextList = [
    {
      title: t("稼働一時停止"),
      text: t("一時停止にすると、iPad上は、メンテナンス中表示になり、ユーザーは、"),
      applyText: t("稼働を一時停止する"),
      cancelText: t("稼働を停止しない")
    },
    {
      title: t("BOXデータを非表示にします"),
      text: t("ダッシュボード上の情報を非表示にします。\n事前に購入履歴と在庫リストのCSVをダウンロードしましょう。"),
      applyText: t("稼働を停止して非表示"),
      cancelText: t("操作しない")
    },
    {
      title: t("BOXの稼働を再開する"),
      text: t("一時停止中のBOXを稼働再開させます。\nユーザーはBOXから購入できるようになります。"),
      applyText: t("稼働を再開する"),
      cancelText: t("稼働停止を継続")
    },
    {
      title: t("ダッシュボードに表示させる"),
      text: t("OSG一般、商社、カスタマーに非表示中のBOXを表示させます。"),
      applyText: t("表示させる"),
      cancelText: t("引き続き非表示")
    }
  ];

  // moment.jsのgetdayに合わせる
  const dayList = [
    t("日曜日"), t("月曜日"), t("火曜日"), t("水曜日"), t("木曜日"), t("金曜日"), t("土曜日")
  ];

  const timeList = [
    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
    '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
    '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
  ];

  useEffect(() => {
    console.info(error)
  }, [error]);

  useEffect(() => {
    (async () => {
      const id = props.match.params.id;
      const box = await axios.get(`boxes/${id}`);
      setPurchaseNotify({
        purchase_notify: typeof box.data.purchase_notify === 'undefined' ? true : box.data.purchase_notify,
        purchase_daily_notify: !box.data.purchase_daily_notify ? false : box.data.purchase_daily_notify,
        purchase_weekly_notify: !box.data.purchase_weekly_notify ? false : box.data.purchase_weekly_notify,
        purchase_monthly_notify: !box.data.purchase_monthly_notify ? false : box.data.purchase_monthly_notify,
        daily_notify_time: !box.data.daily_notify_time ? '00:00' : box.data.daily_notify_time,
        weekly_notify_time: !box.data.weekly_notify_time ? '00:00'  : box.data.weekly_notify_time,
        weekly_notify_day: !box.data.weekly_notify_day ? 0 : box.data.weekly_notify_day,
        monthly_notify_time: !box.data.monthly_notify_time ? '00:00' : box.data.monthly_notify_time,
      })
      setBox(box.data);
    })();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.company_id.is_osg && user.is_admin) {
      setDeletable(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const timezones = await axios.get(`timezone`);
      const defaultValue = _.find(timezones.data, (t) => {
        return t.value === 'Japan'
      });
      setTimezoneDefault(defaultValue);
      setTimezoneOptions(_.sortBy(timezones.data, "order"));
    })();
  }, []);

  const handleHideBox = async () => {
    try {
      const res = await axios.put(`boxes/stop/${id}`, {
        status: 3,
      });
      if (res.data.success) {
        alert(t('非表示にしました'));
      }
    } catch (e) {
      console.log(e);
    }

    setModal(false);
  }

  const showModal = (value) => {
    if(modal) {
      setModal(false);
    } else {
      setModalType(value);
      setModal(true);
    }
  };

  const handleStopBox = async (isShowMessage = false) => {
    try {
      const res = await axios.put(`boxes/stop/${id}`, {
        status: 2,
      });
      if (res.data.success && isShowMessage) {
        alert(t('ダッシュボードに表示しました'));
      } else if (res.data.success) {
        alert(t('一時停止にしました'));
      }
    } catch (e) {
      console.log(e);
    }

    setModal(false);
  }

  const handleStartBox = async () => {
    try {
      const res = await axios.put(`boxes/start/${id}`, {
        status: 1,
      });
      if (res.data.success) {
        alert(t('稼働再開しました'));
      }
    } catch (e) {
      console.log(e);
    }

    setModal(false);
  }

  const handleAddEmail = async () => {
    if (email === '') {
      setError(t('メールアドレスを入力してください'));
      return;
    }

    const res = await axios.put('/boxes/add_email', {
      box_id: id,
      email: email
    });

    if (res.data.success) {
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
      setEmail('');
      alert(t('メールアドレスを登録しました'));
    } else {
      alert(res.data.error);
    }
  };

  const handleRemoveEmail = async email => {

    const res = await axios.put('/boxes/remove_email', {
      box_id: id,
      email: email
    });

    if (res.data.success) {
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
      alert(t('メールアドレスを除外しました'));
    }
  };

  const handleChangeTimezone = async e => {
    setIsLoading((prevState) => ({
      ...prevState,
      timezone: true,
    }));
    const res = await axios.put('/boxes/timezone', {
      box_id: id,
      timezone_id: e.target.value
    });

    if (res.data.success) {
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
      setIsLoading((prevState) => ({
        ...prevState,
        timezone: false,
      }));
      alert(t('タイムゾーンを変更しました'));
    } else {
      setIsLoading((prevState) => ({
        ...prevState,
        timezone: false,
      }));
    }
  };

  const handleBatteryNotify = async e => {
    setIsLoading((prevState) => ({
      ...prevState,
      battery: true,
    }));
    const res = await axios.put('/boxes/battery_notify', {
      box_id: id,
      battery_notify: e.target.checked
    });

    if (res.data.success) {
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
      setIsLoading((prevState) => ({
        ...prevState,
        battery: false,
      }));
      alert(t('バッテリーメール通知を変更しました'));
    } else {
      setIsLoading((prevState) => ({
        ...prevState,
        battery: false,
      }));
    }
  };

  const handleGuestLogin = async e => {
    setIsLoading((prevState) => ({
      ...prevState,
      guestLogin: true,
    }));
    const res = await axios.put('/boxes/guest_login', {
      box_id: id,
      guest_login: e.target.checked
    });

    if (res.data.success) {
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
      setIsLoading((prevState) => ({
        ...prevState,
        guestLogin: false,
      }));
      if (e.target.checked) {
        alert(t('iPadゲストログインをオフにしました'));
      } else {
        alert(t('iPadゲストログインをオンにしました'));
      }
    } else {
      setIsLoading((prevState) => ({
        ...prevState,
        guestLogin: false,
      }));
    }
  };

  const handleDeleteClick = async e => {
    const res = await axios.delete(`/boxes/${id}`);
    if (res.data.success) {
      alert(t('削除しました'));
      props.history.push('/boxes');
    }
  };

  const handlePurchaseNotify = async (value, i) => {
    const loadingPurchaseNotify = isLoading.purchaseNotify;
    loadingPurchaseNotify[i] = true;
    setIsLoading((prevState) => ({
      ...prevState,
      purchase: loadingPurchaseNotify,
    }));

    const tmpPurchaseNotify = purchaseNotify;
    Object.assign(tmpPurchaseNotify, value);
    const res = await axios.put('/boxes/purchase_notify', {
      id: id,
      ...tmpPurchaseNotify,
    });
    if (res.data.success) {
      setPurchaseNotify((prevState) => ({
        ...prevState,
        ...value,
      }));
      const box = await axios.get(`boxes/${id}`);
      setBox(box.data);
    }
    loadingPurchaseNotify[i] = false;
    setIsLoading((prevState) => ({
      ...prevState,
      purchase: loadingPurchaseNotify,
    }));
  };

  return (
    <>
      <div style={{overflowY: "hidden"}}>
        <SubNavbar>
          {/*
          <Link to="/histories" style={{textDecoration: "none"}}>
            <OsgButton  style={{width: "250px"}}>{t('購入履歴を見る')}</OsgButton>
          </Link>
          */}
        </SubNavbar>
        <div style={{borderBottom: "solid 1px #DFDFDF"}}>
          <Details>
            <div className={classes.listTitle}>
              <span style={{marginLeft: 15, fontWeight: "bold"}}>{t('BOX設置先企業情報')}</span>
              <div>
                <Button color="primary" href={`/boxes/edit/${id}`}> {t('編集')} </Button>
              </div>
            </div>
            <List style={{padding: 0}}>
              {companyList.map((v,index) => {
                return (
                  <ListItem
                    key={index}
                    className={classes.listItem}
                    dense
                    style={index === 3 || index === 7 ? {marginTop: 10} : {}}
                  >
                    <ListItemText className={classes.listName} primary={v.name} />
                    <ListItemText className={classes.listText} primary={v.value} />
                  </ListItem>
                );
              })}
            </List>
            {/*
            <div className={classes.listTitle} style={{justifyContent: "flex-end"}}>
              <Button color="primary" href={`/boxes`}> {t('→設置先BOX利用者一覧へ')} </Button>
            </div>
            */}
          </Details>
        </div>
        <div style={{borderBottom: "solid 1px #DFDFDF"}}>
          <Details>
            <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
              <span style={{marginLeft: 15, marginBottom: 6, fontWeight: "bold"}}>{t('BOX管理企業情報')}</span>
            </div>
            <List style={{padding: 0}}>
              {agentCompanyList.map((v,index) => {
                return (
                  <ListItem
                    key={index}
                    className={classes.listItem}
                    dense
                    style={index === 4 ? {marginTop: 10} : {}}
                  >
                    <ListItemText className={classes.listName} primary={v.name} />
                    <ListItemText className={classes.listText} primary={v.value} />
                  </ListItem>
                );
              })}
            </List>
            {/*
            <div className={classes.listTitle} style={{justifyContent: "flex-end"}}>
              <Button color="primary" href={`/users`}>{t('→BOX管理企業ユーザー一覧へ')} </Button>
            </div>
            */}
          </Details>
        </div>

          <div style={{borderBottom: "solid 1px #DFDFDF"}}>
            <Details>
              <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
                <span style={{marginLeft: 15, marginBottom: 6, fontWeight: "bold"}}>{t('BOX設定')}</span>
              </div>
              <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
                <span style={{width: "280px", marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('タイムゾーン')}</span>
                {isLoading.timezone ? (
                  <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                ) : (
                  <OsgSelect
                    native
                    value={box.timezone_id ? box.timezone_id._id : timezoneDefault._id}
                    onChange={handleChangeTimezone}
                    className="formControl"
                  >
                    {timezoneOptions.map((v) => {
                      return (
                        <option value={ v._id } key={ v._id }>
                          {v.name}
                        </option>
                      );
                    })}
                  </OsgSelect>
                )}
              </div>
            </Details>
            <Details>
              <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
                <span style={{width: "280px", marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('バッテリーメール通知')}</span>
                {isLoading.battery ? (
                  <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={typeof box.battery_notify !== 'undefined' ? box.battery_notify : true}
                        onChange={handleBatteryNotify}
                        name="battery_notify"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={typeof box.battery_notify === 'undefined' || box.battery_notify ? 'ON' : 'OFF'}
                  />
                )}
              </div>
            </Details>
            <Details>
              <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
                <span style={{width: "280px", marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('ゲストログインを許可する')}</span>
                {isLoading.guestLogin ? (
                  <div style={{ width: "254px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={typeof box.guest_login !== 'undefined' ? box.guest_login : true}
                        onChange={handleGuestLogin}
                        name="guest_login"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={typeof box.guest_login === 'undefined' || box.guest_login ? 'ON' : 'OFF'}
                  />
                )}
              </div>
            </Details>
          </div>
          <Details>
            <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
              <span style={{marginLeft: 15, fontWeight: "bold"}}>{t('BOXから購入報告・発注点の通知メールを受け取る')}</span>
            </div>
            { box.notify_emails?.map((email, index) => {
              return (
                <List key={ index } style={{marginTop: 5, marginLeft: 15}}>
                  <TextField
                    size="small"
                    variant="outlined"
                    disabled
                    className={classes.listTextInput}
                    value={ email }
                    onChange={ (e) => setEmail(e.target.value) }
                  />
                  <RemoveBtn onClick={() => handleRemoveEmail(email)} style={{width: 100, marginLeft: 15, height: 40}}>{t('除外')}</RemoveBtn>
                </List>
              )
            })}
            <List style={{marginTop: 5, marginLeft: 15}}>
              <TextField
                size="small"
                variant="outlined"
                label={t("メールアドレスを入力する")}
                className={classes.listTextInput}
                value={ email }
                onChange={ (e) => setEmail(e.target.value) }
              />
              <OsgButton onClick={handleAddEmail} style={{width: 100, marginLeft: 15}}>{t('登録')}</OsgButton>
            </List>
          </Details>
          <Details>
            <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
              <span style={{marginLeft: 15, marginBottom: 15, fontWeight: "bold"}}>{t('メールの受信のタイミング')}</span>
            </div>
            <div className={classes.listTitle} style={{justifyContent: "flex-start"}}>
              <span style={{width: "100px", marginTop: 10, marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('即時購入通知')}</span>
              {isLoading.purchaseNotify[0] === true ? (
                <div style={{ width: "85px", height: "34px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <FormGroup style={{display:"flex", paddingLeft:40, paddingTop: 10}}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={purchaseNotify.purchase_notify}
                        onChange={(e) => handlePurchaseNotify({ purchase_notify: e.target.checked }, 0)}
                        name="purchase_notify"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={
                      <Typography variant="body1" className={classes.switchLabel}>
                        {typeof purchaseNotify.purchase_notify === 'undefined' || purchaseNotify.purchase_notify ? 'ON' : 'OFF'}
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
            </div>
            <div style={{marginLeft: 70, marginTop: 30}}>
              {t('※以下のものは、前回の購入通知から、指定した日の前日24時までの分をまとめて購入通知を行います')}
            </div>
            <div className={classes.listTitle} style={{justifyContent: "flex-start", marginTop: 20}}>
              <span style={{width: "100px", marginTop: 10, marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('毎日')}</span>
              {isLoading.purchaseNotify[1] ? (
                <div style={{ width: "85px", height: "40px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="1rem" />
                </div>
              ) : (
                <FormGroup style={{display:"flex", paddingLeft:40, paddingTop: 10}}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={purchaseNotify.purchase_daily_notify}
                        onChange={(e) => handlePurchaseNotify({ purchase_daily_notify: e.target.checked }, 1)}
                        name="guest_login"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={
                      <Typography variant="body1" className={classes.switchLabel}>
                        {typeof purchaseNotify.purchase_daily_notify === 'undefined' || purchaseNotify.purchase_daily_notify ? 'ON' : 'OFF'}
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
              <span style={{marginLeft: 120, marginRight: 20, paddingTop: 10, fontWeight: "bold"}}>{t('通知時間')}</span>
                {isLoading.purchaseNotify[2] ? (
                  <div style={{ width: "115px", justifyContent: 'center', display: 'flex'}}>
                    <CircularProgress size="2rem" />
                  </div>
                ) : (
                  <OsgSelect
                    native
                    value={box.daily_notify_time ? box.daily_notify_time : timeList[0]}
                    onChange={(e) => handlePurchaseNotify({ daily_notify_time: e.target.value }, 2)}
                    className="formControl"
                    style={{width: "12%"}}
                  >
                    {timeList.map((v) => {
                      return (
                        <option value={v} key={`daily_${v}`}>
                          {v}
                        </option>
                      );
                    })}
                  </OsgSelect>
                )}
            </div>
            <div className={classes.listTitle} style={{justifyContent: "flex-start", marginTop: 20}}>
              <span style={{width: "100px", marginTop: 10, marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('毎週')}</span>
              {isLoading.purchaseNotify[3] ? (
                <div style={{ width: "85px", height: "40px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <FormGroup style={{display:"flex", paddingLeft:40, paddingTop: 10}}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={purchaseNotify.purchase_weekly_notify}
                        onChange={(e) => handlePurchaseNotify({ purchase_weekly_notify: e.target.checked }, 3)}
                        name="guest_login"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={
                      <Typography variant="body1" className={classes.switchLabel}>
                        {typeof purchaseNotify.purchase_weekly_notify === 'undefined' || purchaseNotify.purchase_weekly_notify ? 'ON' : 'OFF'}
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
              <span style={{marginLeft: 120, marginRight: 20, paddingTop: 10, fontWeight: "bold"}}>{t('通知時間')}</span>
              {isLoading.purchaseNotify[4] ? (
                <div style={{ width: "140px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <OsgSelect
                  native
                  value={purchaseNotify.weekly_notify_day}
                  onChange={(e) => handlePurchaseNotify({ weekly_notify_day: e.target.value }, 4)}
                  className="formControl"
                  style={{width: "12%", marginRight: 20}}
                >
                  {dayList.map((v, i) => {
                    return (
                      <option value={ i } key={`weekly_day_${i}`}>
                        {v}
                      </option>
                    );
                  })}
                </OsgSelect>
              )}
              {isLoading.purchaseNotify[5] ? (
                <div style={{ width: "115px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <OsgSelect
                  native
                  value={purchaseNotify.weekly_notify_time}
                  onChange={(e) => handlePurchaseNotify({ weekly_notify_time: e.target.value }, 5)}
                  className="formControl"
                  style={{width: "12%"}}
                >
                  {timeList.map((v) => {
                    return (
                      <option value={ v } key={`weekly_${v}`}>
                        {v}
                      </option>
                    );
                  })}
                </OsgSelect>
              )}
            </div>
            <div className={classes.listTitle} style={{justifyContent: "flex-start", marginTop: 20}}>
              <span style={{width: "100px", marginTop: 10, marginLeft: 15, marginRight: 15, fontWeight: "bold"}}>{t('毎月')}</span>
              {isLoading.purchaseNotify[6] ? (
                <div style={{ width: "85px", height: "40px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <FormGroup style={{display:"flex", paddingLeft:40, paddingTop: 10}}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={purchaseNotify.purchase_monthly_notify}
                        onChange={(e) => handlePurchaseNotify({ purchase_monthly_notify: e.target.checked }, 6)}
                        name="guest_login"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={
                      <Typography variant="body1" className={classes.switchLabel}>
                        {typeof purchaseNotify.purchase_monthly_notify === 'undefined' || purchaseNotify.purchase_monthly_notify ? 'ON' : 'OFF'}
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
              <span style={{marginLeft: 120, marginRight: 20, paddingTop: 10, fontWeight: "bold"}}>{t('通知時間')}</span>
              {isLoading.purchaseNotify[7] ? (
                <div style={{ width: "115px", justifyContent: 'center', display: 'flex'}}>
                  <CircularProgress size="2rem" />
                </div>
              ) : (
                <OsgSelect
                  native
                  value={purchaseNotify.monthly_notify_time}
                  onChange={(e) => handlePurchaseNotify({ monthly_notify_time: e.target.value }, 7)}
                  className="formControl"
                  style={{width: "12%"}}
                >
                  {timeList.map((v) => {
                    return (
                      <option value={ v } key={`monthly_${v}`}>
                        {v}
                      </option>
                    );
                  })}
                </OsgSelect>
              )}
            </div>
          </Details>
        { box.status === 1 ? <>
          <div>
            <Button color="primary" onClick={() =>showModal(0)}>
            {t('BOXを一時稼働停止にする')}
            </Button>
          </div>
          <div>
            <Button color="primary" onClick={() =>showModal(1)}>{t('BOXを非表示にする')}</Button>
          </div>
          <div>
            { deletable ?
              <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("このBOXを削除")} </Button>
            : null }
          </div>
        </> : box.status === 2 ? <>
          <div>
            <Button color="primary" onClick={() =>showModal(2)}>
            {t('稼働を再開する')}
            </Button>
          </div>
          <div>
            <Button color="primary" onClick={() =>showModal(1)}>{t('BOXを停止してダッシュボードも非表示にする')}</Button>
          </div>
          <div>
            { deletable ?
              <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("このBOXを削除")} </Button>
            : null }
          </div>
        </> : box.status === 3 ? <>
          <div>
            <Button color="primary" onClick={() =>showModal(3)}>
            {t('ダッシュボードに表示させる')}
            </Button>
            <div>
              { deletable ?
                <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("このBOXを削除")} </Button>
              : null }
            </div>
          </div>
        </> : <>
          <div className={classes.column}>
            { deletable ?
              <Button className={classes.deleteConfirmButton} onClick={ () => setShowDeleteDialog(true)}> {t("このBOXを削除")} </Button>
            : null }
          </div>
        </>}
      </div>

      <Modal open={modal} onClose={() =>setModal(false)} className={classes.modal}>
        <div className={classes.modalContainer}>
          <DialogTitle style={modalType === 0 ? {color: "#C90000"} : {}}>
            <BoldText>{modalTextList[modalType].title}</BoldText>
          </DialogTitle>
          <DialogContent>
            <ListItem>
              <ListItemText className={classes.modalListTitle}>
                <BoldText>{t('設置企業')}</BoldText>
              </ListItemText>
              <ListItemText className={classes.modalListText}>
                <BoldText>{box.customer_id?.name}</BoldText>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText className={classes.modalListTitle}>
                <BoldText>{t('BOX名')}</BoldText>
              </ListItemText>
              <ListItemText className={classes.modalListText}>
                <BoldText>{box.name}</BoldText>
              </ListItemText>
            </ListItem>
            <div style={{marginTop: 20}}>{modalTextList[modalType].text}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={
              modalType === 0 ? () => handleStopBox() :
              modalType === 1 ? () => handleHideBox() :
              modalType === 2 ? () => handleStartBox() :
              modalType === 3 ? () => handleStopBox(true) :
              null}>
              <BoldText className="dangerText">{modalTextList[modalType].applyText}</BoldText>
            </Button>
            <Button onClick={() =>setModal(false)}>
              <BoldText>{modalTextList[modalType].cancelText}</BoldText>
            </Button>
          </DialogActions>
        </div>
      </Modal>

      <Dialog
        open={showDeleteDialog} fullWidth maxWidth="sm">
        <div className={classes.deleteConfirmDialogContainer}>
          <div className={classes.deleteConfirmDialogTitle}>
            {t("BOX削除")}
          </div>
          <div className={classes.deleteConfirmUserName}>
            {t("対象BOX")}:&nbsp;&nbsp;{box.name}
          </div>

          <div className={classes.deleteConfirmDescription}>
            {t("BOX削除をすると、全ユーザーを対象に利用・閲覧ができなくなります。")}
          </div>

          <div className={classes.deleteConfirmDescription}>
            {t("本当に削除しますか？")}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="outlined"
              onClick={ () => setShowDeleteDialog(false)}
              className={classes.backButton}
            >
              {t("戻る")}
            </Button>
            <Button
              className={classes.deleteButton}
              color="primary"
              variant="contained"
              onClick={handleDeleteClick}
            >
              {t("削除する")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default BoxDetailPage;
