import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../Common/Loader/Loader";
import { OsgButton } from "../../Common/UIcomponents";
import LoginTitle from "./Title";
import { Trans, useTranslation } from 'react-i18next';
import { useUserContext } from '../../../contexts/UserContext';
import i18n from 'i18next';

const Div = styled.div`
  padding: 10px 50px;
`;

const SubLoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #00559D;
  font-size: 1.5em;
  font-weight: bold;
`;

const LoginEnTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #00559D;
  font-size: 1.2em;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextFieldWithLabel = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin-top: 16px;
  margin-bottom: 5px;
  .textField {
    width: 100%;
  }
  .formLabel {
    display: flex;
    align-items: center;
    width: 25%;
    font-weight: bold;
    text-align: right;

    span {
      color: #8B8C8E;
      font-weight: normal;
      font-size: 0.8em;
    }
  }
`;

const SignInLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;

  div {
    text-align: center;
    width: 150px;
    margin: 5px 0px;

    .passwordLink {
      color: #8B8C8E;

      &:hover {
        color: #707070;
      }
    }
  }
`;

const SignInView = (props) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState("");
  const [authEnError, setAuthEnError] = useState("");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { handleUpdateUser, handleUpdateToken } = useUserContext();


  const initiateSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) || !password) {
      setAuthError('メールアドレス、またはパスワードが間違っています');
      setAuthEnError('Invalid email or password');
    } else {
      try {
        const result = await axios.post('/authenticate',{
          email: email,
          password: password
        });

        if(result.data.success){
          setAuthError('');
          setAuthEnError('');
          localStorage.setItem("parent-auth-token", result.data.token);
          localStorage.setItem("parent-user", JSON.stringify(result.data.user));
          localStorage.setItem("auth-token", result.data.token);
          localStorage.setItem("user", JSON.stringify(result.data.user));
          handleUpdateUser(result.data.user);
          handleUpdateToken(result.data.token);
          axios.defaults.headers.common['x-access-token'] = result.data.token;


          i18n.changeLanguage(result.data.user.lang);
          props.history.push("/");
        }else{
          setAuthError(result.data.message);
          setAuthEnError('');
        }
      } catch (e){
        if(e.response && e.response.data.errorMessage === 'メールアドレス、またはパスワードが間違っています') {
          setAuthError(e.response.data.errorMessage);
          setAuthEnError('Invalid email or password');
        } else {
          setAuthError(e.response.data.errorMessage);
          setAuthEnError('');
        }
      } finally {
        setTimeout(()=> {
          setIsLoading(false);
        },1000)
      }
    }
    setIsLoading(false);
  };

  return (
    <Div>
      <LoginTitle></LoginTitle>
      <SubLoginTitle>Webダッシュボード ログイン</SubLoginTitle>
      <LoginEnTitle>Web dashboard Login</LoginEnTitle>
      <Form onSubmit={initiateSignIn}>
        <TextFieldWithLabel>
          <label className="formLabel">
            <div>
              mail
            </div>
          </label>
          <TextField
            name="email"
            variant="outlined"
            className="textField"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
          />
        </TextFieldWithLabel>
        <TextFieldWithLabel>
          <label className="formLabel">
            <div>
              Password
            </div>
          </label>
          <TextField
            variant="outlined"
            name="password"
            type="password"
            className="textField"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
          />
        </TextFieldWithLabel>
        <div className="errorMessageContenair errorMessageBox">
          <Typography className="errorMessage" variant="subtitle2">
            {authError}
          </Typography>
          <Typography style={{textAlign: 'center'}} className="errorMessage"  variant="subtitle2">
            {authEnError}
          </Typography>
        </div>

        <div style={{marginTop: '21px'}}>
          <OsgButton type="submit">Login</OsgButton>
        </div>
      </Form>
      <SignInLinks>
        <div>
          <Link to="/forgot_password" className="passwordLink">
            <p>パスワードを忘れた方</p>
            <p>Forget Password?</p>
          </Link>
        </div>
      </SignInLinks>
      {isLoading ? (
        <div className="isLoading">
          <Loader />
        </div>
      ) : null}
    </Div>
  );
};
export default SignInView;
