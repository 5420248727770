import React, { useEffect, useState } from 'react';
import '../App.scss';
import { ThemeProvider, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {Switch, Route} from "react-router-dom";
import ForgotPassword from "./pages/Auth/ForgotPasswordView";
import SignInView from "./pages/Auth/SignInView";
import SignUpView from "./pages/Auth/SignUpView";
import SignOutView from "./pages/Auth/SignOutView";
import VerifyPage from "./pages/Auth/VerifyPage";
import TopupUpdatePage from "./pages/topup/TopupUpdatePage";
import AuthRoute from "./Common/AuthRoute";
import AppLayout from "./Common/AppLayout";
import ProtectedRoute from "./Common/ProtectedRoute";
import { useUserContext } from '../contexts/UserContext';

function MainRouter() {
  const { handleUpdateUser, handleUpdateToken, user, token } = useUserContext();
  const [themParams, setThemeParams] = useState(
    {
      palette: {
        primary: {
          main: "#00559D"
        }
      },
    }
  );

  useEffect(() => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('auth-token');
    if (user && token) {
      handleUpdateUser(JSON.parse(user));
      handleUpdateToken(token);
    }
  }, []);

  useEffect(() => {
    if (user.lang === 'zh') {
      setThemeParams({
        typography: {
          fontFamily: [
            "-apple-system,BlinkMacSystemFont",
            "Segoe UI","Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "Roboto",
            "Helvetica",
            "Arial",
            "sans-serif",
            "Noto Sans TC",
            "PingFang TC",
            "Hiragino Sans CNS",
            "Microsoft JhengHei",
          ].join(','),
        },
      });
    } else {
      setThemeParams({
        palette: {
          primary: {
            main: "#00559D"
          }
        },
      });
    }
  }, [user]);

  const handleUpdateUserLang = (user) => {
    if (user.lang === 'zh') {
      setThemeParams({
        palette: {
          primary: {
            main: "#00559D"
          }
        },
        typography: {
          fontFamily: [
            "-apple-system,BlinkMacSystemFont",
            "Segoe UI","Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "Roboto",
            "Helvetica",
            "Arial",
            "sans-serif",
            "Noto Sans TC",
            "PingFang TC",
            "Hiragino Sans CNS",
            "Microsoft JhengHei",
          ].join(','),
        },
      });
    } else {
      setThemeParams({
        palette: {
          primary: {
            main: "#00559D"
          }
        },
      });
    }
  };

  return (
    <MuiThemeProvider theme={createTheme(themParams)}>
      {/* <ThemeProvider theme={createTheme(themParams)}> */}
        <Switch>
          <AuthRoute path="/forgot_password" exact={true} component={ForgotPassword} />
          <AuthRoute path="/signin" exact={true} component={SignInView} />
          <AuthRoute path="/signup" exact={true} component={SignUpView} />
          <AuthRoute path="/signout" exact={true} component={SignOutView} />
          <AuthRoute path="/users/varify/:token" exact={true} component={VerifyPage} />
          <AuthRoute path="/topup/update/:id" exact={true} component={TopupUpdatePage} />
          {/*<AuthRoute*/}
          {/*  path="/signup-form/:token"*/}
          {/*  exact={true}*/}
          {/*  component={SignUpFormView}*/}
          {/*/>*/}
          {/*/!* <AuthRoute path="/verify/:token" exact={true} component={SignUp} /> *!/*/}
          {/*<AuthRoute*/}
          {/*  path="/reset-password-submit"*/}
          {/*  exact={true}*/}
          {/*  component={ResetPassword}*/}
          {/*/>*/}
          {/*<AuthRoute*/}
          {/*  path="/reset-password-verify/:token"*/}
          {/*  exact={true}*/}
          {/*  component={VerifyResetPwdToken}*/}
          {/*/>*/}
          {/*<AuthRoute*/}
          {/*  path="/resetpassword-form"*/}
          {/*  exact={true}*/}
          {/*  component={ResetPasswordForm}*/}
          {/*/>*/}
          {/*<AuthRoute*/}
          {/*  path="/verify-email/:token"*/}
          {/*  exact={true}*/}
          {/*  component={VerifyNewEmailToken}*/}
          {/*/>*/}
          <ProtectedRoute path="/" component={AppLayout} handleUpdateUserLang={handleUpdateUserLang} />
        </Switch>
      {/* </ThemeProvider> */}
    </MuiThemeProvider>
  );
}

export default MainRouter;
