import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Typography } from "@material-ui/core";
import { OsgButton, OsgSelect } from "../../Common/UIcomponents";
import styled from "styled-components";
import {
  TextField,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  listColumn: {
    display: "flex",
    marginBottom: 10
  },
  listName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "40px",
    width: "40%",
    paddingRight: 20,
    fontWeight: "bold",
  },
  listValue: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    width: "60%"
  },
  listTextInput: {
    width: "60%",
    backgroundColor: "#d4e7ff",
  },
  boxTitle: {
    marginTop: "50px",
    marginBottom: "10px",
    fontSize: "20px",
    fontWeight: "bold"
  }
});
const Details = styled.div`
  max-width: 650px;
  text-align: center;
`;

const UserAddPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [boxes, setBoxes] = useState([]);
  const [company, setCompany] = useState('');
  const [values, setValues] = useState({
    username: '',
    email: '',
    is_admin: true,
    company_id: '',
    permissions: [],
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    (async () => {
      const id = props.match.params.id;
      setValues({ ...values, company_id: id });
      const res = await axios.get(`companies/${id}`);
      setCompany(res.data);
      if (res.data.is_customer) {
        const boxes = await axios.get(`/boxes/customer/${ res.data._id }`);
        setBoxes(boxes.data);
      }
    })();
  }, []);

  const handleSetBox = async (e, box_id) => {
    const params = {
      box_id: box_id,
      boxrole_id: e.target.value,
    };
    const newBox = values.permissions.filter(v => v.box_id !== box_id);
    if (e.target.value != 0) {
      newBox.push(params);
    }
    setValues({ ...values, permissions: newBox});
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    let errors = {};
    if (!values.email || !values.email.match(/^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,18})?$/)) {
      errors.email = t('メールアドレスを入力してください')
    }
    if (!values.username) {
      errors.username = t('ユーザー名を入力してください')
    }
    if (Object.keys(errors).length === 0) {
      try {
        const res = await axios.post('/users/add', values);
        if (res.data.success) {
          alert(t('登録しました'));
          props.history.push("/users");
        } else if (res.data.error?.errors?.email?.message) {
          setErrors({email: res.data.error?.errors?.email?.message})
        } else if (res.data.error?.errors?.username?.message) {
          setErrors({username: res.data.error?.errors?.username?.message})
        } else if (res.data.message){
          setErrors({email: res.data.message})
        }
        setIsDisabled(false);
      } catch (e){
        if (e.response.data.message) {
          alert(t(e.response.data.message));
        } else {
          alert(t('登録時にエラーが発生しました'));
        }
        setIsDisabled(false);
      }
    } else {
      setErrors(errors);
      setIsDisabled(false);
    }

  };

  return (
    <>
      <Details>
        <div style={{minWidth: 1000, marginTop: 50}}>
          <form className={classes.root} noValidate autoComplete="off">
            <div className={classes.listColumn} style={{marginBottom: 20}}>
              <div className={classes.listName}>
                {t('企業')}
              </div>
              <div className={classes.listValue}>
                {company.name}
              </div>
            </div>
            <div className={classes.listColumn} style={{marginBottom: 20}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('ユーザー名')}
              </div>
              <TextField
                name="username"
                size="small"
                variant="outlined"
                className={classes.listTextInput}
                onChange={handleChange}
              />
            </div>
            <Typography>
              <span className="error-text">
                {errors.username ? errors.username : ""}
              </span>
            </Typography>
            <div className={classes.listColumn} style={{marginBottom: -5}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('メールアドレス')}
              </div>
              <TextField
                name="email"
                size="small"
                variant="outlined"
                className={classes.listTextInput}
                onChange={handleChange}
              />
            </div>
            <div className={classes.listColumn} style={{marginBottom: 0}}>
              <div className={classes.listName}></div>
              <div className={classes.listValue} style={{fontSize: "12px"}}>{t('登録ユーザーにアカウント発行のメールが届きます。')}</div>
            </div>
            <Typography>
              <span className="error-text">
                {errors.email ? errors.email : ""}
              </span>
            </Typography>
            <div className={classes.listColumn} style={{marginBottom: 20}}>
              <div className={classes.listName}>
                <span style={{color: "#427DD4", fontSize: "12px", marginRight: 20}}>{t('必須')}</span>
                {t('自社に対する権限の指定')}
              </div>
              <div className={classes.listValue}>
                <OsgSelect name="is_admin" onChange={ handleChange } style={{width: '300px', marginRight: "20px"}} value={ values.is_admin }>
                  <option value={ false }>{t('一般')}</option>
                  <option value={ true }>{t('管理者')}</option>
                </OsgSelect>
              </div>
            </div>

            {company.is_customer && values.is_admin === "false" ?
              <>
                <div className={classes.boxTitle}>{t('BOXリスト')}</div>
                { boxes.map(box => {
                  const optionAdmin = box.box_roles.filter(v => v.is_admin && v.is_customer)[0]?._id;
                  const optionNormal = box.box_roles.filter(v => !v.is_admin && v.is_customer)[0]?._id;

                  return <div className={classes.listColumn} style={{marginBottom: 20}} key={ box._id } value={ box._id }>
                    <div className={classes.listName} style={{fontWeight: "normal", justifyContent: "center"}}>
                      { box.name }
                    </div>
                    <div className={classes.listValue}>
                      <OsgSelect
                        onChange={ (e) => handleSetBox(e, box._id) }
                        style={{width: '100%', marginRight: "20px"}}
                      >
                        <option key="0" value="0">{t('使用不可')}</option>
                        <option key="1" value={optionAdmin}>{t('自社購入履歴全閲覧')} + {t('購入者')}</option>
                        <option key="2" value={optionNormal}>{t('購入者')}</option>
                      </OsgSelect>
                    </div>
                  </div>
                })}
              </>
              : null
            }

          <div style={{marginTop: 50, marginBottom: 20}}>
            <OsgButton
              variant="contained"
              color="primary"
              onClick={(e) => {
                setIsDisabled(true)
                handleSubmit(e)
              }}
              disabled={isDisabled}
            >
            {t('登録')}
            </OsgButton>
          </div>
          <Link to="/users" style={{color: "#00559D", textDecoration: "none", fontSize: "12px"}}>
            {t('登録をやめてユーザー一覧に戻る')}
          </Link>
        </form>
      </div>
    </Details>
    </>
  );
};

export default UserAddPage;
