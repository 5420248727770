import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { OsgSelect } from "../../Common/UIcomponents";
import {
  Button,
  TextField,
  List,
  ListItem
} from '@material-ui/core';
import { useUserContext } from '../../../contexts/UserContext';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import _ from 'lodash';

const useStyles = makeStyles({
  zhTitle: {
    fontFamily: `
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Noto Sans TC",
      "PingFang TC",
      "Hiragino Sans CNS",
      "Microsoft JhengHei"
    `
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainer: {
    width: 900,
  },
  listName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "30%",
    fontWeight: "bold",
  },
  listZhName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    width: "30%",
    fontWeight: "bold",
    fontFamily: `
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Noto Sans TC",
      "PingFang TC",
      "Hiragino Sans CNS",
      "Microsoft JhengHei"
    `,
  },
  listValue: {
    padding: "0px 20px",
    height: "40px",
    width: "50%",
    marginRight: "10px",
    backgroundColor: "#F6FBFF",
  },
  listUser: {
    padding: "0px 20px",
    height: "40px",
    width: "50%",
    backgroundColor: "#efefef",
    display: "flex",
    alignItems: "center",
  },
});

const MyPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  const [parentUser, setParentUser] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [lang, setLang] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [companyType, setCompanyType] = useState('');
  const { handleUpdateUser, handleUpdateToken, user, token } = useUserContext();

  useEffect(() => {
    (async () => {
      const parentUser = JSON.parse(localStorage.getItem("parent-user"));
      let tmpCompanyType = '';
      try {
        setCompany(user.company_id._id)
        setLang(user.lang);
        const res = await axios.get(`users/${parentUser._id}`);
        setUsername(res.data.username);
        setEmail(res.data.email);
        setParentUser(parentUser);
        if (parentUser?.company_id?.is_osg) {
          if (user?.company_id?.is_agent) {
            tmpCompanyType = 'agents';
          } else if (user?.company_id?.is_customer) {
            tmpCompanyType = 'customers';
          } else {
            tmpCompanyType = 'osg';
          }
          setCompanyType(tmpCompanyType);
          const parentToken = localStorage.getItem("parent-auth-token");
          if (tmpCompanyType !== 'osg') {
            const companyRes = await axios.get(`companies/osg/${tmpCompanyType}`, {
              headers:{
                'x-access-token': parentToken
              }
            });
            setCompanies(companyRes.data);
          }
        }
        setIsDisabled(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleSubmitEmail = async () => {
    if (!email || !email.match(/^([\w-\+\.]+@([\w-]+\.)+[\w-]{2,18})?$/)) {
      setError(t('※メールアドレスを入力してください'));
      return;
    }
    const parentToken = localStorage.getItem("parent-auth-token");
    const res =
      await axios.put(
        `/users/change_email/${user._id}`,
        { email: email },
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
    if (res.data.success) {
      alert(t('変更しました'));
    } else if (res.data.error) {
      setError(t(res.data.error));
    }
  };

  const handleSubmitPassword = async () => {
    if (password === "") {
      setError(t('※パスワードを入力してください'));
      return;
    }
    const parentToken = localStorage.getItem("parent-auth-token");
    const res =
      await axios.put(
        `/users/change_password/${user._id}`,
        { password: password },
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
    if (res.data.success) {
      alert(t('再設定しました'));
    }
  };

  const handleSubmitLang = async (e) => {
    const lang = e.target.value
    const parentToken = localStorage.getItem("parent-auth-token");
    const res =
      await axios.put(
        `/users/change_lang/${user._id}`,
        { lang: lang },
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
    if (res.data.success) {
      user.lang = lang;
      localStorage.setItem('user', JSON.stringify(user));
      handleUpdateUser(user);
      props.handleUpdateUserLang(user);
      i18n.changeLanguage(lang);
      alert(t('変更しました'));
      setLang(lang);
    }
  };

  const handleSetCompanyType = async (e) => {
    let res = {
      data: []
    };
    if (e.target.value !== 'osg') {
      const parentToken = localStorage.getItem("parent-auth-token");
      res = await axios.get(
        `companies/osg/${e.target.value}`,
        {
          headers:{
            'x-access-token': parentToken
          }
        });
    } else {
      const parentUser = JSON.parse(localStorage.getItem("parent-user"));
      const parentToken = localStorage.getItem("parent-auth-token");
      const result = await axios.post(
        `/authenticate/supersede/logout`,
        {},
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
      if (result.data.success) {
        setCompany('');
        axios.defaults.headers.common['x-access-token'] = parentToken;
        localStorage.setItem("auth-token", parentToken);
        localStorage.setItem("user", JSON.stringify(parentUser));
        handleUpdateUser(parentUser);
        handleUpdateToken(parentToken);
        alert(t('アカウントを指定の企業に変更しました'));
      }
    }
    setCompanyType(e.target.value);
    setCompanies(res.data);
  }

  const handleSetCompany = async (e) => {
    setCompany(e.target.value);
    const parentUser = JSON.parse(localStorage.getItem("parent-user"));
    if (e.target.value === parentUser.company_id._id && parentUser.company_id.is_osg) {
      const parentToken = localStorage.getItem("parent-auth-token");
      localStorage.setItem("auth-token", parentToken);
      localStorage.setItem("user", JSON.stringify(parentUser));
      i18n.changeLanguage(parentUser.lang);
      handleUpdateUser(parentUser);
      handleUpdateToken(parentToken);
      axios.defaults.headers.common['x-access-token'] = parentToken;
      const result = await axios.post(
        `/authenticate/supersede/logout`,
        {},
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
      if (result.data.success) {
        alert(t('アカウントを指定の企業に変更しました'));
      }
    } else {
      const parentToken = localStorage.getItem("parent-auth-token");
      const result = await axios.post(
        `/authenticate/company`,
        {
          _id: parentUser._id,
          username: parentUser.username,
          company_id: e.target.value
        },
        {
          headers:{
            'x-access-token': parentToken
          }
        }
      );
      if (result.data.token && result.data.supersedeUser) {
        localStorage.setItem("auth-token", result.data.token);
        localStorage.setItem("user", JSON.stringify(result.data.supersedeUser));
        i18n.changeLanguage(result.data.supersedeUser.lang);
        handleUpdateUser(result.data.supersedeUser);
        handleUpdateToken(result.data.token);
        axios.defaults.headers.common['x-access-token'] = result.data.token;
        alert(t('アカウントを指定の企業に変更しました'));
      }
    }
  };

  return (
    <>
      <h2 className={lang === 'zh' && classes.zhTitle}>{t('アカウント情報')}</h2>
      <div className={classes.container}>
        <List className={classes.listContainer}>
          <ListItem dense>
            <div className={lang === 'zh' ? classes.listZhName : classes.listName}></div>
            <div className={classes.listUser}>{ username }</div>
          </ListItem>
          <ListItem dense style={{marginTop: "20px"}}>
            <div className={lang === 'zh' ? classes.listZhName : classes.listName}>{t('メールアドレス')}</div>
            <TextField name="email" className={classes.listValue} onChange={ (e) => setEmail(e.target.value) } value={ email } />
            <Button onClick={ handleSubmitEmail } variant="outlined" color="primary" style={{width: "100px"}}>{t('変更')}</Button>
          </ListItem>
          <ListItem dense style={{marginTop: "20px"}}>
            <div className={lang === 'zh' ? classes.listZhName : lang === 'zh' ? classes.listZhName : classes.listName}>{t('パスワード')}</div>
            <TextField type="password" name="password" className={classes.listValue} onChange={ (e) => setPassword(e.target.value) } value={ password } />
            <Button onClick={ handleSubmitPassword } variant="outlined" color="primary" style={{width: "100px"}}>{t('再変更')}</Button>
          </ListItem>
          { error ?
            <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
              <span className="error-text">{ error }</span>
            </div>
          : null }
          <ListItem dense style={{marginTop: "20px"}}>
            <div className={lang === 'zh' ? classes.listZhName : classes.listName}>Language</div>
            {(lang !== '') ? (
              <OsgSelect name="lang" style={{width: "25%"}} disabled={isDisabled} defaultValue={ lang } onChange={ handleSubmitLang } >
                <option value="ja">{t('日本語')}</option>
                <option value="en">{t('English')}</option>
                <option value="zh">{t('中国語')}</option>
              </OsgSelect>
            ) : null}
          </ListItem>
          {(parentUser?.company_id?.is_osg) && (
            <ListItem dense style={{marginTop: "25px"}}>
              <div className={lang === 'zh' ? classes.listZhName : classes.listName}>{t('企業設定')}</div>
              {(companyType !== '') ? (
                <OsgSelect name="company_type" style={{width: "25%", marginRight: "50px"}} defaultValue={ companyType } onChange={ handleSetCompanyType } >
                  <option value='osg'>{t('OSG(デフォルト)')}</option>
                  <option value='agents'>{t('商社')}</option>
                  <option value='customers'>{t('カスタマー')}</option>
                </OsgSelect>
              ) : null}
              {(company !== '' && companies.length > 0) ? (
                <OsgSelect name="companies" style={{width: "40%"}} defaultValue={ company } onChange={ handleSetCompany } disabled={companyType === 'osg'} >
                  <option value=''>{t('未選択')}</option>
                  {_.map(companies, (c) => (
                    <option value={c._id} key={c._id}>
                      {c.name}
                    </option>
                  ))}
                </OsgSelect>
              ) : null}
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
};

export default MyPage;
