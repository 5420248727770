import React, { useState, createContext, useContext, useCallback } from 'react';
import _ from 'lodash';

const UserContext = createContext({
  token: '',
  user: {},
  handleUpdateUser: () => {},
  handleRemoveUser: () => {},
})

const UserProvider = ({ children }) => {
  const [token, setToken] = useState([]);
  const [user, setUser] = useState([]);

  const handleUpdateUser = useCallback(user => {
    setUser(user);
  }, [user, setUser])

  const handleUpdateToken = useCallback(token => {
    setToken(token);
  }, [token, setToken])

  const handleRemoveUser = useCallback(() => {
    setToken('');
    setUser({});
  }, [setToken,setUser])

  return <UserContext.Provider value={{
    token,
    user,
    handleUpdateUser,
    handleRemoveUser,
    handleUpdateToken,
  }}>
    {children}
  </UserContext.Provider>
}

const useUserContext = () => useContext(UserContext)

export { UserProvider, useUserContext }
