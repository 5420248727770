import React, { createRef, useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles, Button} from '@material-ui/core';
import ItemCsvForm from './ItemCsvForm.jsx';
import QuantityCsvForm from './QuantityCsvForm.jsx';
import OneByOneForm from './OneByOneForm.jsx';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  container: {
    display: "flex",
    borderBottom: "solid 1px #C4C4C4"
  },
  buttonContainer: {
    paddingBottom: "30px",
    width: "160px"
  },
  addBtn: {
    width: "160px",
    marginLeft: "30px"
  },
  uploadBtn: {
    width: "135px",
    marginLeft: "30px"
  },
  label: {
    marginLeft: "30px",
    fontSize: 15,
  }
});

const TopupPage = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [visibleCountCsvForm, setVisibleCountCsvForm] = useState(false);
  const [visibleItemCsvForm, setVisibleItemCsvForm] = useState(false);
  const [visibleInputForm, setVisibleInputForm] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    (async () => {
      if (props.location.state?.customerId && props.location.state?.boxId ) {
        setVisibleCountCsvForm(true)
      }
      const res = await axios.get('/companies/for_box');
      setCustomerList(res.data);
    })();
  }, []);

  const handleShowCountCsv = () => {
    setVisibleInputForm(false);
    setVisibleCountCsvForm(true);
    setVisibleItemCsvForm(false);
  };
  const handleShowItemCsv = () => {
    setVisibleInputForm(false);
    setVisibleCountCsvForm(false);
    setVisibleItemCsvForm(true);
  };
  const handleShowInputForm = () => {
    setVisibleInputForm(true);
    setVisibleCountCsvForm(false);
    setVisibleItemCsvForm(false);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.buttonContainer} style={{paddingBottom: "30px"}}>
          <span className={classes.label}>{t('在庫数量 更新')}</span>
          <Button color="primary" className={classes.uploadBtn} variant={visibleCountCsvForm ? 'contained' : 'outlined'} onClick={handleShowCountCsv}>
            {t('アップロード')}
          </Button>
        </div>
        <div className={classes.buttonContainer} style={{paddingTop: "21px"}}>
          <Button color="primary" className={classes.addBtn} variant={visibleInputForm ? 'contained' : 'outlined'} onClick={handleShowInputForm}>
            {t('手入力')}
          </Button>
        </div>
        <div className={classes.buttonContainer} style={{paddingLeft: "60px"}}>
          <span className={classes.label}>{t('在庫品目 更新')}</span>
          <Button color="primary" className={classes.uploadBtn} variant={visibleItemCsvForm ? 'contained' : 'outlined'} onClick={handleShowItemCsv}>
            {t('アップロード')}
          </Button>
        </div>
      </div>

      { visibleCountCsvForm ? <QuantityCsvForm {...props} customerList={customerList} /> : null }
      { visibleItemCsvForm ? <ItemCsvForm {...props} customerList={customerList} /> : null }
      { visibleInputForm ? <OneByOneForm {...props} customerList={ customerList } /> : null }
    </>
  );
};
export default TopupPage;
