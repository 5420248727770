import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import notoSansRegular from './assets/fonts/NotoSansSC-Regular.otf';

let environmentName = '';
let clientUrl = '';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  axios.defaults.baseURL = 'https://dashboard.monolithbox.com/api'
  clientUrl = 'https://dashboard.monolithbox.com'
  environmentName = 'production'
}else if(process.env.REACT_APP_ENVIRONMENT === 'stg') {
  axios.defaults.baseURL = 'https://osg-dashboard.ankr.dev/api'
  clientUrl = 'https://osg-dashboard.ankr.dev'
  environmentName = 'stg'
}else if(process.env.REACT_APP_ENVIRONMENT === 'development') {
  axios.defaults.baseURL = 'https://osg-dashboard2.ankr.dev/api'
  clientUrl = 'https://osg-dashboard2.ankr.dev'
  environmentName = 'development'
} else{
  axios.defaults.baseURL = 'http://localhost:3000';
  clientUrl = 'http://localhost:3001'
  environmentName = 'local'
}

Sentry.init({
  dsn: "https://7a658bfa91d048bc9a65ce997137e817@o1208450.ingest.sentry.io/4503918379663360",
  integrations: [new BrowserTracing({
    tracingOrigins: [clientUrl],
  })],
  environment: environmentName,
  tracesSampleRate: 1.0,
});

axios.get('index.html').then((res) => {
  window.enJson = res.data.en;
  window.zhJson = res.data.zh;
}).then(res => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
