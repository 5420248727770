import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";


const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  height:100%;
  top:0;
  left:0;
  width: 100%;
  position: absolute;
  background:rgba(0,0,0,.3);
`;

const Loader = (props) => {
  return (
    <Loading>
      <CircularProgress />
    </Loading>
  );
};

export default Loader;
